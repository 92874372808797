import { AlertCircle, CheckCircle } from "lucide-react";
import { Fragment } from "react";
import SmartForm from "../components/SmartForm";

type getDoctorFieldsProps = {
  formFields: any;
  isAdoptForm: Boolean;
  autoCompleteTypeHandler: any;
  setUinVerified: any;
  setUin: any;
  setAltUin: any;
  uinVerified: any;
  isUinDisabled: any;
  isImportForm: any;
};

export const getDoctorFields = ({
  formFields,
  isAdoptForm,
  autoCompleteTypeHandler,
  setUinVerified,
  setUin,
  setAltUin,
  uinVerified,
  isUinDisabled,
}: getDoctorFieldsProps) => {

  const fieldsToIgnoreDisableInAdopt = ["remarks", "associated_dr_mobile_number", "associated_dr_name"];

  return formFields?.map((field: any) => {
    if (!field.is_display) return null;
    // console.log(field?.bindingKey, typeof JSON.parse(JSON.stringify(field?.validationRules?.validationValue || "")));
    switch (field?.inputType?.toString()?.toLocaleLowerCase().trim()) {
      case "dropdown":
        return (
          <SmartForm.Autocomplete
            className=""
            placeholder={field?.placeholder}
            disabled={isAdoptForm ? true : false}
            fieldName={field?.bindingKey}
            label={field?.field_name}
            fieldType={field?.bindingKey?.toString()?.toLowerCase()?.includes("city") ? "city" : undefined}
            asyncListFunction={autoCompleteTypeHandler(field.bindingKey)?.fn}
            isAsyncQuery={false}
            suggestionList={field?.suggestionList || []}
            uniqueKey={autoCompleteTypeHandler(field?.bindingKey)?.uniqueKey || ""}
            displayKey={autoCompleteTypeHandler(field?.bindingKey)?.displayKey || ""}
            key={field?.field_name}
            rules={isAdoptForm ? {} : field?.is_mandatory ? { required: "This field is required" } : undefined}
          />
        );

      default: {
        return field?.attachment_required ? (
          <SmartForm.File
            key={field?.field_name || ""}
            placeholder={field?.placeholder || ""}
            label={field?.field_name || ""}
            disabled={isAdoptForm ? (fieldsToIgnoreDisableInAdopt.includes(field?.bindingKey) ? false : true) : false}
            type={field?.inputType || "text"}
            fieldName={field?.bindingKey || ""}
            className="w-full"
            tooltipContent={field?.attachment_title}
            rules={
              isAdoptForm && !fieldsToIgnoreDisableInAdopt.includes(field?.bindingKey)
                ? {}
                : {
                    ...(field?.is_mandatory && { required: "This field is required" }),
                    ...(field?.bindingKey.includes("pharmacy_manager_name") && {
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Enter alphabets only",
                      },
                    }),
                    ...(field?.validationRules?.validationType === "regex" && {
                      pattern: {
                        value: new RegExp(field?.validationRules?.validationValue),
                        message: field?.validationRules?.errorMessage,
                      },
                    }),
                    ...(field?.validationRules?.validationType === "length" && {
                      minLength: {
                        value: field?.validationRules?.validationValue,
                        message: field?.validationRules?.errorMessage,
                      },
                      maxLength: {
                        value: field?.validationRules?.validationValue,
                        message: field?.validationRules?.errorMessage,
                      },
                    }),
                  }
            }
            fileUploadKey={field?.counterDraft_companyConfig_id || ""}
          />
        ) : field.bindingKey === "uin_code" ? (
          <Fragment key={field?.field_name}>
            <div className="relative w-full">
              <SmartForm.Input
                type={"text"}
                className=""
                placeholder={field?.placeholder || ""}
                fieldName={field?.bindingKey || ""}
                label={field?.field_name || ""}
                onKeyUp={(e: any) => {
                  setUinVerified(false);
                  setUin(e.target.value);
                }}
                key={field?.field_name || ""}
                inputClassName="w-full"
                rules={
                  isUinDisabled
                    ? {}
                    : {
                        required: "This field is required",
                      }
                }
              />
              {!uinVerified && <AlertCircle size={20} color="red" className="absolute top-6 right-1" />}
              {uinVerified && <CheckCircle size={20} color="green" className="absolute top-6 right-1" />}
            </div>
          </Fragment>
        ) : field.bindingKey === "company_alternate_uin" ? (
          <Fragment key={field?.field_name}>
            <div className="relative w-full">
              <SmartForm.Input
                type={"text"}
                className=""
                placeholder={field?.placeholder || ""}
                fieldName={field?.bindingKey || ""}
                label={field?.field_name || ""}
                onKeyUp={(e: any) => {
                  setUinVerified(false);
                  setAltUin(e.target.value);
                }}
                key={field?.field_name || ""}
                inputClassName="w-full"
                rules={{
                  required: "This field is required",
                }}
              />
              {!uinVerified && <AlertCircle size={20} color="red" className="absolute top-6 right-1" />}
              {uinVerified && <CheckCircle size={20} color="green" className="absolute top-6 right-1" />}
            </div>
          </Fragment>
        ) : (
          <SmartForm.Input
            type={field?.bindingKey?.includes("mobile_no") ? "text" : field?.inputType || "text"}
            className=""
            placeholder={field?.placeholder || ""}
            fieldName={field?.bindingKey || ""}
            label={field?.field_name || ""}
            key={field?.field_name || ""}
            disabled={isAdoptForm ? (fieldsToIgnoreDisableInAdopt.includes(field?.bindingKey) ? false : true) :  false}
            inputClassName="w-full"
            rules={
              isAdoptForm && !fieldsToIgnoreDisableInAdopt.includes(field?.bindingKey)
                ? {}
                : {
                    ...(field?.is_mandatory && { required: "This field is required" }),
                    ...(field?.validationRules?.validationType === "regex" && {
                      pattern: {
                        value: new RegExp(field?.validationRules?.validationValue),
                        message: field?.validationRules?.errorMessage,
                      },
                    }),
                    ...(field?.validationRules?.validationType === "length" && {
                      minLength: {
                        value: field?.validationRules?.validationValue,
                        message: field?.validationRules?.errorMessage,
                      },
                      maxLength: {
                        value: field?.validationRules?.validationValue,
                        message: field?.validationRules?.errorMessage,
                      },
                    }),
                  }
            }
          />
        );
      }
    }
  });
};
