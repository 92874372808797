import { Combobox, Transition } from "@headlessui/react";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import SearchIcon from "../assets/arrow-down.svg";
import { cn, getValueFromNestedObject } from "../utils/utils";
import Checkbox from "./Checkbox";

interface AutocompleteProps {
  placeholder: string;
  inputClassName?: string;
  dropdownClassName?: string;
  onSelectionChange: any;
  suggestionList: Array<any>;
  displayKey: string;
  uniqueKey?: string;
  disabled?: boolean;
  currentSelected?: Array<any>;
  defaultValue?: Array<any>;
  getAllSelectedValues?: (value: any[]) => void;
  controlledValue?: any[];
}

const MultiAutocomplete: FC<AutocompleteProps> = ({
  placeholder,
  inputClassName,
  dropdownClassName,
  suggestionList,
  displayKey,
  uniqueKey,
  disabled,
  defaultValue,
  onSelectionChange,
  controlledValue,
}) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(defaultValue || []);
  const [query, setQuery] = useState<string>("");

  const filteredQuery =
    query === ""
      ? suggestionList
      : suggestionList.filter(
          (item) =>
            getValueFromNestedObject(item, displayKey)
              ?.toString()
              ?.toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    if (event.target.value === "") {
      setSelectedValues([]);
    }
  };

  useEffect(() => {
    if (controlledValue && Array.isArray(controlledValue)) {
      const transformedData = controlledValue?.map((item: any) => getValueFromNestedObject(item, displayKey));
      setSelectedValues(transformedData || []);
    }
  }, [controlledValue]);

  const selectHandler = (value: any) => {
    try {
      // check if it already selected or not
      const isPresent = selectedValues.find(
        (item) => item.toString().toLocaleLowerCase().trim() === getValueFromNestedObject(value, displayKey).toString().toLocaleLowerCase().trim()
      );

      if (isPresent) {
        // if already selected remove it from the list
        const newList = selectedValues.filter((item) => item !== getValueFromNestedObject(value, displayKey));
        onSelectionChange(value, "remove");
        setSelectedValues(newList);
      } else {
        // otherwise add it to the list
        setSelectedValues((prev) => [...prev, getValueFromNestedObject(value, displayKey)]);
        onSelectionChange(value, "add");
      }
    } catch (error) {
      setSelectedValues([]);
      onSelectionChange("", "reset");
    }
  };

  return (
    <Combobox value={selectedValues} disabled={disabled} nullable multiple>
      <div className="relative">
        <Combobox.Input
          className={`border w-[12.375rem] rounded-lg  focus:bg-white focus:outline-none text-sm line-height-normal font-normal text-[#1E1E1E] px-6 py-2 ${inputClassName}`}
          onChange={inputChangeHandler}
          placeholder={placeholder}
          autoComplete="off"
          displayValue={(selectedValues: any[]) => selectedValues.join(", ")}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <img src={SearchIcon} alt="search icon" />
        </Combobox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options
            className={`bg-white border absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md  py-2 text-base shadow-lg   focus:outline-none sm:text-sm z-10 ${dropdownClassName}`}
          >
            {query && !filteredQuery.length && <p className="ml-2">Not found...</p>}
            {filteredQuery?.slice(0, 20).map((item) => (
              <Combobox.Option key={uniqueKey ? item[uniqueKey] : item.id} value={getValueFromNestedObject(item, displayKey)}>
                {({ active, selected }) => (
                  <div
                    onClick={() => {
                      selectHandler(item);
                    }}
                    className={cn(
                      `${
                        active ? "bg-purple-50 text-purple-700" : ""
                      }  px-2 py-3 cursor-pointer flex justify-between items-cen ter gap-3 font-semibold`
                    )}
                  >
                    {getValueFromNestedObject(item, displayKey)}
                    <Checkbox checked={selected} onChange={() => {}} />
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default MultiAutocomplete;
