/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DivisionState {
  divisionList: any[] | null | undefined;
  addFormDivisionDetails: any[] | any | null | undefined;
  orderDiscountData: any[] | any | null | undefined;
  selectedData: any | any | null | undefined;
  tableData: any[] | any | null | undefined;
  showAddEditForm: boolean;
  isEdit: boolean;
}

const divisionSlice = createSlice({
  name: "division",
  initialState: {
    divisionList: null,
    orderDiscountData: null,
    selectedData: null,
    tableData: null,
    showAddEditForm: false,
    isEdit: false,
  } as DivisionState,
  reducers: {
    setDivisionList(state, action: PayloadAction<any[] | null | undefined>) {
      state.divisionList = action.payload;
    },
    setOrderDiscountList(state, action: PayloadAction<any[] | null | undefined>) {
      state.orderDiscountData = action.payload;
    },
    setAddFormDivisionDetails(state, action: PayloadAction<any[] | null | any | undefined>) {
      state.addFormDivisionDetails = action.payload;
    },
    setSelectedData(state, action: PayloadAction<any[] | null | undefined | any>) {
      state.selectedData = action.payload;
    },
    setTableData(state, action: PayloadAction<any[] | null | undefined | any>) {
      state.tableData = action.payload;
    },
    toggleAddEditForm(state) {
      if (state.showAddEditForm) {
        state.showAddEditForm = false;
        state.isEdit = false;
      } else {
        state.showAddEditForm = true;
      }
    },
    setIsEdit(state, action: PayloadAction<boolean>) {
      state.isEdit = action.payload;
    },
  },
});

export const divisionActions = divisionSlice.actions;
export { divisionSlice };
