import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AccessControlState {
  menus: any | null | undefined;
}

const accessControlSlice = createSlice({
  name: "accessControl",
  initialState: {
    menus: null,
  } as AccessControlState,
  reducers: {
    fetchAccessControl(state, action: PayloadAction<string | null | undefined>) {
      state.menus = action.payload;
    },
  },
});

export const accessControlActions = accessControlSlice.actions;
export { accessControlSlice };
