import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { api, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
let eventInstance: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/company",
  });
  // Add the interceptor to the instance
  instance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });

  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};

const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/company",
  });

  eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};

axiosEventInstance();

axiosInstance();

export const getAllCompanyDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const queryCompanyDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};

export const queryPriceIdentifier = async (query: string) => {
  return instance.get(`/price-identifier${query}`);
};

export const queryCounterPriceIdentifier = async (query: string) => {
  return instance.get(`/priceIdentifier-list${query}`);
};

export const getPriceIdentifierList = async (query: string) => {
  return instance.get(`/priceIdentifier-list_counterproduct${query}`);
};

export const getClusterFromCompany = async (data: any) => instance.get(`/cluster?company_code=${data}`);

export const addCompany = async (data: any) => eventInstance.post("/add", { data: data, event_type: "COMPANY_ADD" });

export const editCompany = async (data: any, primary_key: string | number) =>
  eventInstance.put("/edit", {
    data,
    event_type: "COMPANY_EDIT",
    primary_key,
  });
