import { Switch } from "@headlessui/react";
import { FC } from "react";
import { cn } from "src/utils/utils";

interface ToggleProps {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  showModal?: () => void;
  disabled?: boolean;
}

const Toggle: FC<ToggleProps> = ({ showModal, checked, onChange, disabled }) => {
  const handleToggler = (newChecked: boolean) => {
    onChange(newChecked);
    if (!checked && showModal) {
      showModal();
    }
  };

  return (
    <>
      <Switch
        checked={checked}
        onChange={handleToggler}
        disabled={disabled}
        className={cn(`${checked ? "bg-[#7F56D9]" : "bg-gray-700"}
          relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`)}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={cn(`${checked ? "translate-x-4" : "translate-x-0"}
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`)}
        />
      </Switch>
    </>
  );
};

export default Toggle;
