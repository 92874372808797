class EventEmitter {
  private listeners: { [event: string]: Function[] } = {};

  public on(event: string, listener: Function): void {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(listener);
  }

  public emit(event: string, data?: any): void {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data));
    }
  }

  public off(event: string, listener: Function): void {
    if (this.listeners[event]) {
      const index = this.listeners[event].indexOf(listener);
      if (index > -1) {
        this.listeners[event].splice(index, 1);
      }
    }
  }
}

export const geographyEvent = new EventEmitter();
export const employeeEvent = new EventEmitter();
export { EventEmitter };
