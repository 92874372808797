import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  token: string | null | undefined;
  loggedInUser: string | null | undefined;
  currentPsr: any | null | undefined;
  idTokenDetails: any | null | undefined;
  isAdmin: boolean;
  restricted: boolean;
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    loggedInUser: null,
    currentPsr: null,
    idTokenDetails: null,
    isAdmin: false,
    restricted: false,
  } as AuthState,
  reducers: {
    login(state, action: PayloadAction<string | null | undefined>) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = null;
    },
    setLoggedInUser(state, action: PayloadAction<string | null | undefined>) {
      state.loggedInUser = action.payload;
    },
    removeLoggedInUser(state) {
      state.loggedInUser = null;
    },
    setCurrentPsr(state, action: PayloadAction<any | null | undefined>) {
      state.currentPsr = action.payload;
    },
    setIdTokenDetails(state, action: PayloadAction<any | null | undefined>) {
      state.idTokenDetails = action.payload;
    },
    setIsAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    setRestricted(state, action: PayloadAction<boolean>) {
      state.restricted = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export { authSlice };
