import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { api, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
// let eventInstance: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/bulkUpload",
  });
  // Add the interceptor to the instance
  instance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });

  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};

axiosInstance();

export const bulkUploadEmployee = async (formData: FormData, onUploadProgress: any) => {
  return instance.post(`/employee`, formData, onUploadProgress);
};

export const getFormatFileEmployee = async (): Promise<string> => {
  return instance.get("/employee-format");
};

export const getFormatFileCounter = async (): Promise<string> => {
  return instance.get("/counter-format");
};
export const bulkUploadCounter = async (formData: FormData, onUploadProgress: any) => {
  return instance.post(`/counter`, formData, onUploadProgress);
};
