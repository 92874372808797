import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MastersState {
  tableData: null | any[];
  selectedData: any;
  error: null | string;
  pathParams: string;
  autocompleteFilters: any;
  autocompleteSuggestionList: any;
}

const mastersSlice = createSlice({
  name: "masters",
  initialState: {
    tableData: null,
    selectedData: {},
    error: null,
    pathParams: "",
    autocompleteFilters: {
      company: { name: "", id: "" },
      product: { name: "", id: "" },
      division: { name: "", id: "" },
      employee: { name: "", id: "" },
    },
    autocompleteSuggestionList: {
      company: [],
      product: [],
      division: [],
      employee: [],
    },
  } as MastersState,
  reducers: {
    setSelectedData(state, action: PayloadAction<any>) {
      state.selectedData = action.payload;
    },
    setTableData(state, action: PayloadAction<any>) {
      if (action?.payload) state.tableData = [...action.payload];
      else state.tableData = null;
    },
    appendProductData(state, action: PayloadAction<any>) {
      state.tableData?.push(action.payload);
    },
    SetParams(state, action: PayloadAction<any>) {
      state.pathParams = action.payload;
    },

    setAutocompleteFilters(state, action: PayloadAction<any>) {
      const { name, id, filterName } = action.payload;
      state.autocompleteFilters = {
        ...state.autocompleteFilters,
        [filterName]: { name, id },
      };
    },

    removeAutocompleteFilters(state, action: PayloadAction<any>) {
      const filterName = action.payload;
      state.autocompleteFilters[filterName] = { name: "", id: "" };
    },

    removeAllAutocompleteFilters(state) {
      state.autocompleteFilters = {
        company: { name: "", id: "" },
        product: { name: "", id: "" },
        division: { name: "", id: "" },
        employee: { name: "", id: "" },
      };
    },

    setAutocompleteSuggestionList(state, action: PayloadAction<any>) {
      const { filterName, suggestionList } = action.payload;
      state.autocompleteSuggestionList = {
        ...state.autocompleteSuggestionList,
        [filterName]: suggestionList,
      };
    },

    removeAutocompleteSuggestionList(state, action: PayloadAction<any>) {
      const filterName = action.payload;
      state.autocompleteSuggestionList[filterName] = [];
    },

    removeAllAutocompleteSuggestionList(state) {
      state.autocompleteSuggestionList = {
        company: [],
        product: [],
        division: [],
        employee: [],
      };
    },
  },
});

export const masterActions = mastersSlice.actions;
export { mastersSlice };
