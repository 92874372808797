import { yupResolver } from "@hookform/resolvers/yup";
import { createContext, useContext, Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Input, Toggle, Checkbox } from "src/components";
import TextArea from "src/components/ui/text-area.component";
import { DownloadIcon } from "lucide-react";
import CloseIcon from "src/features/fields/assets/close-cross.svg";
import fileIcon from "src/features/fields/assets/draft.svg";
import uploadIcon from "src/features/fields/assets/upload.svg";
import ErrorMessage from "src/container/error-message.component";
import Badge from "src/components/ui/badge.component";
import { useToast } from "src/hooks/useToast";
import { cn, isFieldRequired } from "src/utils/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/components/ui/tooltip.component";

interface FormContextProps {
  uploadedFiles: Record<string, any>;
  setUploadFiles: any;
  removeFile: any;
  handleFileDownload: any;
  getValues: any;
}
interface ProductFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormSubmit: (value: any) => void;
  formFields: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues?: any;
  filesDefaultValues?: any;
  fileUploadKeyMap?: any;
  children?: ReactNode;
  schema?: any;
  onAutocompleteChange?: (value: any, fieldName?: string) => void;
  disabledFieldArray?: any[];
  isControlled?: boolean;
  inputHandler?: any;
  formInstance?: any;
  fieldsToWatch?: any[];
  isFormChangedCheck?: boolean;
}

// interface FormErrorProps {
//   message: string;
//   show: boolean;
// }

interface FormFieldsProps {
  id: number | string;
  placeholder?: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyofForm: string;
  bindingKey: string;
  type: string;
  suggestionList?: any[];
  size?: string;
  asyncListFunction?: (query?: string | number) => Promise<any[][]>;
  displayKey?: string;
  disabled?: boolean;
  isAsyncQuery?: boolean;
  uniqueKey?: string;
  validation?: any | any[];
  additionalDisplayKey?: string;
  additionalDisplayKeySearch?: boolean;
  showCityInHospital?: boolean;
  readonly?: boolean;
  min?: string;
  fileUploadKey?: any;
  is_attachment?: boolean;
  tooltipContent?: string;
}

const FormContext = createContext<FormContextProps | null>(null);

const FormProvider: React.FC<{
  children: React.ReactNode;
  value: FormContextProps;
}> = ({ children, value }) => {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const GenericForm: React.FC<ProductFormProps> = ({
  formFields,
  onFormSubmit,
  defaultValues = {},
  filesDefaultValues = [],
  fileUploadKeyMap = {},
  children,
  onAutocompleteChange,
  schema,
  disabledFieldArray,
  isControlled,
  inputHandler,
  formInstance,
  fieldsToWatch = [],
  isFormChangedCheck = true,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
  } = formInstance
    ? formInstance
    : useForm({
        resolver: schema ? yupResolver(schema) : undefined,
        defaultValues,
        mode: "onBlur",
      });

  // const { isValidating } = useFormState({ control });

  const previousValuesRef = useRef(structuredClone(defaultValues));

  const [defaultValuesToCheck, setDefaultValuesToCheck] = useState(defaultValues);

  const submitTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState<any>({});

  useEffect(() => {
    setDefaultValuesToCheck(defaultValues);
  }, []);

  useEffect(() => {
    if (filesDefaultValues && filesDefaultValues?.length > 0) {
      const attachmentsObject: any = {};
      filesDefaultValues.forEach((item: any) => {
        if (!attachmentsObject[item?.validation_rules_id?.validation_rules_id]) {
          attachmentsObject[item?.validation_rules_id?.validation_rules_id] = [];
        }
        attachmentsObject[item?.validation_rules_id?.validation_rules_id].push(item);
      });
      setUploadedFiles(attachmentsObject);
    }
  }, []);

  useEffect(() => {
    if (isControlled)
      reset(structuredClone(defaultValues), {
        keepDirty: false,
        keepDirtyValues: false,
        keepValues: false,
        keepTouched: false,
        keepDefaultValues: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
        keepIsValid: false,
        keepErrors: false,
      });
  }, [defaultValues]);

  useEffect(() => {
    if (formInstance) {
      fieldsToWatch.forEach(({ fieldName, handler }) => {
        const isFieldDirty = fieldName in formInstance.formState.dirtyFields;

        if (isFieldDirty) {
          const fieldValue = formInstance.watch(fieldName);
          const previousValue = previousValuesRef.current[fieldName];

          if (fieldValue !== previousValue) {
            handler(fieldValue);
            previousValuesRef.current[fieldName] = fieldValue;
          }
        }
      });
    }
  }, [formInstance, ...fieldsToWatch.map(({ fieldName }) => formInstance?.watch(fieldName))]);

  const { toast } = useToast();

  const onSubmit = (data: any) => {
    try {
      if (isSubmittingForm) return;
      console.log("Form submission timer started");
      setIsSubmittingForm(true);

      // Clear any existing timeout
      if (submitTimeoutRef.current) {
        console.log("Clearing existing timeout");
        clearTimeout(submitTimeoutRef.current);
      }
      // Set a timeout to reset isSubmitting state
      submitTimeoutRef.current = setTimeout(() => {
        setIsSubmittingForm(false);
        console.log("Form submission timeout");
      }, 500);

      if (isFormChangedCheck) {
        if (JSON.stringify(defaultValuesToCheck) === JSON.stringify(data)) {
          toast({
            description: "Please make some changes in the form",
            variant: "destructive",
          });
          return;
        }
      }

      if (
        formInstance &&
        formInstance.getValues("gst_available") &&
        formInstance.getValues("gst_available").id === "No" &&
        uploadedFiles["9"] &&
        uploadedFiles["9"].length > 0
      ) {
        removeFile("9", 0);
        toast({
          description: "Cannot add attachment for GST as GST available is 'No'",
          variant: "default",
        });
      }

      if (Object.keys(uploadedFiles).length > 0) {
        const finalData = { ...data, fileData: uploadedFiles };
        onFormSubmit(finalData);
      } else onFormSubmit(data);
    } catch (error) {
      setIsSubmittingForm(false);
      console.log(error);
    }
  };

  const setUploadFiles = (data: any, key: string) => {
    setUploadedFiles((prev: any) => {
      return { ...prev, [key]: data };
    });
  };

  const removeFile = (fieldName: string, index: number) => {
    setUploadedFiles((prev: any) => {
      let updatedState = structuredClone(prev);

      updatedState?.[fieldName]?.splice(index, 1);
      return updatedState;
    });
  };

  const handleFileDownload = (file: any) => {
    if (!file?.upload_path) return;
    const a = document.createElement("a");
    a.href = file?.upload_path;
    a.download = file?.upload_path?.split("/").pop();
    a.click();
  };

  return (
    <form className="w-full" onReset={reset} onSubmit={handleSubmit(onSubmit)}>
      {disabledFieldArray?.length && (
        <section className="flex justify-center w-full">
          <div className="w-full mx-6 my-3 bg-gray-100 border rounded-lg">
            {disabledFieldArray?.map((item: any, index: number) => (
              <Fragment key={item?.heading}>
                <div
                  className={`flex justify-between px-5 hover:bg-gray-300 py-2 ${
                    index === 0 && disabledFieldArray.length > 0 ? "border-b-[1px]" : ""
                  } ${index === disabledFieldArray.length - 1 && disabledFieldArray.length > 0 ? "border-t-0" : ""}
              ${index !== 0 && index !== disabledFieldArray.length - 1 ? "border-b-[1px]" : ""}
              `}
                >
                  <p className="text-sm font-normal text-[#49484C]">{item?.heading || ""}</p>
                  <p className="text-[#1B1A1F] font-semibold text-sm">{item?.data || ""}</p>
                </div>
              </Fragment>
            ))}
          </div>
        </section>
      )}
      <FormProvider value={{ uploadedFiles, setUploadFiles, removeFile, handleFileDownload, getValues }}>
        <div className="grid items-start w-full grid-cols-1 p-6 sm:grid-cols-2 place-items-center gap-x-6 gap-y-3 ">
          {formFields?.map(
            ({
              label,
              type,
              id,
              placeholder,
              keyofForm,
              bindingKey,
              suggestionList,
              disabled,
              size,
              displayKey,
              asyncListFunction,
              isAsyncQuery,
              validation,
              additionalDisplayKey,
              additionalDisplayKeySearch,
              showCityInHospital,
              readonly,
              min,
              fileUploadKey,
              is_attachment,
              tooltipContent = "Upload file",
            }: FormFieldsProps) => {
              switch (type?.toString()?.toLocaleLowerCase().trim()) {
                case "autocomplete":
                  return (
                    <div className={cn(`${size === "full" ? "col-span-2" : ""} flex flex-col w-full`)} key={id}>
                      <p className="text-[#A4A3A5] text-xs ml-3 mb-1">{isFieldRequired(keyofForm, schema) ? `${label}*` : label}</p>
                      <Controller
                        name={keyofForm as string}
                        disabled={disabled ? true : false}
                        control={control}
                        defaultValue={(defaultValues?.[keyofForm] as string) || ""}
                        render={({ field }) => (
                          <>
                            <Autocomplete
                              showCityInHospital={showCityInHospital}
                              suggestionList={suggestionList || []}
                              isAsyncQuery={isAsyncQuery}
                              asyncListFunction={asyncListFunction || undefined}
                              uniqueKey="name"
                              readonly={readonly}
                              placeholder="Search the values"
                              displayKey={(displayKey as string) || ""}
                              additionalDisplayKey={additionalDisplayKey}
                              additionalDisplayKeySearch={additionalDisplayKeySearch ? true : false}
                              inputClassName="w-full h-[30px]"
                              disabled={disabled ? true : false}
                              defaultValue={
                                typeof field.value === "object"
                                  ? additionalDisplayKey
                                    ? `${field?.value?.[(displayKey as string) || ""]} - ${field?.value?.[(additionalDisplayKey as string) || ""]}`
                                    : field?.value?.[(displayKey as string) || ""]
                                  : field.value
                              }
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              onSelectionChange={(e: any) => {
                                field.onChange(e);
                                if (onAutocompleteChange) onAutocompleteChange(e, keyofForm);
                              }}
                            />
                          </>
                        )}
                      />
                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );

                case "toggle":
                  return (
                    <div key={id}>
                      <Controller
                        name={keyofForm as string}
                        control={control}
                        render={({ field }) => (
                          <div className={cn(`flex items-center gap-2 justify-end mt-7`)}>
                            <Toggle
                              disabled={disabled ? true : false}
                              checked={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            />
                            <p className={cn(`text-[#7b7a7c] font-medium text-xs`)}>{label}</p>
                          </div>
                        )}
                      />
                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );

                case "textarea":
                  return (
                    <div className={cn(`${size === "full" ? "col-span-2" : "col-span-1"} w-full`)} key={id}>
                      <Controller
                        name={keyofForm as string}
                        disabled={disabled ? true : false}
                        control={control}
                        defaultValue={defaultValues[keyofForm] || ""}
                        rules={validation || {}}
                        render={({ field }) => (
                          <>
                            <TextArea
                              {...field}
                              onChange={field.onChange}
                              label={isFieldRequired(keyofForm, schema) ? `${label}*` : label}
                              value={field.value}
                              className="w-full mr-auto"
                              onBlur={field.onBlur}
                              placeholder={placeholder}
                            />
                          </>
                        )}
                      />

                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );
                case "checkbox":
                  return (
                    <div className={cn(`${size === "full" ? "col-span-2" : "col-span-1"} w-full`)} key={id}>
                      <Controller
                        name={keyofForm as string}
                        disabled={disabled ? true : false}
                        control={control}
                        defaultValue={defaultValues[keyofForm] || ""}
                        rules={validation || {}}
                        render={({ field }) => (
                          <>
                            <Checkbox
                              {...field}
                              onChange={(e: any) => {
                                const checked = e.target.checked; // get the checked state directly from the event
                                field.onChange(checked); // update the field with the boolean value
                                if (inputHandler) inputHandler(checked, keyofForm); // pass the boolean value to the handler if needed
                              }}
                              label={label}
                              checked={field.value}
                              className="w-full mr-auto"
                            />
                          </>
                        )}
                      />

                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );

                case "inputfileupload":
                  return (
                    <div className={cn(`${size === "full" ? "col-span-2" : "col-span-1"} w-full`)} key={id}>
                      <Controller
                        name={keyofForm as string}
                        disabled={disabled ? true : false}
                        control={control}
                        defaultValue={defaultValues[keyofForm] || ""}
                        rules={validation || {}}
                        render={({ field }) => {
                          const contextValue = useContext(FormContext);

                          if (!contextValue) {
                            throw new Error("SmartFormInput must be used within a FormProvider");
                          }

                          return (
                            <>
                              <div className="relative">
                                <Input
                                  {...field}
                                  readonly={readonly}
                                  onChange={(e) => {
                                    const event: any = e;
                                    // remove white space from front only.
                                    event.target.value = event.target.value.replace(/^\s+/, "");
                                    field.onChange(event);
                                    if (inputHandler) inputHandler(event, keyofForm);
                                  }}
                                  type={type}
                                  label={isFieldRequired(keyofForm, schema) ? `${label}*` : label}
                                  value={field.value}
                                  className="w-full mr-auto"
                                  onBlur={field.onBlur}
                                  placeholder={placeholder}
                                  min={min}
                                />

                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <button className="absolute flex items-center justify-center w-5 h-5 p-0.5 bg-gray-200 border rounded-full cursor-pointer top-6 right-3">
                                        <img src={uploadIcon} alt="upload" className="cursor-pointer" />
                                        <input
                                          type="file"
                                          multiple
                                          disabled={
                                            contextValue.getValues("gst_available")
                                              ? contextValue.getValues("gst_available").id === "No" && field?.name === "gst_number"
                                              : false
                                          }
                                          className="absolute top-0 left-0 max-w-full max-h-full opacity-0 cursor-pointer"
                                          onChange={(e: any) => {
                                            if (e.target.files.length > 1 && is_attachment) {
                                              setError(field.name, {
                                                type: label,
                                                message: "You can only upload a maximum of 1 image",
                                              });
                                              return;
                                            }

                                            // TODO: Handle file upload
                                            const incomingFiles = Array.from(e.target.files);
                                            const existingFiles = contextValue.uploadedFiles?.[fileUploadKey || fileUploadKeyMap[bindingKey]] ?? [];
                                            const updatedFileList = [...existingFiles, ...incomingFiles];
                                            // also it should not exceed 1 file
                                            if (updatedFileList.length > 1) {
                                              setError(field.name, {
                                                type: label,
                                                message: "You can only upload a maximum of 1 image",
                                              });
                                              return;
                                            }
                                            contextValue.setUploadFiles(updatedFileList, fileUploadKey || fileUploadKeyMap[bindingKey]);
                                          }}
                                        />
                                      </button>
                                    </TooltipTrigger>
                                    <TooltipContent>{tooltipContent || "Upload file"}</TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </div>
                              <div className="flex flex-col items-start flex-wrap max-w-full gap-3 mt-1">
                                {contextValue.uploadedFiles?.[fileUploadKey || fileUploadKeyMap[bindingKey]]?.map((file: any, index: number) => {
                                  let fileName = "";
                                  if (file?.attachment_path) {
                                    fileName = file?.attachment_path?.split("/").pop();
                                  } else {
                                    fileName = file?.name?.length > 30 ? `${file?.name?.substring(0, 30)}...` : file?.name;
                                  }
                                  return (
                                    <Badge key={fileName} className={`gap-1.5 border bg-gray-50 max-w-full `}>
                                      <img src={fileIcon} alt="file icon" />
                                      {fileName}
                                      <img
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          removeFile(fileUploadKey || fileUploadKeyMap[bindingKey], index);
                                        }}
                                        src={CloseIcon}
                                        className="cursor-pointer"
                                      />
                                      {file?.upload_path && (
                                        <DownloadIcon
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleFileDownload(file);
                                          }}
                                          className="w-4 cursor-pointer"
                                        />
                                      )}
                                    </Badge>
                                  );
                                })}
                              </div>
                            </>
                          );
                        }}
                      />

                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );

                case "autocompletefileupload":
                  return (
                    <div className={cn(`${size === "full" ? "col-span-2" : "col-span-1"} w-full`)} key={id}>
                      <Controller
                        name={keyofForm as string}
                        disabled={disabled ? true : false}
                        control={control}
                        defaultValue={(defaultValues?.[keyofForm] as string) || ""}
                        render={({ field }) => {
                          const contextValue = useContext(FormContext);

                          if (!contextValue) {
                            throw new Error("SmartFormInput must be used within a FormProvider");
                          }

                          return (
                            <>
                              <div className="relative" style={{ height: "53px" }}>
                                <p className="text-[#7b7a7c] font-medium text-xs ml-3 mb-1">{label}</p>
                                <Autocomplete
                                  showCityInHospital={showCityInHospital}
                                  suggestionList={suggestionList || []}
                                  isAsyncQuery={isAsyncQuery}
                                  asyncListFunction={asyncListFunction || undefined}
                                  uniqueKey="name"
                                  readonly={readonly}
                                  placeholder="Search the values"
                                  displayKey={(displayKey as string) || ""}
                                  additionalDisplayKey={additionalDisplayKey}
                                  additionalDisplayKeySearch={additionalDisplayKeySearch ? true : false}
                                  inputClassName="w-full h-[30px]"
                                  disabled={disabled ? true : false}
                                  defaultValue={
                                    typeof field.value === "object"
                                      ? additionalDisplayKey
                                        ? `${field?.value?.[(displayKey as string) || ""]} - ${field?.value?.[
                                            (additionalDisplayKey as string) || ""
                                          ]}`
                                        : field?.value?.[(displayKey as string) || ""]
                                      : field.value
                                  }
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  onSelectionChange={(e: any) => {
                                    field.onChange(e);
                                    if (onAutocompleteChange) onAutocompleteChange(e, keyofForm);
                                  }}
                                  is_attachment={true}
                                />

                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <button className="absolute flex items-center justify-center w-5 h-5 p-0.5 bg-gray-200 border rounded-full cursor-pointer top-6 right-3">
                                        <img src={uploadIcon} alt="upload" className="cursor-pointer" />
                                        <input
                                          type="file"
                                          multiple
                                          className="absolute top-0 left-0 max-w-full max-h-full opacity-0 cursor-pointer"
                                          onChange={(e: any) => {
                                            if (e.target.files.length > 1 && is_attachment) {
                                              setError(field.name, {
                                                type: label,
                                                message: "You can only upload a maximum of 1 image",
                                              });
                                              return;
                                            }

                                            // TODO: Handle file upload
                                            const incomingFiles = Array.from(e.target.files);
                                            const existingFiles = contextValue.uploadedFiles?.[fileUploadKey || fileUploadKeyMap[bindingKey]] ?? [];
                                            const updatedFileList = [...existingFiles, ...incomingFiles];
                                            // also it should not exceed 1 file
                                            if (updatedFileList.length > 1) {
                                              setError(field.name, {
                                                type: label,
                                                message: "You can only upload a maximum of 1 image",
                                              });
                                              return;
                                            }
                                            contextValue.setUploadFiles(updatedFileList, fileUploadKey || fileUploadKeyMap[bindingKey]);
                                          }}
                                        />
                                      </button>
                                    </TooltipTrigger>
                                    <TooltipContent>{tooltipContent || "Upload file"}</TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </div>
                              <div className="flex flex-wrap flex-col items-start max-w-full gap-3 mt-1">
                                {contextValue.uploadedFiles?.[fileUploadKey || fileUploadKeyMap[bindingKey]]?.map((file: any, index: number) => {
                                  let fileName = "";
                                  if (file?.attachment_path) {
                                    fileName = file?.attachment_path?.split("/").pop();
                                  } else {
                                    fileName = file?.name?.length > 30 ? `${file?.name?.substring(0, 30)}...` : file?.name;
                                  }
                                  return (
                                    <Badge key={fileName} className={`gap-1.5 border bg-gray-50 max-w-full `}>
                                      <img src={fileIcon} alt="file icon" />
                                      {fileName}
                                      <img
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          removeFile(fileUploadKey || fileUploadKeyMap[bindingKey], index);
                                        }}
                                        src={CloseIcon}
                                        className="cursor-pointer"
                                      />
                                      {file?.upload_path && (
                                        <DownloadIcon
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleFileDownload(file);
                                          }}
                                          className="w-4 cursor-pointer"
                                        />
                                      )}
                                    </Badge>
                                  );
                                })}
                              </div>
                            </>
                          );
                        }}
                      />

                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );

                default:
                  return (
                    <div className={cn(`${size === "full" ? "col-span-2" : "col-span-1"} w-full`)} key={id}>
                      <Controller
                        name={keyofForm as string}
                        disabled={disabled ? true : false}
                        control={control}
                        defaultValue={defaultValues[keyofForm] || ""}
                        rules={validation || {}}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              readonly={readonly}
                              onChange={(e) => {
                                const event: any = e;
                                // remove white space from front only.
                                event.target.value = event.target.value.replace(/^\s+/, "");
                                field.onChange(event);
                                if (inputHandler) inputHandler(event, keyofForm);
                              }}
                              type={type}
                              label={isFieldRequired(keyofForm, schema) ? `${label}*` : label}
                              value={field.value}
                              className="w-full mr-auto"
                              onBlur={field.onBlur}
                              placeholder={placeholder}
                              min={min}
                            />
                          </>
                        )}
                      />

                      <ErrorMessage
                        label={label}
                        type={errors[keyofForm]?.type as string}
                        show={!!errors[keyofForm]?.message}
                        message={(errors[keyofForm]?.message as string) || ""}
                      />
                    </div>
                  );
              }
            }
          )}
        </div>
        {children}
      </FormProvider>
    </form>
  );
};

export default GenericForm;
