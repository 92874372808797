/* eslint-disable @typescript-eslint/no-explicit-any */
import { Combobox, Transition } from "@headlessui/react";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import SearchIcon from "src/assets/down-arrow.svg";
import { cn, getValueFromNestedObject } from "src/utils/utils";
import { Spinner } from "src/components";
import InfiniteScroll from "react-infinite-scroll-component";

interface AutocompleteProps {
  placeholder: string;
  inputClassName?: string;
  type?: string;
  dropdownClassName?: string;
  onSelectionChange?: any;
  suggestionList: Array<any>;
  displayKey: string;
  uniqueKey?: string;
  disabled?: boolean;
  currentSelected?: any;
  defaultValue?: any;
  additionalfield?: string;
  asyncListFunction?: any;
  isAsyncQuery?: boolean;
  additionalDisplayKey?: string;
  additionalDisplayKeySearch?: boolean;
  showCityInHospital?: boolean;
  isCounterMerge?: boolean;
  tertiaryDisplayKey?: string;
  readonly?: boolean;
  is_attachment?: boolean;
}

const Autocomplete: FC<AutocompleteProps> = ({
  placeholder,
  inputClassName,
  type,
  dropdownClassName,
  onSelectionChange,
  suggestionList,
  displayKey,
  uniqueKey,
  disabled,
  defaultValue,
  asyncListFunction,
  isAsyncQuery = true,
  additionalDisplayKey,
  additionalDisplayKeySearch = false,
  showCityInHospital = false,
  isCounterMerge = false,
  tertiaryDisplayKey,
  readonly = false,
  is_attachment = false,
}) => {
  const [selected, setSelected] = useState<string | number>("");
  const [query, setQuery] = useState<string>("");
  const [asyncList, setAsyncList] = useState<Array<any>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentDataSize, setCurrentDataSize] = useState(25);

  useEffect(() => {
    if (asyncListFunction && isAsyncQuery) {
      setIsFetching(true);
      asyncListFunction(query)
        .then((res: any) => {
          if (Array.isArray(res)) {
            setAsyncList(res);
          } else if (res?.data && Array.isArray(res.data)) {
            setAsyncList(res.data);
          } else if (res?.data && Array.isArray(res?.data?.data)) {
            setAsyncList(res.data.data);
          }
        })
        .catch((err: any) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (asyncListFunction && !isAsyncQuery) {
      setIsFetching(true);
      asyncListFunction()
        .then((res: any) => {
          if (Array.isArray(res)) {
            setAsyncList(res);
          } else if (res?.data && Array.isArray(res.data)) {
            setAsyncList(res.data);
          } else if (res?.data && Array.isArray(res?.data?.data)) {
            setAsyncList(res.data.data);
          }
        })
        .catch((err: any) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  }, [query]);

  useEffect(() => {
    if (defaultValue === "" || !defaultValue) setSelected("");
    if (typeof defaultValue === "string") setSelected("");
    if (getValueFromNestedObject(defaultValue, displayKey)) {
      if (additionalDisplayKey) {
        setSelected(`${getValueFromNestedObject(defaultValue, displayKey)} - ${defaultValue[additionalDisplayKey]}`);
      } else setSelected(getValueFromNestedObject(defaultValue, displayKey));
    } else setSelected("");
  }, [defaultValue]);

  const changeHandler = (value: any) => {
    if (onSelectionChange) onSelectionChange(value);
    if (!value) setSelected("");
    if (value) {
      if (additionalDisplayKey) {
        setSelected(`${getValueFromNestedObject(value, displayKey)} - ${getValueFromNestedObject(value, additionalDisplayKey)}`);
      } else setSelected(getValueFromNestedObject(value, displayKey));
      setQuery(getValueFromNestedObject(value, displayKey));
    }
  };

  let queryTimer: any = null;
  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (asyncListFunction) {
      clearTimeout(queryTimer);

      queryTimer = setTimeout(() => {
        setQuery(event.target.value);
      }, 500);
    } else setQuery(event.target.value);

    if (event.target.value === "") {
      setSelected("");
    }
  };

  const filteredList = React.useMemo(() => {
    const listToFilter = asyncListFunction ? asyncList : suggestionList;

    if (!Array.isArray(listToFilter)) return [];

    return type === "city"
      ? listToFilter?.filter(
        (item) =>
          getValueFromNestedObject(item, displayKey)
            ?.toString()
            ?.toLowerCase()
            .split("|")[0]
            .includes(query?.toString()?.toLowerCase().replace(/\s+/g, "") as string)
      )
      : listToFilter?.filter((item) => {
        return additionalDisplayKeySearch
          ? getValueFromNestedObject(item, displayKey)
            ?.toString()
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query?.toString()?.toLowerCase().replace(/\s+/g, "") as string) ||
          item[additionalDisplayKey!]
            ?.toString()
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query?.toString()?.toLowerCase().replace(/\s+/g, "") as string)
          : getValueFromNestedObject(item, displayKey)
            ?.toString()
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query?.toString()?.toLowerCase().replace(/\s+/g, "") as string);
      });
  }, [suggestionList, asyncList, query]);

  const fetchMoreData = async () => {
    if (!asyncListFunction) return;
    if (isFetching) return; // Prevent multiple fetches at the same time
    setIsFetching(true); // Set the fetching state to true
    try {
      const res = await asyncListFunction(query, currentPage);
      if (res?.status !== 200) new Error();
      setCurrentPage((prev) => prev + 1);
      setCurrentDataSize((prev) => prev + 15);
      // Assuming the response is an array of items
      if (Array.isArray(res)) {
        setAsyncList((prevList) => [...prevList, ...res]);
      } else if (res?.data && Array.isArray(res.data)) {
        setAsyncList((prevList) => [...prevList, ...res.data]);
      } else if (res?.data && Array.isArray(res?.data?.data)) {
        setAsyncList((prevList) => [...prevList, ...res.data.data]);
      }
    } catch (err) {
      console.error(err); // Log the error to the console
    } finally {
      setIsFetching(false); // Reset the fetching state
    }
  };

  // const handleScroll = useCallback((event: any) => {
  //   // if (asyncList?.length < 5) return;
  //   // if (!asyncListFunction) return;
  //   const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
  //   if (scrollHeight - scrollTop <= clientHeight * 1.5 && !isFetching) {
  //     console.log(currentPage);
  //     fetchMoreData();
  //     // Load more items when the user is 1.5 times the clientHeight away from the bottom
  //   }
  // }, []);

  const handleServerSideApiCall = () => {
    if (asyncListFunction && isAsyncQuery) {
      setIsFetching(true);
      asyncListFunction(query)
        .then((res: any) => {
          if (Array.isArray(res)) {
            setAsyncList(res);
          } else if (res?.data && Array.isArray(res.data)) {
            setAsyncList(res.data);
          } else if (res?.data && Array.isArray(res?.data?.data)) {
            if (isCounterMerge) {
              // transforming data for counter merge
              // counter ---> multiple location
              // creating individual object for each counter
              const transformedAsyncList: any[] = [];
              res?.data?.data?.forEach((counter: any) => {
                counter?.counter_location?.forEach((counterLocation: any) => {
                  transformedAsyncList.push({
                    counter_name: counter?.counter_name,
                    counter_id: counter?.counter_id,
                    counter_code: counter?.counter_code,
                    location_code: counterLocation?.location_code,
                    location_name: counterLocation?.location_name,
                  });
                });
              });
              setAsyncList(transformedAsyncList || []);
            } else setAsyncList(res.data.data);
          }
        })
        .catch((err: any) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (asyncListFunction && !isAsyncQuery) {
      setIsFetching(true);
      asyncListFunction()
        .then((res: any) => {
          if (Array.isArray(res)) {
            setAsyncList(res);
          } else if (res?.data && Array.isArray(res.data)) {
            setAsyncList(res.data);
          } else if (res?.data && Array.isArray(res?.data?.data)) {
            setAsyncList(res.data.data);
          }
        })
        .catch((err: any) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  return (
    <Combobox value={defaultValue || selected} onChange={changeHandler} disabled={disabled} nullable>
      <div className="relative w-full">
        <Combobox.Input
          className={`border font-semibold w-full rounded-lg  focus:bg-white focus:outline-none text-sm line-height-normal disabled:bg-gray-50  text-[#1E1E1E] px-6 py-2 ${readonly ? "bg-gray-50 cursor-not-allowed" : ""} ${inputClassName}`}
          onChange={inputChangeHandler}
          placeholder={placeholder}
          displayValue={(value) => (value ? value.toString() : "")}
          autoComplete="off"
          readOnly={readonly}
        />

        {!readonly && (
          <Combobox.Button
            onClick={handleServerSideApiCall}
            className={is_attachment ? `absolute inset-y-0 right-8 flex items-center pr-2` : `absolute inset-y-0 right-0 flex items-center pr-2`}
          >
            <img src={SearchIcon} alt="search icon" />
          </Combobox.Button>
        )}
        <Transition
          as={React.Fragment}
          enter="transition duration-300 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-300 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options
            id="scrollArea"
            className={`bg-white border absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md  py-2 text-base shadow-lg   focus:outline-none sm:text-sm z-10 ${dropdownClassName}`}
          >
            {asyncListFunction && isFetching && (
              <div className="flex justify-center my-5">
                <Spinner size="medium" />
              </div>
            )}
            {!query && !isFetching && filteredList?.length === 0 && (
              <p className="ml-2 text-sm font-semibold text-center text-gray-600">No Data...</p>
            )}
            {query && !filteredList.length && <p className="ml-2 text-sm font-semibold text-center text-gray-600">Not found...</p>}

            <InfiniteScroll
              scrollableTarget="scrollArea"
              next={fetchMoreData}
              hasMore={true}
              loader={isFetching ? <h4 className="text-center">Loading...</h4> : <></>}
              dataLength={asyncList.length}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {Array.isArray(filteredList) &&
                filteredList?.slice(0, currentDataSize)?.map((item) => {
                  if (!getValueFromNestedObject(item, displayKey)) return;
                  return (
                    <Combobox.Option key={uniqueKey ? item[uniqueKey] : item.id} value={item}>
                      {({ active }) => (
                        <div className={cn(`${active ? "bg-purple-50 text-purple-700" : ""} px-2 py-3 flex items-center gap-3 font-semibold`)}>
                          {getValueFromNestedObject(item, displayKey)} {showCityInHospital ? ` - ${item?.city_code?.city_name}` : ""}
                          {additionalDisplayKey ? ` - ${getValueFromNestedObject(item, additionalDisplayKey)}` : ""}
                          {tertiaryDisplayKey ? ` - ${getValueFromNestedObject(item, tertiaryDisplayKey)}` : ""}
                        </div>
                      )}
                    </Combobox.Option>
                  );
                })}
            </InfiniteScroll>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default React.memo(Autocomplete);
