import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "src/constants/global-const";
import { getHeaderLinks } from "src/constants/header";
import SettingsIcon from "../../assets/Settings.svg";
import bellIcon from "../../assets/bell.svg";
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuTrigger } from "./dropDownMenu";
import { useSelector } from "react-redux";
import { RootState } from "src/context/store";

// const api: string = "http://localhost:5173/";

const Navbar = () => {
  const [currentSelectedLink, setCurrentSelectedLink] = useState<string>("");
  const { loggedInUser } = useSelector((state: RootState) => state.auth);

  const userRole: any = useSelector((state: RootState) => state.auth);
  const accessControl = useSelector((state: RootState) => state.accessControl);

  useEffect(() => {
    const currentLocation: string = window.location.pathname.split("/")[1];
    setCurrentSelectedLink(currentLocation);
  }, [window.location.pathname]);

  const headerLinks = getHeaderLinks(accessControl);

  return (
    <header className="w-full bg-white ">
      <section className="flex w-full px-[3.75rem] justify-between border-b-[rgba(0, 0, 0, 0.10)] border h-16">
        {/* left side of header  */}
        <nav className="flex gap-[3.75rem]  items-center">
          <h1 className="font-bold text-[#1B1A1F] text-xl">Medvol</h1>
          <ul className="flex gap-10 text-[#A4A3A5] text-base font-medium not-italic capitalize">
            {headerLinks?.map(
              (link: any) =>
                (link.roles.includes(userRole.token["custom:mv_role"]) || link.roles.includes("Any")) && (
                  <Link
                    to={`/${link.value}`}
                    className={`${
                      currentSelectedLink.toString().trim().toLocaleLowerCase() === link.value.toString().trim().toLocaleLowerCase()
                        ? "text-blue-600"
                        : ""
                    }`}
                    key={link.value}
                  >
                    {link.label}
                  </Link>
                )
            )}
          </ul>
        </nav>

        <DropdownMenu>
          <DropdownMenuTrigger className="focus:outline-none">
            <div className="flex items-center gap-4 ">
              <img src={SettingsIcon} alt="settings" className="w-5 h-5 cursor-pointer" />
              <img src={bellIcon} alt="bell" className="w-5 h-5 cursor-pointer" />
              <div className="h-10 w-10 cursor-pointer rounded-[0.625rem] bg-[#D9CDF4] flex items-center justify-center py-3 px-[0.6875rem] text-[#42267F] text-2xl font-bold not-italic">
                {loggedInUser?.charAt(0) || ""}
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white">
            <DropdownMenuLabel>
              <p onClick={() => logout(false)}>Logout</p>
            </DropdownMenuLabel>
          </DropdownMenuContent>
        </DropdownMenu>

        {/* right side of header  */}
        {/* <div className="flex items-center gap-4 ">
          <img src={SettingsIcon} alt="settings" className="w-5 h-5 cursor-pointer" />
          <img src={bellIcon} alt="bell" className="w-5 h-5 cursor-pointer" />
          <div className="h-10 w-10 cursor-pointer rounded-[0.625rem] bg-[#D9CDF4] flex items-center justify-center py-3 px-[0.6875rem] text-[#42267F] text-2xl font-bold not-italic">
            M
          </div>
        </div> */}
      </section>
    </header>
  );
};

export default Navbar;
