export const productKeysArray = [
  { id: 1, value: "ProductName", label: "Product Name - Edit", sendIn: "filter" },
  { id: 2, value: "ItemCodePO", label: "Item Code PO - Edit", sendIn: "filter" },
  { id: 3, value: "BrandName", lable: "Brand Name - Edit", sendIn: "filter" },
  { id: 4, value: "UOM", lable: "UOM - Edit", sendIn: "filter" },
  { id: 5, value: "ItemFactor", lable: "Item Factor - Edit", sendIn: "filter" },
  { id: 6, value: "MinQty", label: "Min Qty - Edit", sendIn: "filter" },
  { id: 7, value: "IntegralQty", label: "Integral Qty - Edit", sendIn: "filter" },
  { id: 8, value: "DiscountGroup", label: "Discount Group - Edit", sendIn: "filter" },
  { id: 9, value: "OrderType", label: "Order Type - Edit", sendIn: "filter" },
  { id: 10, value: "PORoundoff", label: "PO Round off - Edit", sendIn: "filter" },
  { id: 11, value: "PriceDefinedby", label: "Price Defined by - Edit", sendIn: "filter" },
  { id: 12, value: "MaxDiscount", label: "Max Discount - Edit", sendIn: "filter" },
  { id: 13, value: "CompanyDivision", label: "Division - Add/Remove", sendIn: "filter" },
  { id: 14, value: "PRODUCT_PRICE", label: "Product Price - Add/Edit", sendIn: "event_type" },
  { id: 15, value: "POExclusion", label: "PO Exclusion Edit", sendIn: "filter" },
  { id: 16, value: "PRODUCT_VISIBLITY_REGION", label: "Visiblity Geo - Add/Edit", sendIn: "event_type" },
  { id: 17, value: "PRODUCT_VISIBLITY_PSR", label: "Visiblity FF - Add/Edit", sendIn: "event_type" },
];

export const employeeKeysArray = [
  { id: 1, value: "EmployeeName", label: "Employee Name - Edit", sendIn: "filter" },
  { id: 2, value: "AlternateEmployeeCode", label: "Alternate Employee Code - Edit", sendIn: "filter" },
  { id: 3, value: "UserType", label: "User Type - Edit", sendIn: "filter" },
  { id: 4, value: "MobileNumber", label: "Mobile Number - Edit", sendIn: "filter" },
  { id: 5, value: "EmailAddress", label: "Email Address - Edit", sendIn: "filter" },
  { id: 6, value: "HQID", label: "HQID - Edit", sendIn: "filter" },
  { id: 7, value: "L1EC", label: "L1EC - Edit", sendIn: "filter" },
  {
    id: 8,
    value: "ActiveStatus",
    label: "Active Status - Edit",
    sendIn: "filter",
  },
];
// export const counterKeysArray = [
//   { id: 1, value: "COUNTER", label: "Counter - Add", sendIn: "event_type" },
//   { id: 2, value: "counter_name", label: "Counter Name - Edit", sendIn: "filter" },
//   { id: 3, value: "mci_register_number", label: "MCI Register Number - Edit", sendIn: "filter" },
//   { id: 4, value: "email", label: "Email - Edit", sendIn: "filter" },
//   { id: 5, value: "manager_name", label: "Manager Name - Edit", sendIn: "filter" },
//   { id: 6, value: "gst_number", label: "GST Number - Edit", sendIn: "filter" },
//   { id: 7, value: "drug_license_number", label: "Drug License Number - Edit", sendIn: "filter" },
//   { id: 8, value: "speciality_id", label: "Speciality ID - Edit", sendIn: "filter" },
//   { id: 9, value: "location_name", label: "Location Name - Edit", sendIn: "filter" },
//   { id: 10, value: "clinic_name", label: "Clinic Name - Edit", sendIn: "filter" },
//   { id: 11, value: "address", label: "Address - Edit", sendIn: "filter" },
//   { id: 12, value: "pincode", label: "Pincode - Edit", sendIn: "filter" },
//   { id: 13, value: "COUNTER_LOCATION", label: "Counter Location - Add/Edit/Delete", sendIn: "event_type" },
//   { id: 14, value: "COUNTER_COMPANY", label: "Counter Company - Add/Edit", sendIn: "event_type" },
//   { id: 15, value: "COUNTER_DIVISION", label: "Counter Division - Add/Remove", sendIn: "event_type" },
//   { id: 16, value: "COUNTER_ORDERCAP", label: "Counter Order Cap - Edit", sendIn: "event_type" },
//   { id: 17, value: "COUNTER_DIVISION_BLOCK", label: "Counter Division Block - Add", sendIn: "event_type" },
//   { id: 18, value: "COUNTER_COMPANY_BLOCK", label: "Counter Company Block - Add", sendIn: "event_type" },
//   { id: 19, value: "COUNTER_DIVISION_REVOKE", label: "Counter Division Revoke - Add", sendIn: "event_type" },
//   { id: 20, value: "COUNTER_COMPANY_REVOKE", label: "Counter Company Revoke - Add", sendIn: "event_type" },
//   { id: 21, value: "COUNTER_STOCKIST", label: "Counter Stockist - Add/Remove", sendIn: "event_type" },
//   { id: 22, value: "COUNTER_DELEGATE", label: "Counter Delegate - Add", sendIn: "event_type" },
//   { id: 23, value: "COUNTER_DELEGATE_COMPANY", label: "Counter Delegate Company - Add", sendIn: "event_type" },
//   { id: 24, value: "COUNTER_PRODUCT", label: "Counter Product - Add/Edit/Delete", sendIn: "event_type" },
// ];

export const counterKeysArray = [
  { id: 1, value: "COUNTER", label: "Counter - Add", sendIn: "event_type" },
  { id: 13, value: "COUNTER_LOCATION", label: "Counter Location - Add/Edit/Delete", sendIn: "event_type" },
  { id: 14, value: "COUNTER_COMPANY", label: "Counter Company - Add/Edit", sendIn: "event_type" },
  { id: 15, value: "COUNTER_DIVISION", label: "Counter Division - Add/Remove", sendIn: "event_type" },
  { id: 16, value: "COUNTER_ORDERCAP", label: "Counter Order Cap - Add/Edit", sendIn: "event_type" },
  { id: 17, value: "COUNTER_DIVISION_BLOCK", label: "Counter Division Block - Add", sendIn: "event_type" },
  { id: 18, value: "COUNTER_COMPANY_BLOCK", label: "Counter Company Block - Add", sendIn: "event_type" },
  { id: 19, value: "COUNTER_DIVISION_REVOKE", label: "Counter Division Revoke - Add", sendIn: "event_type" },
  { id: 20, value: "COUNTER_COMPANY_REVOKE", label: "Counter Company Revoke - Add", sendIn: "event_type" },
  { id: 21, value: "COUNTER_STOCKIST", label: "Counter Stockist - Add/Remove", sendIn: "event_type" },
  { id: 22, value: "COUNTER_DELEGATE", label: "Counter Delegate - Add", sendIn: "event_type" },
  { id: 23, value: "COUNTER_DELEGATE_COMPANY", label: "Counter Delegate Company - Add", sendIn: "event_type" },
  { id: 24, value: "COUNTER_PRODUCT", label: "Counter Product - Add/Edit/Delete", sendIn: "event_type" },
  { id: 25, value: "COUNTER_REPLACE", label: "Counter Replace", sendIn: "event_type" },
];
