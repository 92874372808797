import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Button, Modal, Table } from "src/components";
import Loader from "src/components/ui/loader.component";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { getBlockCounterList, revokeCounter } from "src/services/counter-api";
import { insertElementAtSpecificIndex } from "src/utils/utils";
import GenericForm from "../counter-master/form.component";

const columnHelper = createColumnHelper();

const BlockedCounter = ({ onChangeTab, selectedCompany }: { onChangeTab?: any; selectedCompany: any }) => {
  const [blockList, setBlockList] = useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const [showRevokeForm, setShowRevokeForm] = useState(false);
  const [currentRevokeData, setCurrentRevokeData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { selectedCounterData } = useSelector((state: RootState) => state.counter);

  const { toast } = useToast();

  useEffect(() => {
    async function fetchBlockCounter() {
      try {
        setIsLoading(true);

        const res = await getBlockCounterList(
          searchParams.get("id") ?? "",
          selectedCompany?.counter_company_location_id,
          selectedCompany?.counter_location?.counter_location_id
        );

        if (res?.status !== 200) throw new Error();

        const transformedData: any[] = [];
        for (let i = 0; i < res?.data?.length; i++) {
          const currentObj = res?.data[i];
          for (let j = 0; j < currentObj?.counter_order_block.length; j++) {
            transformedData.push({
              ...currentObj,
              counter_order_block: currentObj?.counter_order_block[j],
              // divisionData: currentObj?.division_data,
              // company_data: currentObj?.company_data,
              // counter_location: currentObj?.counter_location,
            });
          }
          // transformedData.push({
          //   ...currentObj,
          // divisionData: currentObj?.division_data,
          // company_data: currentObj?.company_data,
          // counter_location: currentObj?.counter_location,
          // counter_order_block: currentObj?.counter_order_block,

          // });
        }

        setBlockList(transformedData ?? []);
      } catch (error) {
        toast({
          description: "Not able to fetch block counter",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (searchParams.get("id") && selectedCompany && Object.keys(selectedCompany)?.length > 0) fetchBlockCounter();
  }, [selectedCompany]);

  // Metadata for the Blocked/Revoked table
  const blockedRevokedTableMD = [
    // Column for Block dtl id
    columnHelper.accessor("counter_order_block.order_block_dtl_id", {
      cell: (info) => info.getValue(),
      header: () => "Block Detail id",
    }),
    columnHelper.accessor("counterData.counter_code", {
      cell: (info) => info.getValue(),
      header: () => "Counter Code",
    }),

    // Column for Block Type
    columnHelper.accessor("counter_order_block.block_type", {
      cell: (info) => info.getValue(),
      header: () => "Block Type",
    }),

    // Column for Block Category
    columnHelper.accessor("counter_order_block.block_remark_id.blocked_remarks_desc", {
      cell: (info) => info.getValue(),
      header: () => "Block Category",
    }),

    // Column for Division Name
    columnHelper.accessor("divisionData.division_name", {
      cell: (info) => info.getValue(),
      header: () => "Division Name",
    }),

    // Column for Remarks
    columnHelper.accessor("counter_order_block.blocked_remarks_free_text", {
      cell: (info) => info.getValue(),
      header: () => "Remarks",
      enableSorting: false,
    }),
    // Column for Internal Remarks
    columnHelper.accessor("counter_order_block.internal_remarks", {
      cell: (info) => info.getValue(),
      header: () => "Internal Remarks",
      enableSorting: false,
    }),

    // Column for Date & Time
    columnHelper.accessor("counter_order_block.created_date", {
      cell: (info) => (info.getValue() ? <p>{moment(info.getValue()).format("DD/MM/YYYY | h:mm a")}</p> : "-"),

      header: () => "Date & Time",
    }),

    // Column for Revoked Block with a button
    columnHelper.accessor("revoked_block", {
      cell: (info) => (
        <Button
          onClick={() => {
            setCurrentRevokeData(info.row.original);
            setShowRevokeForm(true);
          }}
          className="border"
        >
          Revoke
        </Button>
      ),
      header: () => "Revoked Block",
    }),
  ];

  const revokeHandler = async (data: any) => {
    console.log("data", data);
    console.log("currentRevokeData", currentRevokeData);
    try {
      setIsSaving(true);
      const updatedFormData = structuredClone(data);
      const responseObj: any = {};
      responseObj.company_code = currentRevokeData?.company_code;
      responseObj.counter_code = selectedCounterData?.counter_code;
      responseObj.block_remark_id = currentRevokeData?.counter_order_block?.block_remark_id?.blocked_remarks_id;
      responseObj.order_block_dtl_id = currentRevokeData?.counter_order_block?.order_block_dtl_id ?? "";
      responseObj.counter_location_id = currentRevokeData?.counter_location?.counter_location_id;
      responseObj.location_code = currentRevokeData?.counter_location?.location_code;
      responseObj.revoked_remarks_free_text = updatedFormData?.remarks ?? "";
      responseObj.company_name = currentRevokeData?.company_data?.name;
      responseObj.location_name = currentRevokeData?.company_location?.location_name;
      responseObj.division_name = currentRevokeData?.divisionData?.division_name || undefined;
      responseObj.internal_remarks = currentRevokeData?.counter_order_block?.internal_remarks;
      responseObj.company_division_code = currentRevokeData?.divisionData?.company_divisioncode || undefined;
      responseObj.u_code = currentRevokeData?.counter_order_block?.u_code || undefined;
      responseObj.block_category = currentRevokeData?.counter_order_block?.block_remark_id?.blocked_remarks_desc;

      const res = await revokeCounter(responseObj, selectedCounterData?.counter_id, currentRevokeData?.divisionData?.division_name ? true : false);

      if (res?.status !== 200) {
        throw new Error();
      }

      toast({
        description: "Revoked successfully",
      });

      setShowRevokeForm(false);
      onChangeTab(1);
    } catch (error) {
      toast({
        description: "Error in revoking",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Table data={blockList} columns={blockedRevokedTableMD} isPaginating={false} headClass="bg-[#F8F8F8]" />
      {/* <ConfirmRevokeModel show={false} /> */}
      {console.log("currentRevokeData", currentRevokeData)}
      {console.log("currentRevokeData?.counter_order_block?.internal_remarks", currentRevokeData?.counter_order_block?.internal_remarks)}
      <AddToRevokedListModal
        onFormSubmit={revokeHandler}
        defaultValues={{
          doctorName: selectedCounterData?.counter_name,
          location: currentRevokeData?.counter_location?.location_name,
          company: currentRevokeData?.company_data?.name,
          division: currentRevokeData?.divisionData?.division_name,
          blockCategory: currentRevokeData?.counter_order_block?.block_remark_id?.blocked_remarks_desc,
          internal_remarks: currentRevokeData?.counter_order_block?.internal_remarks,
        }}
        isDivisionBlock={currentRevokeData?.divisionData?.division_name ? true : false}
        setShow={setShowRevokeForm}
        show={showRevokeForm}
        isLoading={isSaving}
      />
    </>
  );
};

const AddToRevokedListModal = ({
  show,
  setShow,
  defaultValues,
  onFormSubmit,
  isLoading,
  isDivisionBlock,
}: {
  show?: boolean;
  setShow: any;
  defaultValues: any;
  onFormSubmit: any;
  isLoading: boolean;
  isDivisionBlock: boolean;
}) => {
  let formFields = [
    {
      id: "doctorName",
      type: "text",
      label: "Doctor Name",
      placeholder: "Enter Doctor Name",
      keyofForm: "doctorName",
      disabled: true,
    },
    {
      id: "company",
      type: "text",
      label: "Company",
      placeholder: "Enter Company",
      keyofForm: "company",
      disabled: true,
    },
    {
      id: "location",
      type: "text",
      label: "Location",
      placeholder: "Enter Location",
      keyofForm: "location",
      disabled: true,
    },
    {
      id: "blockCategory",
      type: "text",
      label: "Block Category",
      placeholder: "Enter Block Category",
      keyofForm: "blockCategory",
      disabled: true,
    },
    {
      id: "remarks",
      type: "text",
      label: "Remarks*",
      placeholder: "Enter Remarks",
      validation: {
        required: "This field is required",
      },
      keyofForm: "remarks",
    },
    {
      id: "reference_number",
      type: "text",
      label: "Reference Number*",
      size: "full",
      placeholder: "Enter Reference Number",
      validation: {
        required: "This field is required",
      },
      keyofForm: "revoke_reference_number",
    },
    {
      id: "internalRemarks",
      type: "textarea",
      label: "Internal Remarks*",
      placeholder: "Enter Internal Remarks",
      size: "full",
      // validation: {
      //   required: "This field is required",
      // },
      keyofForm: "internal_remarks",
      disabled: true,
    },
  ];

  if (isDivisionBlock) {
    formFields = insertElementAtSpecificIndex(
      formFields,
      { id: "internalRemarks", type: "input", label: "Division", keyofForm: "division", disabled: true },
      2
    );
  }

  return (
    <div className="w-9/12 sm:w-6/12">
      <Modal
        title="Add to Revoke List"
        onCloseModal={() => {
          setShow(false);
        }}
        isHeader={true}
        isOpen={show ? true : false}
      >
        <GenericForm defaultValues={defaultValues} onFormSubmit={onFormSubmit} formFields={formFields}>
          <p className="p-0 px-5 m-0 text-sm font-normal text-primary">*These remarks are private and are only viewable to Medvol staff</p>
          <footer className="flex items-center justify-end gap-3 p-5 ">
            <Button
              onClick={() => {
                setShow(false);
              }}
              className="border"
            >
              Cancel
            </Button>
            <Button isLoading={isLoading} loaderSize="small" type="submit" className="text-white bg-primary">
              Revoke
            </Button>
          </footer>
        </GenericForm>
      </Modal>
    </div>
  );
};

export default BlockedCounter;
