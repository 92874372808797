import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { api, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

const eventInstance = axios.create({
  baseURL: api + "/event/logs",
});

// Add the interceptor to the instance
eventInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403) {
    // logout
    logout();
  }

  eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
});

export const getProductVersioning = async (code: any, typeValue: any, paramType: any, page?: number | string): Promise<AxiosResponse> => {
  if (page) {
    return eventInstance.get(
      `?domain=product&domain_primary_key=${code}` + (paramType && typeValue ? `&${paramType}=${typeValue}` : ``) + `&page=${page}`
    );
  } else return eventInstance.get(`?domain=product&domain_primary_key=${code}` + (paramType && typeValue ? `&${paramType}=${typeValue}` : ``));
};

export const getEmployeeVersioning = async (code: any, typeValue: any, paramType: any, page?: number | string): Promise<AxiosResponse> => {
  if (page) {
    return eventInstance.get(
      `?domain=employee&domain_primary_key=${code}` + (paramType && typeValue ? `&${paramType}=${typeValue}` : ``) + `&page=${page}`
    );
  } else return eventInstance.get(`?domain=employee&domain_primary_key=${code}` + (paramType && typeValue ? `&${paramType}=${typeValue}` : ``));
};

export const getCounterVersioning = async (code: any, typeValue: any, paramType: any, page?: number | string): Promise<AxiosResponse> => {
  if (page) {
    return eventInstance.get(
      `?domain=counter&domain_primary_key=${code}` + (paramType && typeValue ? `&${paramType}=${typeValue}` : ``) + `&page=${page}`
    );
  } else return eventInstance.get(`?domain=counter&domain_primary_key=${code}` + (paramType && typeValue ? `&${paramType}=${typeValue}` : ``));
};

export const getGeographyVersioning = async (code: any, page?: number | string): Promise<AxiosResponse> => {
  if (page) {
    return eventInstance.get(`?domain=state&domain_primary_key=${code}&page=${page}`);
  } else return eventInstance.get(`?domain=state&domain_primary_key=${code}`);
};

export const getStockistVersioning = async (code: any, page?: number | string): Promise<AxiosResponse> => {
  if (page) {
    return eventInstance.get(`?domain=stockist&domain_primary_key=${code}&page=${page}`);
  } else return eventInstance.get(`?domain=stockist&domain_primary_key=${code}`);
};
