import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { api } from "../constants/global-const";
import { addAuthHeader } from "src/utils/auth";

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/counter",
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const getAllCounterDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const getAllLinkTypesDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/linktype");
};

export const queryCounterDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get("/search" + query);
};
export const queryCounterDetailsById = async (query: string): Promise<AxiosResponse> => {
  return instance.get("/details" + query);
};

export const getCounterType = async (): Promise<AxiosResponse> => {
  return instance.get("/type");
};

export const addCounter = async (data: any): Promise<AxiosResponse> => {
  return instance.post("/add", data);
};

export const getCounterLinkType = async (query?: string): Promise<AxiosResponse> => {
  return instance.get(`/linktype${query ? query : ""}`);
};

export const getCounterDetailsById = async (id: string | number | null | undefined): Promise<AxiosResponse> => {
  return instance.get("/search?id=" + id);
};

export const getCounterSpeciality = async (): Promise<AxiosResponse> => {
  return instance.get("/speciality");
};

export const AddUpdateLocation = async (data: any): Promise<AxiosResponse> => {
  return instance.post("/update/location", data);
};
export const getCounterCompanyLinkData = async (data: any): Promise<AxiosResponse> => instance.get(`/companylink?counter_id=${data}`);

export const addCounterDivision = async (data?: any): Promise<AxiosResponse> => instance.post("/division/add", data);

export const DeactivateCounterDivision = async (data?: string | number): Promise<AxiosResponse> => instance.post(`deactivate/division?${data}`);

export const getCounterValueCap = async (data?: string | number): Promise<AxiosResponse> => instance.get(`value-cap?counter_ff_id=${data}`);

export const getDelegates = async (data: string | number): Promise<AxiosResponse> => instance.get(`/delegate?counter_id=${data}`);

export const setValueCap = async (data?: string | number | object): Promise<AxiosResponse> => instance.post(`/set/ordercap`, data);

export const addDelegate = async (data?: any): Promise<AxiosResponse> => instance.post(`/delegate/add`, data);

export const getHospitalName = async () => instance.get("/search?usertype=2");

export const blockCounterDivision = async (data?: any): Promise<AxiosResponse> => instance.post(`/block`, data);

export const getBlockCounterList = async (counter_id: number | string) => instance.get(`/blocked-revoked/list?type=B&counter_id=${counter_id}`);

export const getRevokeCounterList = async (counter_id: number | string) => instance.get(`/blocked-revoked/list?type=R&counter_id=${counter_id}`);

export const getAllBlockCategory = async () => instance.get("/block-category");

export const revokeCounter = async (data: any) => instance.post(`/revoke`, data);

export const getStockistData = async (data: any) => instance.get(`/stockist-link/?counter_id=${data}`);

export const getStockistList = async (data: any) => instance.get(`/stockist-link/?counter_id=${data}`);

export const blockCounterCompany = async (data: any) => instance.post("/block", data);

export const checkUinRequired = async (data: any) => axios.post("https://testappsapigw.medvol.in/api/CompanyUINValidation_Required", data);

export const validateUin = async (data: any) => axios.post("https://testappsapigw.medvol.in/api/CompanyUINValidation", data);

export const getCounterByEmployee = async (query: any) => {
  return instance.get(`/details/employee${query}`);
};

export const getPDADetailsByLocationID = async (id: any) => {
  return instance.get(`/details/employee?counter_company_location_id=${id}`);
};
