import { useState } from "react";
import adoptCounterIcon from "../assets/adoptCounter.svg";
import createCounterIcon from "../assets/createCounter.svg";
import Button from "../components/Button";
import { cn } from "../utils/utils";

const CreateCounter = ({ onSubmit, onCancel }: { onSubmit: any; onCancel: any }) => {
  const [selectedState, setSelectedState] = useState(0);

  function createHandler() {
    if (selectedState === 0) {
      // adopt a counter form
      onSubmit("adoptForm");
    } else if (selectedState === 2) {
      onSubmit("CreqForm");
    } else {
      // create a new counter form
      onSubmit("createCounter");
    }
  }

  return (
    <div className="border rounded-lg w-96">
      <main className="flex items-center gap-3 px-2 py-4">
        <button
          onClick={() => setSelectedState(0)}
          className={cn(
            `flex flex-col items-center justify-center flex-1 gap-2 py-5 text-sm  font-medium border rounded-lg ${
              selectedState === 0 ? "bg-[#DEE2FF]" : ""
            }`
          )}
        >
          <div className="p-2 rounded-md bg-[#586AF5]">
            <img src={adoptCounterIcon} height={22} width={22} />
          </div>
          Adopt a Counter
        </button>

        <button
          onClick={() => setSelectedState(1)}
          className={cn(
            `flex flex-col items-center justify-center flex-1 gap-2 py-5 text-sm font-medium border rounded-lg ${
              selectedState === 1 ? "bg-[#DEE2FF]" : ""
            }`
          )}
        >
          <div className="p-2 rounded-md bg-[#586AF5]">
            <img src={createCounterIcon} height={22} width={22} />
          </div>
          Create a new Counter
        </button>
      </main>

      <footer className="flex items-center justify-end gap-3 py-2 border-t-[1px] px-2">
        <Button onClick={onCancel} className="border">
          Cancel
        </Button>
        <Button onClick={createHandler} className="bg-[#586AF5] text-white">
          Create
        </Button>
      </footer>
    </div>
  );
};

export default CreateCounter;
