/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductState {
  productDetails: any[] | null | undefined;
  addEditFormData: any;
  primary_key: string | number | null | undefined;
  showAddEditForm: boolean | null | undefined;
  isEdit: boolean | null | undefined;
  isReplacedProduct: boolean | null | undefined;
  initialFormData: any | null | undefined | any[];
}

interface PrefilledFormData {
  [key: string]: any;
}

const initialProductSlice = {
  productDetails: null,
  primary_key: "",
  showAddEditForm: false,
  isEdit: false,
  isReplacedProduct: false,
  initialFormData: {},
  addEditFormData: {},
} as ProductState;

const productSlice = createSlice({
  name: "product",
  initialState: initialProductSlice,
  reducers: {
    setProduct(state, action: PayloadAction<any[] | null | undefined>) {
      state.productDetails = action.payload;
    },

    setFullFormObject(state, action: PayloadAction<Partial<PrefilledFormData>>) {
      state.addEditFormData = action.payload;
      // const parentProductFormKeys = parentProductForm.map((item) => item.keyofForm);
      // const payloadKeys = Object.keys(action.payload);
      // payloadKeys.forEach((key) => {
      //   if (parentProductFormKeys.includes(key)) {
      //     state.addEditFormData[key] = action.payload[key];
      //   } else if (
      //     key.startsWith("divisionAutoComplete__") ||
      //     key.startsWith("po_roundoff_typecode__") ||
      //     key.startsWith("ordergroup_id__") ||
      //     key.startsWith("price_identifier_cp__") ||
      //     key.startsWith("discount_group_id__") ||
      //     key.startsWith("discountonptr_cp__")
      //   ) {
      //     // Handle dynamic fields
      //     state.addEditFormData[key] = action.payload[key];
      //   } else if (key === "company_divisions") {
      //     state.addEditFormData[key] = action.payload[key];
      //   }
      // });
    },

    setFormData(state, action: PayloadAction<any[] | any | null | undefined>) {
      const { keyofForm, value } = action.payload;
      state.addEditFormData[keyofForm] = value;
    },

    deleteFormKey(state, action: PayloadAction<string | null | undefined>) {
      const keyofForm = action.payload;

      if (state.addEditFormData.hasOwnProperty(keyofForm)) {
        delete state.addEditFormData?.[keyofForm!];
      }
    },

    setFormCompany_divisions(state, action: PayloadAction<any[] | any | null | undefined>) {
      state.addEditFormData.company_divisions = action.payload;
    },

    resetForm(state) {
      state.addEditFormData = initialProductSlice.addEditFormData;
    },

    setPrimaryKey(state, action: PayloadAction<string | number | null | undefined>) {
      state.primary_key = action.payload;
    },

    setShowAddEditForm(state, action: PayloadAction<boolean | null | undefined>) {
      state.showAddEditForm = action.payload;
    },

    setIsEdit(state, action: PayloadAction<boolean | null | undefined>) {
      state.isEdit = action.payload;
    },

    setIsReplacedProduct(state, action: PayloadAction<boolean | null | undefined>) {
      state.isReplacedProduct = action.payload;
    },

    setDivisionRemoveIndex(state, action: PayloadAction<any>) {
      Object.keys(state.addEditFormData).forEach((key) => {
        if (key?.includes("__")) {
          if (key.endsWith(action.payload)) {
            // remove that key from the add edit form data
            delete state.addEditFormData[key];
          }
        }
      });
    },
    setInitialFormData(state, action: PayloadAction<any[] | null | undefined>) {
      state.initialFormData = action.payload;
    },

    removeInitialFormData(state) {
      state.initialFormData = {};
    },
  },
});

export const productActions = productSlice.actions;
export { productSlice };
