import { ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button } from "src/components";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "src/components/ui/command.components";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover.components";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/components/ui/tooltip.component";
import { RootState } from "src/context/store";
import { cn } from "src/utils/utils";

interface ICombobox {
  options: any[];
  placeholder: string;
  displayKey: string;
  uniqueKey?: string | number;
  disabled?: boolean;
  defaultValue?: any;
  currentVal: (item: any) => void;
  additionalfield?: string;
  isControlled?: boolean;
}

export function Combobox({
  options,
  placeholder,
  uniqueKey,
  defaultValue,
  displayKey,
  currentVal,
  additionalfield,
  isControlled = false,
}: ICombobox) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(typeof defaultValue === "object" ? defaultValue : defaultValue || "");

  const { valueCapCurrDivision } = useSelector((state: RootState) => state.counter);

  React.useEffect(() => {
    if (isControlled) {
      setValue(defaultValue);
    } else if ((typeof value === "object" && Object.keys(value).length === 0) || value?.length === 0 || !value) {
      setValue(defaultValue);
    }
  }, [defaultValue?.[displayKey], defaultValue, valueCapCurrDivision?.company]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between line-clamp-1 overflow-hidden text-ellipsis  px-4 border-2 border-purple-600 rounded-full bg-purple-100 text-[#4B0082] capitalize py-1"
        >
          {/* {value ? value?.[displayKey] : placeholder} */}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <p className="text-xs font-bold line-clamp-1">
                  {value && value[displayKey]
                    ? `${value[displayKey]}${additionalfield ? ` - ${additionalfield.split(".").reduce((o, k) => (o || {})[k], value)}` : ""}`
                    : placeholder}
                </p>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-xs font-bold">
                  {value && value[displayKey]
                    ? `${value[displayKey]}${additionalfield ? ` - ${additionalfield.split(".").reduce((o, k) => (o || {})[k], value)}` : ""}`
                    : placeholder}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 bg-white">
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandEmpty>No data found.</CommandEmpty>
          <CommandGroup>
            {options.map((framework) => {
              const isActive: boolean =
                `${framework?.[displayKey]} ${
                  additionalfield ? ` - ${additionalfield.split(".").reduce((o: any, k: any) => (o || {})[k], framework)}` : ""
                }` ===
                `${value?.[displayKey]} ${additionalfield ? ` - ${additionalfield.split(".").reduce((o: any, k: any) => (o || {})[k], value)}` : ""}`;

              return (
                <CommandItem
                  className={cn(
                    `hover:bg-gray-100 cursor-pointer ${
                      isActive ? "bg-purple-50 text-purple-700" : ""
                    } px-2 py-3 flex items-center gap-3 font-semibold`
                  )}
                  key={framework?.[uniqueKey as string]}
                  value={framework}
                  onSelect={() => {
                    currentVal(framework);
                    setValue(framework);
                    setOpen(false);
                  }}
                >
                  {framework[displayKey]} {additionalfield ? ` - ${additionalfield.split(".").reduce((o, k) => (o || {})[k], framework)}` : ""}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
