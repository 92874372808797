import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Layout, Navbar, SubHeader } from "src/components";
import CraHomeScreen from "src/features/fields/pages/Homescreen";
import Loader from "./components/ui/loader.component";
import { isProd, logout } from "./constants/global-const";
import { accessControlActions } from "./context/access-control-slice";
import { authActions } from "./context/auth-slice";
import { RootState } from "./context/store";
import Login from "./pages/auth/login.component";
import BlockedRevoked from "./pages/blocked-counter";
import NotFound from "./pages/not-found/not-found.component";
import StockistDetails from "./pages/stockist-details";
import StockistMaster from "./pages/stockist-master/stockist-master.component";
import { fetchMenuAccessRoles } from "./services/access-control-api";
import { queryEmployeeDetails } from "./services/employee-api";
import CompanyMasterComponent from "./pages/company-master/company-master.component";
import RestrictedPage from "./container/restricted.component";

const CounterDetails = lazy(() => import("./pages/counter-details/counter-details.component"));
const DivisionDetails = lazy(() => import("./pages/division-details/division-details.component"));
const DivisionMaster = lazy(() => import("./pages/division-master/division-master.component"));
const ValueCap = lazy(() => import("./pages/value-cap"));

const EmployeeMaster = lazy(() => import("src/pages/employee-master/employee-master.component"));
const EmployeeDetails = lazy(() => import("src/pages/employees-details"));
const ProductDetails = lazy(() => import("src/pages/product-details"));
const ProductMaster = lazy(() => import("src/pages/product-master/product-master.component"));
const CounterMaster = lazy(() => import("src/pages/counter-master/counter-master.component"));
// const CraHomescreen = lazy(() => import("src/features/fields/pages/Homescreen"));
const PDAHomescreen = lazy(() => import("src/features/fields/pages/PDAHomescreen"));
const CraDetailScreen = lazy(() => import("src/features/fields/pages/DetailScreen"));
const PdaDetailScreen = lazy(() => import("src/features/fields/pages/productDetailsScreen"));
const GeographicMaster = lazy(() => import("src/pages/geography-master/geographic-master.component"));

export default function App(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const token: any = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    getMenuAccessRoles();
  }, []);

  const getMenuAccessRoles = async () => {
    try {
      const menuAccessRoles = await fetchMenuAccessRoles();
      dispatch(accessControlActions.fetchAccessControl(menuAccessRoles.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Unable to fetch Menu Access Control Roles:", error);
    }
  };

  useEffect(() => {
    // const token: any = {
    //   token: {
    //     // sub: "55fc3d2f-f6c1-49f3-8b91-1ee91d60a971",
    //     sub: "729c1fcd-7285-48f8-91c8-1352fdad555f",
    //     "custom:role": "fieldforce",
    //   },
    // };

    if (token?.token && token?.token?.sub) {
      // const isAdmin = token?.token?.["custom:role"] === "admin" || token?.token?.["custom:role"] === "Medvol Field";
      const isAdmin = true;
      const query = isAdmin ? `/details?cognito_id=${token?.token?.sub}&cdh=true` : `/details?cognito_id=${token?.token?.sub}`;
      queryEmployeeDetails(query)
        .then((res) => {
          if (isAdmin) {
            dispatch(authActions.setCurrentPsr(res?.data?.[0] || []));
          } else {
            dispatch(authActions.setCurrentPsr(res?.data?.data?.[0] || []));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [token?.token]);

  // useEffect(() => {
  //   const idTokenDetails = {
  //     "custom:role": "admin",
  //   };
  //   dispatch(authActions.setIdTokenDetails(idTokenDetails));
  // }, []);
  // logout after
  useEffect(() => {
    const currentTime = 30 * 60 * 1000; // 30 minutes
    // const currentTime = 1 * 60 * 1000; // 1 minutes

    const events = ["click", "load", "keypress"];
    let timer = setTimeout(() => logout(false), currentTime);

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => logout(false), currentTime);
    };

    events.forEach((event) => window.addEventListener(event, resetTimer));

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(timer);
    };
  }, []);

  const userRole: any = useSelector((state: RootState) => state.auth);
  const accessControl = useSelector((state: RootState) => state.accessControl);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  // useEffect(() => {
  //   // const getCookie = await getCookieValue("token");
  //   const setDataInRedux = async () => {
  //     const getIdToken = await getCookieValue("idToken");

  //     console.log("getCookie", getIdToken);

  //     if (getIdToken) {
  //       const decodedToken: string | null | undefined = await jwtDecode(getIdToken as string);
  //       let decodedName: any = null;
  //       if (getIdToken) {
  //         decodedName = await jwtDecode(getIdToken as string);
  //       }
  //       dispatch(authActions.login(decodedToken));
  //       dispatch(authActions.setLoggedInUser(decodedName?.name || ""));
  //       console.log("it is logged in");
  //     }
  //   };

  //   setDataInRedux();
  // }, [getCookieValue("idToken")]);

  return (
    <>
      {token.restricted && <RestrictedPage />}
      {!token.token && (
        // window.location.hostname != "localhost" &&
        <Login />
      )}
      {token.token && (
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="masters" />} />
              <Route
                path="/masters"
                element={
                  <>
                    <Navbar />
                    <SubHeader />
                  </>
                }
              />

              {/* masters route starts here */}
              {accessControl.menus?.MASTERS?.ALLOWED_ROLES.includes(userRole.token["custom:mv_role"]) && (
                <>
                  <Route path="/Masters" element={<Layout />}>
                    {/* {products routes} */}
                    {accessControl.menus?.MASTERS?.SECONDARY_MENUS.PRODUCTS?.ALLOWED_ROLES.includes(userRole.token["custom:mv_role"]) && (
                      <>
                        <Route path="products" element={<ProductMaster />} />
                        <Route path="products/details" element={<ProductDetails />} />
                      </>
                    )}
                    {/* employees routes  */}
                    {accessControl.menus?.MASTERS?.SECONDARY_MENUS.EMPLOYEES?.ALLOWED_ROLES.includes(userRole.token["custom:mv_role"]) && (
                      <>
                        <Route path="employees" element={<EmployeeMaster />} />
                        <Route path="employees/details" element={<EmployeeDetails />} />
                      </>
                    )}
                    {/*counters routes*/}
                    {accessControl.menus?.MASTERS?.SECONDARY_MENUS.COUNTERS?.ALLOWED_ROLES.includes(userRole.token["custom:mv_role"]) && (
                      <>
                        <Route path="counters" element={<CounterMaster />} />
                        <Route path="counters/details" element={<CounterDetails />} />
                        <Route path="counters/details/value-cap" element={<ValueCap />} />
                        <Route path="counters/details/block-revoke" element={<BlockedRevoked />} />
                        <Route path="divisions" element={<DivisionMaster />} />
                        <Route path="divisions/details" element={<DivisionDetails />} />
                      </>
                    )}
                    {/* stockists route */}
                    {!isProd && (
                      <>
                        <Route path="stockists" element={<StockistMaster />} />
                        <Route path="stockists/details" element={<StockistDetails />} />
                      </>
                    )}
                    <Route path="geographic" element={<GeographicMaster />} />
                    {!isProd && <Route path="company" element={<CompanyMasterComponent />} />}
                  </Route>
                  {/* master route ends here */}
                </>
              )}
              {/* request approval routes starts here  */}

              <Route path="/request-approval" element={<Navigate to="/request-approval/cra" />} />
              <Route path="/request-approval" element={<Layout />}>
                <Route
                  path="cra"
                  element={
                    <>
                      <SubHeader />
                      <CraHomeScreen />
                    </>
                  }
                />
                <Route
                  path="cra/details"
                  element={
                    <>
                      <SubHeader />
                      <CraDetailScreen />
                    </>
                  }
                />

                <Route
                  path="pra/details"
                  element={
                    <>
                      <SubHeader />
                      <PdaDetailScreen />
                    </>
                  }
                />
                <Route
                  path="Pra"
                  element={
                    <>
                      <SubHeader />
                      <PDAHomescreen />
                    </>
                  }
                />
              </Route>
              {/* request approval routes ends here  */}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      )}
    </>
  );
}
