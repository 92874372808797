/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { queryDivisionDetails } from "src/services/division-api";
import Autocomplete from "../components/Autocomplete";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import Input from "../components/Input";
import Modal from "../components/Modal";
import { ScrollArea } from "../components/ui/ScrollArea";
import { craActions } from "../context/cra-slice";
import { RootState } from "../context/store";
import { useToast } from "../hooks/useToast";
import { getPostPersons } from "../services/cda-api";
import { getLowestPSRDetails } from "../services/employee-api";
import { getConvertedCraFormField } from "../utils/convertCraFormFields";
import { cn, flattenObject } from "../utils/utils";

interface ReplaceProductProps {
  onCloseModal: () => void;
  onConfirm: (data: any) => void;
  selectedCompany?: any;
  isAdoptForm?: boolean;
}

const DivisionSelect: React.FC<ReplaceProductProps> = ({ onCloseModal, onConfirm, selectedCompany, isAdoptForm }) => {
  const dispatch = useDispatch();
  const { deactivateDivisionModalState, location_code, formDefaultValues, adoptDrType } = useSelector((state: RootState) => state.cra);
  const { currentPsr, idTokenDetails, isAdmin } = useSelector((state: RootState) => state.auth);
  const { toast } = useToast();

  const [lowestPsrList, setLowestPsrList] = useState([]);
  const [currentLowestPSR, setCurrentLowestPSR] = useState({});
  const [postPerson, setPostPerson] = useState({});
  const [selectedDivisions, setSelectedDivisions] = useState<any>({});

  const [searchQuery, setSearchQuery] = useState("");

  const [divisionList, setDivisionList] = useState([]);

  const selectHandler = (value: any) => {
    setSelectedDivisions(value);
  };

  const deselectHandler = () => {
    setSelectedDivisions({});
  };

  console.log(isAdoptForm);

  const handleConfirm = async () => {
    if (!selectedDivisions || Object.keys(selectedDivisions).length === 0) {
      toast({
        description: "Please select a division first!",
      });
      return;
    }
    if (!currentLowestPSR || Object.keys(currentLowestPSR).length === 0) {
      toast({
        description: "Please select employee first",
      });
      return;
    }
    // debugger;
    dispatch(craActions.setDeactivateDivisionModalState({ type: false }));
    const companyCode = selectedDivisions?.company_code;
    const foundLocation = formDefaultValues?.counter_location?.find((location: any) => location?.location_code == location_code);
    const foundCompany = foundLocation?.counter_cmpny_location_id?.find((company: any) => company?.company_code == companyCode);
    dispatch(craActions.setCounterCompanyLocationId(foundCompany?.counter_company_location_id || null));
    const convertedData = getConvertedCraFormField(formDefaultValues, adoptDrType as string, companyCode);
    dispatch(craActions.setFormDefaultValues(convertedData));
    onConfirm({ selectedDivisions, currentLowestPSR, postPerson });
  };

  const fetchPostPersons = async (_: string, currentPage: number = 1) => {
    if (!selectedDivisions?.company_divisioncode) {
      return [];
    }
    // need to add counter_linktype, company_code , division_code

    const company_code = selectedCompany?.code || selectedDivisions?.code;

    const queryString = `company_code=${company_code}&page=${currentPage}&perPage=15&counter_linktype=${adoptDrType}&division_code=${selectedDivisions?.company_divisioncode}`;
    const res = await getPostPersons(queryString);
    return res;
  };

  const fetchLowestPSRDetails = async (position_code: string, usertype: string) => {
    getLowestPSRDetails(position_code, selectedDivisions?.company_divisioncode, usertype)
      .then((res) => setLowestPsrList(res?.data?.data || []))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const companyCode = selectedDivisions?.company_code;

    const foundLocation = formDefaultValues?.counter_location?.find((location: any) => location?.location_code === location_code);
    const companyCode2 = foundLocation?.counter_cmpny_location_id?.find((company: any) => company?.company_code.code == companyCode);
    const foundCompany = foundLocation?.counter_cmpny_location_id?.flatMap((company: any) => company?.counter_field_force_id || []);
    const foundDivision = foundCompany?.filter((counter: any) => counter?.company_division_code);

    if (isAdmin && selectedCompany?.code) {
      // fetch division
      queryDivisionDetails(`details?company_code=${selectedCompany?.code}`)
        .then((res) => {
          setDivisionList(res?.data);
        })
        .catch((err) => console.log(err));
    } else if (companyCode2?.code) {
      queryDivisionDetails(`details?company_code=${companyCode2?.code}`)
        .then((res) => {
          //Subtract foundDivision data from the API response
          const filteredData = res?.data?.filter(
            (item: any) =>
              !foundDivision.some((division: any) => division?.company_division_code?.company_divisioncode === item?.company_divisioncode)
          );
          setDivisionList(filteredData || []);
        })
        .catch((err) => console.log(err));
    } else {
      setDivisionList(currentPsr?.emp_position_hdr?.emp_position_devrel || []);
    }
  }, []);

  useEffect(() => {
    if (selectedDivisions && Object.keys(selectedDivisions)?.length && !isAdmin)
      getLowestPSRDetails(
        currentPsr.emp_position_hdr.position_code,
        selectedDivisions?.company_divisioncode,
        // currentPsr?.emp_position_hdr?.emp_role_hdr?.usertype
        idTokenDetails?.["custom:mv_role"]
      )
        .then((res) => setLowestPsrList(res?.data?.data || []))
        .catch((err) => console.log(err));
  }, [selectedDivisions, isAdmin]);

  const filteredDivisionList = !searchQuery
    ? structuredClone(divisionList)
    : divisionList.filter((division: any) => flattenObject(division)?.division_name?.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Modal
      isHeader={true}
      isOpen={deactivateDivisionModalState?.type ? true : false}
      onCloseModal={() => {
        onCloseModal();
        dispatch(craActions.setDeactivateDivisionModalState({ type: false }));
      }}
      title="Select Counter Division"
    >
      <div className="p-6 w-[26rem]">
        <header className="flex flex-col mb-8">
          <h1 className="text-[1.525rem] not-italic font-semibold leading-7 ">Select Division</h1>
          <h3 className="text-sm font-normal text-[#667085] mt-2">Pick a division</h3>
        </header>

        <div className="mb-3">
          <Input
            onChange={(e: any) => setSearchQuery(e.target.value)}
            placeholder="Search"
            type="search"
            label="Search Division"
            className="w-full py-2"
          />
        </div>
        <div className="flex flex-col gap-3 mb-6">
          <ScrollArea className="overflow-scroll max-h-96 ">
            <div className="flex flex-col max-h-full gap-3 rounded-md">
              {filteredDivisionList?.map((division: any) => {
                const flattendedDIvision = flattenObject(division);
                return (
                  <div
                    key={flattendedDIvision.company_divisioncode}
                    className={cn(
                      `${
                        selectedDivisions &&
                        selectedDivisions.company_divisioncode &&
                        selectedDivisions.company_divisioncode?.toString().includes(flattendedDIvision?.company_divisioncode)
                          ? "bg-blue-50 text-blue-700"
                          : ""
                      } px-2 py-3 flex justify-between items-center gap-3 font-semibold border rounded-md`
                    )}
                  >
                    {flattendedDIvision.division_name}
                    <Checkbox
                      checked={selectedDivisions?.company_divisioncode?.toString()?.includes(flattendedDIvision?.company_divisioncode) ? true : false}
                      onChange={() => {
                        if (Object.keys(selectedDivisions).length) {
                          selectedDivisions?.company_divisioncode?.toString()?.includes(flattendedDIvision?.company_divisioncode)
                            ? deselectHandler()
                            : selectHandler(flattendedDIvision);
                        } else selectHandler(flattendedDIvision);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollArea>

          {isAdmin && (
            <div>
              <Autocomplete
                placeholder="Select employee who has post-rights"
                suggestionList={lowestPsrList}
                displayKey="emp_name"
                uniqueKey="empmaster_id"
                additionalDisplayKey="emp_shortcode"
                asyncListFunction={(query: string, currentPage: number) => {
                  return fetchPostPersons(query, currentPage);
                }}
                isAsyncQuery={false}
                onSelectionChange={(data: any) => {
                  setPostPerson(data);
                  fetchLowestPSRDetails(data?.emp_position_hdr?.position_code, data?.emp_position_hdr?.emp_role_hdr?.usertype);
                }}
              />
            </div>
          )}

          <Autocomplete
            placeholder="Select L0 employee"
            suggestionList={lowestPsrList}
            onSelectionChange={(data: any) => {
              setCurrentLowestPSR(data);
            }}
            displayKey="emp_name"
            uniqueKey="empmaster_id"
            additionalDisplayKey="emp_shortcode"
          />
        </div>

        <footer className="flex items-center justify-between gap-3">
          <Button
            onClick={() => {
              onCloseModal();
              dispatch(craActions.setDeactivateDivisionModalState({ type: false }));
            }}
            className="flex-1 border"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirm} className="border flex-1 bg-[#586AF5] text-white py-[0.62rem]">
            Confirm
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

export default DivisionSelect;
