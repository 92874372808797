import * as yup from "yup";

import { createColumnHelper } from "@tanstack/table-core";

export const getAllProductsApi = "/product/details";

export const title = "Product Master";

export const subtitle = "Fetch product master details";

export const bodyTitle = "Browse Products";

export const bodySubtitle = "Review and Manage all products";

const columnHelper = createColumnHelper();

export const parentProductForm = [
  { label: "Item Name", type: "input", id: 1, keyofForm: "name" },
  {
    label: "Company Name",
    type: "autocomplete",
    id: 2,
    keyofForm: "company_code",
  },
  { label: "Item Code", type: "input", id: 3, keyofForm: "item_code" },
  { label: "Item Code PO", type: "input", id: 4, keyofForm: "po_itemcode" },
  { label: "PTS", type: "number", id: 5, keyofForm: "pts" },
  { label: "PTR", type: "number", id: 6, keyofForm: "ptr" },
  { label: "MRP", type: "number", id: 7, keyofForm: "mrp" },
  { label: "GST", type: "number", id: 8, keyofForm: "gst_pc" },
  {
    label: "Item Unit of Measurement(UOM)",
    type: "input",
    id: 9,
    keyofForm: "item_uom",
  },
  { label: "Item Factor", type: "number", id: 10, keyofForm: "item_factor" },
  { label: "Minimum Quantity", type: "number", id: 11, keyofForm: "min_qty" },
  {
    label: "Integral Quantity",
    type: "number",
    id: 12,
    keyofForm: "integral_quantity",
  },
  {
    label: "Brand",
    type: "input",
    id: 13,
    keyofForm: "brand",
  },
  {
    label: "Company Item Code",
    type: "input",
    id: 13,
    keyofForm: "company_item_code",
  },
  {
    label: "Reference Number",
    type: "input",
    id: 156,
    keyofForm: "reference_number",
    disabled: false,
  },
];

export const AddPriceSchema = yup.object().shape({
  batch_code: yup.string().notRequired(),
  pts: yup
    .number()
    .required()
    .test("is-length-valid", "PTS should not exceed 18 digits", (value) => (value + "").length <= 18)

    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (!value) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{0,2})?$/));
    })
    .test("is-less", "PTS should be less than PTR and MRP", function (value: any) {
      const { ptr, mrp } = this.parent;

      if (!mrp && !ptr && mrp !== 0 && ptr !== 0) return true;
      if (!mrp && mrp !== 0) return value < ptr;
      if (!ptr && ptr !== 0) return value < mrp;

      return value < ptr && value < mrp;
    }),
  ptr: yup
    .number()
    .required()
    .test("is-length-valid", "PTR should not exceed 18 digits", (value) => (value + "").length <= 18)

    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (!value) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{0,2})?$/));
    })
    .test("is-greater", "PTR should be greater than PTS", function (value: any) {
      const { pts } = this.parent;
      if (!pts && pts !== 0) return true;
      return value > pts;
    })
    .test("is-less", "PTR should be less than  MRP", function (value: any) {
      const { mrp } = this.parent;
      if (!mrp && mrp !== 0) return true;
      return value < mrp;
    }),
  mrp: yup
    .number()
    .required()
    .test("is-length-valid", "MRP should not exceed 18 digits", (value) => (value + "").length <= 18)

    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (!value) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{0,2})?$/));
    })
    .test("is-greater", "MRP should be greater than PTS and PTR", function (value: any) {
      const { pts, ptr } = this.parent;
      if (!pts && !ptr && pts !== 0 && ptr !== 0) return true;
      if (!pts && pts !== 0) return value > ptr;
      if (!ptr && ptr !== 0) return value > pts;

      return value > pts && value > ptr;
    }),
  remarks: yup.string().notRequired(),
});

export const schema = yup.object().shape({
  name: yup.string().required("Item Name is required").max(300).min(2, "Must be greater then 2 characters"),
  company_code: yup.object().required("Item Company Code is required"),
  item_code: yup.string().required("Item Code is required").max(50),
  po_itemcode: yup.string().required("Item Code PO is required").max(50),
  pts: yup
    .number()
    .positive()
    .required()
    .test("is-length-valid", "PTS should not exceed 18 digits", (value) => (value + "").length <= 18)
    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (!value) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{0,2})?$/));
    })
    .test("is-less", "PTS should be less than PTR and MRP", function (value) {
      const { ptr, mrp } = this.parent;

      if (!mrp && !ptr && mrp !== 0 && ptr !== 0) return true;
      if (!mrp && mrp !== 0) return value < ptr;
      if (!ptr && ptr !== 0) return value < mrp;

      return value < ptr && value < mrp;
    }),
  ptr: yup
    .number()
    .positive()
    .required()
    .test("is-length-valid", "PTR should not exceed 18 digits", (value) => (value + "").length <= 18)
    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (!value) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{0,2})?$/));
    })
    .test("is-greater", "PTR should be greater than PTS", function (value: any) {
      const { pts } = this.parent as any;
      if (!pts && pts !== 0) return true;
      return value > pts;
    })
    .test("is-less", "PTR should be less than  MRP", function (value) {
      const { mrp } = this.parent;
      if (!mrp && mrp !== 0) return true;
      return value < mrp;
    }),
  mrp: yup
    .number()
    .positive()
    .required()
    .test("is-length-valid", "MRP should not exceed 18 digits", (value) => (value + "").length <= 18)
    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (!value) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{0,2})?$/));
    })
    .test("is-greater", "MRP should be greater than PTS and PTR", function (value) {
      const { pts, ptr } = this.parent;
      if (!pts && !ptr && pts !== 0 && ptr !== 0) return true;
      if (!pts && pts !== 0) return value > ptr;
      if (!ptr && ptr !== 0) return value > pts;

      return value > pts && value > ptr;
    }),

  gst_pc: yup
    .number()
    .nullable()
    .min(0, "It must be greater then 0")
    .lessThan(100, "This field should be less than 101")
    .required()
    .test("is-decimal", "Should not exceed 2 decimal", (value) => {
      if (value === null || value === undefined) return true;
      return Boolean((value + "").match(/^[0-9]+(\.[0-9]{1,2})?$/));
    }),
  item_uom: yup.string().notRequired().max(30),
  item_factor: yup.number().notRequired().positive().integer(),
  min_qty: yup.number().nullable().required().integer(), // checking self-equality works for NaN, transforming it to null
  integral_quantity: yup
    .number()
    .required()
    .integer()
    .test("is-less", "Integral Quantity should be less than Minimum Qty.", function (value) {
      const { min_qty } = this.parent;
      if (!min_qty) return true;

      return value <= min_qty;
    })
    .test("is-length-valid", "Should not exceed 10 digit", (value) => Boolean((value + "").match(/^[0-9]{1,10}$/))),

  brand: yup.string().notRequired(),
  reference_number: yup.string().notRequired(),
});

export const columns = [
  columnHelper.accessor("order_type", {
    cell: (info) => info.getValue(),
    header: () => "Order Type",
    enableSorting: false,
  }),
  columnHelper.accessor("item_code", {
    cell: (info) => info.getValue(),
    header: () => "Item Code",
    enableSorting: false,
  }),
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: () => "Product Name",
  }),
  columnHelper.accessor("remarks", {
    header: () => "Remarks",
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
];
