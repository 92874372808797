import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompanyState {
  companyDetails: any[] | null | undefined;
  primary_key: string | number | null | undefined;
  selectedCompanyData: any;
  companyEdit: {
    type: boolean;
    formData: any;
  };
  addEditFormData: any | null;
  isEdit: boolean | null | undefined;
  showAddEditForm: boolean | null | undefined;
  companyMasterTable: any[] | null | undefined;
}

// const initialCompanySlice = {
//   companyDetails: null,
//   primary_key: "",
//   isEdit: false,
//   selectedCompanyData: {},
//   showAddEditForm: false,
//   companyEdit: {
//     type: false,
//     formData: {},
//   },
// } as CompanyState;

console.log("Redux Calling");

const companySlice = createSlice({
  name: "company",
  initialState: {
    companyDetails: null,
    addEditFormData: null,
    isEdit: false,
    showAddEditForm: false,
    companyMasterTable: null,
  } as CompanyState,
  reducers: {
    setCompany(state, action: PayloadAction<any[] | null | undefined>) {
      state.companyDetails = action.payload;
    },
    setPrimaryKey(state, action: PayloadAction<string | number | null | undefined>) {
      state.primary_key = action.payload;
    },
    setIsEdit(state, action: PayloadAction<boolean | null | undefined>) {
      state.isEdit = action.payload;
    },
    setSelectedCompanyData(state, action: PayloadAction<any>) {
      state.selectedCompanyData = action.payload;
    },
    setShowAddEditForm(state, action: PayloadAction<boolean | null | undefined>) {
      state.showAddEditForm = action.payload;
    },
    setCompanyEdit(state, action: PayloadAction<any>) {
      state.companyEdit = action.payload || {};
    },

    setAddEditFormData(state, action: PayloadAction<any | null>) {
      state.addEditFormData = action.payload;
    },

    setCompanyMasterTable(state, action: PayloadAction<any[] | null | undefined>) {
      state.companyMasterTable = action.payload;
    },
  },
});

export const companyActions = companySlice.actions;
export { companySlice };
