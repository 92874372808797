import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { addAuthHeader } from "src/utils/auth";
import { api } from "../constants/global-const";

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/division",
});

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status: number | string;
  // Add other properties as needed
}

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const getAllDivisionDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const queryDivisionDetails = async (query: string): Promise<ApiResponse> => {
  return instance.get(query);
};

export const getOrderAndDiscount = async (query: string): Promise<AxiosResponse> => {
  return instance.get(`/details?${query}`);
};

export const getDivisionByEmployee = async (query?: any) => {
  if (query) {
    return instance.get(`/details/employee${query}`);
  } else {
    return instance.get(`/details/employee`);
  }
};
