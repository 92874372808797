import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EmployeeState {
  tableData: null | any[];
  selectedEmployeeData: any;
  addEditFormData: any;
  primary_key: string | number | null | undefined;
  formDivisionList: any[];
  showAddEditForm: boolean | null | undefined;
  isEdit: boolean | null | undefined;
  sections: number | any;
  maximumUserType: any;
  emp_position_hdr: string | number | null | undefined;
}

const initialEmployeeSlice = {
  tableData: null,
  selectedEmployeeData: {},
  primary_key: "",
  showAddEditForm: false,
  isEdit: false,
  maximumUserType: {},
  emp_position_hdr: null,
  sections: 1,
  addEditFormData: {
    pSRWise_division_Link: [],
  },

  formDivisionList: [],
} as EmployeeState;

const employeeSlice = createSlice({
  name: "employee",
  initialState: initialEmployeeSlice,
  reducers: {
    setTableData(state, action: PayloadAction<any>) {
      if (action?.payload) state.tableData = [...action.payload];
      else state.tableData = null;
    },
    appendEmployeeData(state, action: PayloadAction<any>) {
      state.tableData?.push(action.payload);
    },

    setDivisionListForForm(state, action: PayloadAction<any[] | null | undefined>) {
      state.formDivisionList = action.payload || [];
    },
    setPrimaryKey(state, action: PayloadAction<string | number | null | undefined>) {
      state.primary_key = action.payload;
    },

    setFormData(state, action: PayloadAction<any[] | any | null | undefined>) {
      const { keyofForm, value } = action.payload;
      state.addEditFormData[keyofForm] = value;
    },

    setformDivisionData(state, action: PayloadAction<any[] | any | null | undefined>) {
      if (!state.addEditFormData.pSRWise_division_Link[action.payload.index]) state.addEditFormData.pSRWise_division_Link[action.payload.index] = {};

      state.addEditFormData.pSRWise_division_Link[action.payload.index][action.payload.keyofForm] = action.payload.value;
    },

    setFullFormObject(state, action: PayloadAction<any[] | any | null | undefined>): void {
      state.addEditFormData = action.payload;
    },

    resetForm(state) {
      state.addEditFormData = initialEmployeeSlice.addEditFormData;
    },
    setEmployeeSelectedData(state, action: PayloadAction<any>) {
      state.selectedEmployeeData = action.payload;
    },

    setShowAddEditForm(state, action: PayloadAction<boolean | null | undefined>) {
      state.showAddEditForm = action.payload;
    },

    setIsEdit(state, action: PayloadAction<boolean | null | undefined>) {
      state.isEdit = action.payload;
    },

    setNumberOfSections(state, action: PayloadAction<number | null | undefined>) {
      state.sections = action.payload;
    },

    removeDivisionAutocompleteData(state) {
      Object.keys(state.addEditFormData).forEach((key) => {
        if (key.startsWith("divisionAutocomplete__")) {
          delete state.addEditFormData[key];
        }
      });
    },

    setEmp_position_hdr(state, action: PayloadAction<any>) {
      state.emp_position_hdr = action.payload;
    },

    setMaximumUserType(state, action: PayloadAction<any>) {
      state.maximumUserType = action.payload;
    },
    removeL1EMployee(state) {
      Object.keys(state.addEditFormData).forEach((key) => {
        if (key.startsWith("l1_employeeName__")) {
          delete state.addEditFormData[key];
        }
        if (key.startsWith("l1_ec__")) {
          delete state.addEditFormData[key];
        }
      });
    },

    removel1EmployeeFromSpecificIndex(state, action: PayloadAction<number[]>) {
      // i want to accept array of index
      action.payload.forEach((index) => {
        delete state.addEditFormData?.[`l1_ec__${index}`];
      });
    },

    removeEmptyL1EMployee(state) {
      Object.keys(state.addEditFormData).forEach((key) => {
        if (key.startsWith("l1_ec__")) {
          console.log("removing");

          state.addEditFormData[key] = null;
        }
      });
      alert("removed");
      state.addEditFormData.l1_ec__1 = null;
    },
  },
});

export const employeeActions = employeeSlice.actions;
export { employeeSlice };
