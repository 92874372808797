import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/context/store";
import * as yup from "yup";
import Button from "../components/Button";
import SmartForm from "../components/SmartForm";
import { craActions } from "../context/cra-slice";
import { useToast } from "../hooks/useToast";
import { queryCounterDetails, queryCounterDetailsById } from "../services/counter-api";
import { getAllCityDetails } from "../services/product-api";

const AdoptCounterForm = ({ onSubmit, onCancel }: { onSubmit: any; onCancel: any }) => {
  const [cityList, setCityList] = useState<any[]>([]);
  const { toast } = useToast();
  const dispatch = useDispatch();
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);
  const [selectedDoctor, setSelectedDoctor] = useState<any>({});
  const [loading, setLoading] = useState(false);

  async function handleFormSubmission() {
    try {
      if (loading) return;
      setLoading(true);
      const res = await queryCounterDetailsById(`?id=${selectedDoctor?.counter_id}`);
      dispatch(craActions.setFormDefaultValues(res?.data || []));
      dispatch(craActions.setAdoptCounterCode(selectedDoctor?.counter_code || ""));
      dispatch(craActions.setLocationCode(selectedDoctor?.location_code || ""));
      dispatch(craActions.setAdoptDrType(selectedDoctor?.drph_lnk_type?.dr_ph_lnk_code || ""));
      onSubmit(selectedDoctor || []);
    } catch (error) {
      toast({
        description: "Error adopting counter",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchCity() {
      try {
        const res = await getAllCityDetails();
        if (res?.status !== 200) throw new Error();
        setCityList(res?.data?.data);
      } catch (error) {
        toast({
          description: "Error fetching city",
          variant: "destructive",
        });
      }
    }
    fetchCity();
  }, []);

  const validationSchema = yup.object().shape({
    doctor_code: yup.object().test("doctor_code", "Doctor code is required when doctor name and city are not provided", function (value) {
      const { counter_name, city } = this.parent;
      return (counter_name && city) || value != null;
    }),
    counter_name: yup.object().test("counter_name", "Doctor name is required when doctor code is not provided", function (value) {
      const { doctor_code, city } = this.parent;
      return doctor_code || (value != null && city != null);
    }),
    city: yup.object().test("city", "City is required when doctor code and doctor name are not provided", function (value) {
      const { doctor_code, counter_name } = this.parent;
      return doctor_code || (value != null && counter_name != null);
    }),
    // company: yup.object().test("company", "Company is required when doctor code or doctor name are provided", function (value) {
    //   const { doctor_code, counter_name } = this.parent;
    //   if ((doctor_code || counter_name) && !value) return false;
    //   return true;
    // }),
  });

  const smartFormInstance = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const queryCounterDetailsPromise = async (searchQuery: any, currentPage: number = 0, isCityIncluded: boolean = true): Promise<any> => {
    const company_id = idTokenDetails?.["custom:company"];

    let backendParams = "?";
    const cityId = (smartFormInstance.getValues("city") as any)?.city_code;
    if (searchQuery && isCityIncluded) {
      if (/^dr/i.test(searchQuery)) {
        backendParams += `city_code=${cityId}&counter_code=${searchQuery.toUpperCase().substring(0, 2) + searchQuery.substring(2)}`;
      } else if (isNaN(Number(searchQuery))) {
        backendParams += `city_code=${cityId}&name=${searchQuery}`;
      }
    }
    if (searchQuery && !isCityIncluded) {
      backendParams += `counter_code=${searchQuery.toUpperCase().substring(0, 2) + searchQuery.substring(2)}`;
    }

    const response = await queryCounterDetails(
      `${backendParams}&page=${currentPage}&perPage=15&company_id=${company_id}&isCompany=true&isOnlyActive=true&isPharmacy=true&notDivision=${idTokenDetails?.["custom:division"]}`
    );

    const transformedAsyncList: any[] = [];
    response?.data?.data?.forEach((counter: any) => {
      counter?.counter_location?.forEach((counterLocation: any) => {
        if (
          counterLocation?.drph_lnk_type?.dr_ph_lnk_code !== "INDPH-NT" &&
          counterLocation?.drph_lnk_type?.dr_ph_lnk_code !== "DRNT" &&
          counterLocation?.location_code
        )
          transformedAsyncList.push({
            counter_name: counter?.counter_name,
            counter_id: counter?.counter_id,
            counter_code: counter?.counter_code,
            location_code: counterLocation?.location_code,
            location_name: counterLocation?.location_name,
            drph_lnk_type: counterLocation?.drph_lnk_type,
          });
      });
    });

    return Promise.resolve(transformedAsyncList);
  };

  return (
    <SmartForm formInstance={smartFormInstance} schema={validationSchema} isControlled={false} defaultValues={{}} onFormSubmit={handleFormSubmission}>
      <section className="flex flex-col gap-2 px-5 py-3 w-80">
        <SmartForm.Autocomplete
          className=""
          placeholder="Search doctor code"
          fieldName="doctor_code"
          label="Doctor Code"
          suggestionList={[]}
          asyncListFunction={(query: string, currentPage: number) => {
            return queryCounterDetailsPromise(query, currentPage, false);
          }}
          additionalfield={"counter_name"}
          uniqueKey={"location_code"}
          additionalDisplayKeySearch={true}
          displayKey="counter_code"
          tertiaryDisplayKey={"location_name"}
          quaternaryDisplayKey={"drph_lnk_type.dr_ph_lnk_code"}
          isAsyncQuery={true}
          onChange={setSelectedDoctor}
        />
        <p className="p-0 m-0 text-xs font-medium text-center text-gray-400">OR</p>
        <SmartForm.Autocomplete
          className=""
          placeholder="Select City"
          fieldName="city"
          label="City"
          suggestionList={cityList}
          uniqueKey="city_code"
          displayKey="city_name"
          onChange={() => smartFormInstance.setValue("counter_name", null as any)}
        />
        <SmartForm.Autocomplete
          className=""
          placeholder="Select Doctor"
          fieldName="counter_name"
          label="Doctor"
          suggestionList={[]}
          asyncListFunction={queryCounterDetailsPromise}
          uniqueKey="counter_code"
          displayKey="counter_name"
          additionalDisplayKeySearch={true}
          additionalfield="counter_code"
          tertiaryDisplayKey={"location_name"}
          quaternaryDisplayKey={"drph_lnk_type.dr_ph_lnk_code"}
          isAsyncQuery={true}
          onChange={(value) => {
            const cityId = (smartFormInstance.getValues("city") as any)?.city_code;
            if (!cityId) {
              toast({
                description: "Please select a city",
                variant: "destructive",
              });
              smartFormInstance.setValue("counter_name", null as any);
              return;
            }
            setSelectedDoctor(value);
          }}
        />
      </section>
      <footer className="border border-t-[1px] flex justify-end px-5 py-3 gap-3 rounded-bl-2xl rounded-br-2xl">
        <Button onClick={onCancel} className="border">
          Cancel
        </Button>
        <Button isLoading={loading} loaderSize="small" type="submit" className="text-white bg-[#586AF5]">
          Adopt
        </Button>
      </footer>
    </SmartForm>
  );
};

export default AdoptCounterForm;
