import axios from "axios";

export const api = import.meta.env.VITE_API_URL;

export const getCookieValue = (cookieName: string): string | null => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
};

export const logout = (showWarning: Boolean = true) => {
  const clientId = import.meta.env.VITE_CLIENT_ID;
  const redirectUri = encodeURIComponent(window.location.origin);

  const queryParams = new URLSearchParams({
    response_type: "token",
    client_id: clientId,
    redirect_uri: redirectUri,
  });

  let logoutResponse = true;

  if (showWarning) {
    logoutResponse = confirm("You are logged out. Please login again to continue.");
    if (!logoutResponse) return;
  }

  let logoutUrl: any = ``;

  logoutUrl = `${import.meta.env.VITE_LOGOUT_URL}?${queryParams.toString()}`;

  window.location.href = logoutUrl;
};

export enum ActionType {
  accept = "accept",
  approval = "approval",
  post = "post",
  validate = "validate",
  viewer = "viewer",
  reject = "reject",
  pending = "pending",
}

/**
 * Class representing an API blocker.
 */
const apiCall = async (axios: any, url: string, data?: any): Promise<any> => {
  try {
    // const type = window.location.origin.includes("ops.stage") ? "/stage" : "/dev";

    const response = await axios.get(api + url, {
      headers: {
        Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
      },
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error("API call failed", error);
    return Promise.reject(error);
  }
};

export let toastStatic = { value: false, message: "" };

export const changeShowErrorToast = (value: boolean, message: string) => {
  toastStatic = { value, message };
};

export let bannerStatic = { isEventPending: false, isEventBlocked: false };

export const changeApiBlocker = (isEventPending: boolean, isEventBlocked: boolean) => {
  bannerStatic = { isEventPending, isEventBlocked };
};

export class ApiBlocker {
  /**
   * Indicates whether the API is blocked or not.
   */
  static isApiBlocked = false;
  static isEventBlocked = false;
  static isEventPending = false;

  /**
   * Interceptor function to block API requests based on the status condition.
   * @param axios - The axios instance.
   * @returns The axios interceptor.
   */

  static eventChecked = async (streamId?: string) => {
    try {
      changeApiBlocker(false, false);
      changeShowErrorToast(false, "");

      if (!streamId) return;
      const response = await apiCall(axios, `/api/v1/common/check-event-status?streamId=${streamId}&consumerName=V2`);

      changeApiBlocker(response?.isEventPending, response?.isEventBlocked);
      ApiBlocker.isEventBlocked = response?.isEventBlocked;
      ApiBlocker.isEventPending = response?.isEventPending;
      if (response?.isEventBlocked || response?.isEventPending) {
        const message = response.isEventBlocked ? "Event Blocked. Please try after some time" : "Event is pending. Please wait";
        changeShowErrorToast(true, message);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  static isApiBlockedFn(axios: any) {
    return axios.interceptors.request.use(
      (config: any) => {
        // Check the status condition before allowing the request
        if ((config.method === "post" || config.method === "put" || config.method === "patch") && ApiBlocker.isApiBlocked) {
          // If the condition is not met, you can reject the request
          return Promise.reject(new Error("Post request is blocked."));
        }

        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
}
