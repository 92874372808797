import { ErrorBoundary } from "@sentry/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "src/context/store.ts";
import App from "./App.tsx";
import "./index.css";
// add the beginning of your app entry
import * as Sentry from "@sentry/browser";
import { reactRouterV6Instrumentation } from "@sentry/react";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import "vite/modulepreload-polyfill";
import SomethingWentWrong from "./pages/not-found/something-went-wrong.component.tsx";
import { isProd } from "./constants/global-const.ts";

// dont run on localhost
if (window.location.origin.toString().toLowerCase().includes("medvol.in")) {
  Sentry.init({
    // Your Sentry DSN
    dsn: "https://1b0b31acbe351985c2dee8c64ebe68ec@o4506504892448768.ingest.sentry.io/4506512043606016",
    release: "0.1",
    integrations: window.location.origin.toString().toLowerCase().includes("ops.medvol.in")
      ? [
          new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay({
            // Use new Sentry.Replay() for replay integration
            maskAllText: false, // Allow all text to be visible
            blockAllMedia: false, // Allow all media content to be visible
          }),
        ]
      : [
        new Sentry.BrowserTracing({
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["*"],
    // Capture Replay for 100% of sessions with an error

    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    environment: isProd ? "production" : "development",
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <ErrorBoundary fallback={<SomethingWentWrong />}>
      <App />
    </ErrorBoundary>
  </Provider>
);
