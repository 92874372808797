import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "src/components";
import Loader from "src/components/ui/loader.component";
import DetailsGrid from "src/container/details-grid/details-grid.component";
import TableActiveInactiveDropdown from "src/container/table-active-inactive-dropdown.component";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { editStockistCompany, queryStockist } from "src/services/stockist-api";

const columnHelper = createColumnHelper();

interface Data {
  company: any;
  operator_name: any;
  operator_mobile: any;
  operator_email: any;
  plantCode: any;
  remarks?: any;
  reference_number: any;
  compStockistCode: any;
  compStockistAliasCode: any;
  stockist_type: any;
  stockist: any;
  elnv_exempt: any;
  stockistClusterLnk: any[];
  id: any;
  active_status?: "Y" | "N"; // Optional property to handle active status
  owner_name: any;
  city: any;
  email: any;
  medvol_code: any;
  firm_name: any;
}

const DetailsSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  // const [activeStatus, setactiveStatus] = useState<boolean>();
  const { selectedStockistData } = useSelector((state: RootState) => state.stockist);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();

  const fetchDetails = useCallback(async () => {
    if (!window.sessionStorage.getItem("stockistMasterId")) {
      navigate("/masters/stockists");
    }

    const queryParams = `?stockist_id=${window.sessionStorage.getItem("stockistMasterId")}`;
    setIsDataLoaded(false);
    let response: any = null;
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true`);
    if (response?.status !== 200) throw new Error();
    // response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true`);
    console.log("response?.data?.data[0]", response?.data?.data[0]);
    setIsLoading(false);
    dispatch(stockistActions.setSelectedStockistData(response?.data?.data[0]));
    setIsDataLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (!selectedStockistData || Object.keys(selectedStockistData)?.length === 0) {
      setIsLoading(true);
      interval = setInterval(async () => {
        try {
          await fetchDetails();
        } catch (error) {
          clearInterval(interval);
          setIsLoading(false);
          toast({
            description: "Unable to fetch details",
            variant: "destructive",
          });
        }
      }, 1500);
    }
    return () => {
      clearInterval(interval);
    };
  }, [fetchDetails, selectedStockistData]);

  const transformRowData = (data: any) => {
    const rowData = structuredClone(data);
    const tempObj: any = {};
    rowData.stockistClusterLnk?.forEach((item: any, index: number) => {
      (tempObj[`clusterCode__${index}`] = item?.Cluster_Code ?? ""),
        (tempObj[`margin__${index}`] = item.paymentTermHdr ?? item.margin ?? ""),
        (tempObj[`paymentTermHdr__${index}`] = item.paymentTermHdr ?? ""),
        (tempObj[`distributionChannel__${index}`] = item.distributionChannel);
    });

    return {
      ...rowData,
      ...tempObj,
      elnv_exempt: { elnv_exempt: rowData?.elnv_exempt, id: 1 },
      stockist_type: { stockist_type: rowData?.stockist_type, id: 1 },
    };
  };

  const handleEditCompany = async (data: any) => {
    dispatch(stockistActions.setClusterFormSectionCounter(data?.stockistClusterLnk?.length || 1));
    dispatch(stockistActions.setStockistLinkFormFullObject(transformRowData(data)));
    dispatch(stockistActions.linkClusterCompanyFormData({ status: true }));
    dispatch(stockistActions.setMainFormEditMode(true));
    dispatch(stockistActions.setStockistLinkEditMode(true));
  };

  const handleLinkCompany = async (addEditFormData: any) => {
    console.log("formedData", addEditFormData);
    console.log("selectedStockistData", selectedStockistData);

    const data: Data = {
      company: addEditFormData?.company?.code, // Assuming company is represented by an ID
      operator_name: addEditFormData?.operator_name,
      operator_mobile: addEditFormData?.operator_mobile,
      operator_email: addEditFormData?.operator_email,
      plantCode: addEditFormData?.plantCode,
      reference_number: addEditFormData?.reference_number,
      compStockistCode: addEditFormData?.compStockistCode,
      compStockistAliasCode: addEditFormData?.compStockistAliasCode,
      stockist_type: addEditFormData?.stockist_type,
      stockist: window.sessionStorage.getItem("stockistMasterId"),
      elnv_exempt: addEditFormData?.elnv_exempt,
      stockistClusterLnk: addEditFormData?.stockistClusterLnk?.map((item: any) => {
        return {
          id: item?.id,
          cluster_code: item?.Cluster_Code?.cluster_code,
          paymentTermHdr: item?.paymentTermHdr?.id,
          payment_paymentTermCode: item?.paymentTermHdr?.paymentTermCode,
          margin: item?.margin?.margin,
          distributionChannel: item?.distributionChannel,
        };
      }),
      id: addEditFormData?.id || null,
      owner_name: selectedStockistData?.owner_name,
      firm_name: selectedStockistData?.firm_name,
      city: selectedStockistData?.city?.city_code,
      email: selectedStockistData?.email,
      medvol_code: selectedStockistData?.medvol_code,
    };

    data["active_status"] = addEditFormData?.active_status != "Y" ? "Y" : "N";

    console.log("data", data);

    console.log("selectedStockistData", selectedStockistData);

    try {
      const resCompany = await editStockistCompany({ ...data, stockist: data?.stockist }, data?.stockist);

      if (resCompany?.status === 200) {
        toast({
          description: "Company status updated successfully",
        });
        dispatch(stockistActions.setShowStockistMainForm(false));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(stockistActions.setLinkedCompaniesData(data));
    setTimeout(() => {
      fetchDetails();
    }, 1500);
  };

  // Define the columns for the table
  const columns = useMemo(
    () => [
      columnHelper.accessor("company", {
        cell: (info: any) => (
          <div className="px-4 py-3 overflow-hidden text-sm not-italic font-semibold text-ellipsis line-height-normal whitespace-nowrap">
            {info.getValue()?.shortname}
          </div>
        ),
        header: () => "Company",
        enableSorting: true,
      }),
      columnHelper.accessor("active_status", {
        header: () => "Link Status",
        cell: (info: any) => (
          <div className="px-4 py-3 overflow-hidden text-sm not-italic font-semibold text-ellipsis line-height-normal whitespace-nowrap">
            <TableActiveInactiveDropdown
              disabled={!isDataLoaded}
              defaultValue={info.getValue() === "Y" ? "active" : "inactive"}
              onChanged={() => handleLinkCompany(info?.row?.original)}
            />
          </div>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor("action", {
        header: () => "Action",
        cell: (info: any) => (
          <Button
            onClick={() => {
              handleEditCompany(info?.row?.original);

            }}
            className="text-blue-600 underline"
          >
            Edit
          </Button>
        ),
        enableSorting: true,
      }),
    ],
    [isDataLoaded]
  );

  function switchString(input: string) {
    switch (input.toLowerCase()) {
      case "stockist_id":
        return undefined;
      case "owner_name":
        return "Owner Name";
      case "owner_mobile_no":
        return "Owner Mobile Number";
      case "medvol_code":
        return "Medvol Code";
      case "uid":
        return undefined;
      case "cognito_id":
        return "Cognito ID";
      case "created_by":
        return undefined;
      case "created_date":
        return "Created Date";
      case "modified_by":
        return undefined;
      case "modified_date":
        return "Modified Date";
      case "active_status":
        return undefined;
      case "firm_name":
        return "Firm Name";
      case "manager_name":
        return "Manager Name";
      case "manager_mobile_no":
        return "Manager Mobile Number";
      case "address":
        return "Address";
      case "gst":
        return "GST";
      case "drug_licence":
        return "Drug Licence";
      case "food_licence":
        return "Food Licence";
      case "email":
        return "Email";
      case "u_code":
        return undefined;
      case "group_name":
        return "Group Name";
      case "elnv_qualification":
        return "E-lnv. Qualification";
      case "elnv_invoice":
        return "E-Invoice Ack.";
      case "medvol_field":
        return "Medvol Field";
      case "gst_available":
        return "GST Available";
      case "gst_status":
        return "GST Status";
      default:
        return input;
    }
  }

  if (isLoading) return <Loader />;

  console.log("selectedStockistData", selectedStockistData);

  return (
    <section className="h-auto min-h-screen pb-20 ">
      <div className="display-f">
        <div className="flex-1">
          <div className="flex flex-col mb-2">
            <h3 className="text-xl font-semibold line-height-normal leading-[0.025rem] flex gap-2 items-center">User Details</h3>
            <p className="mt-2 text-xs font-semibold text-gray-500 ">View basic user details and information</p>
          </div>

          <Divider />

          <DetailsGrid removeNestedData={true} dataObj={selectedStockistData} keyString={switchString} />

          <div className="flex items-center justify-between gap-3">
            <div>
              <h3 className="text-xl font-semibold line-height-normal leading-[0.025rem]  mt-14 ">Company Associations</h3>
              <p className="my-2 text-xs font-semibold text-gray-500">View basic user details and information</p>
            </div>
            <Button
              className="border"
              onClickCapture={() => {
                dispatch(stockistActions.setStockistLinkFormFullObject({}));
                dispatch(stockistActions.linkClusterCompanyFormData({ status: true }));
                dispatch(stockistActions.setMainFormEditMode(false));
                dispatch(stockistActions.setIndependentAddCompany(true));
              }}
            >
              Add Company
            </Button>
          </div>
          <Divider />

          <Table data={selectedStockistData?.stockist_company_lnk?.length > 0 ? selectedStockistData?.stockist_company_lnk : []} columns={columns} />
        </div>
      </div>
    </section>
  );
};

const Divider = () => <div className="h-[0.0625rem] w-full bg-[#00000010] mx-auto my-3" />;

export default DetailsSection;
