interface IDataOrigin {
  name: string;
  path: string;
}

interface BreadcrumbProps {
  dataOrigin: IDataOrigin[];
  currentActive: string;
}

const Breadcrumb = ({ dataOrigin, currentActive }: BreadcrumbProps) => {
  return (
    <nav aria-label="breadcrumb" className="block w-full">
      <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
        {dataOrigin?.map((item, index) => (
          <li
            key={item?.name}
            onClick={() => {}}
            className={`${
              currentActive.toLocaleLowerCase() === item?.path?.toLocaleLowerCase() ? "text-purple-800" : ""
            } "flex items-center font-sans text-lg antialiased f leading-normal font-semibold transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500"`}
          >
            <button className="opacity-60">{item?.name}</button>
            {dataOrigin?.length - 1 !== index && (
              <span className="mx-2 font-sans text-lg antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                /
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
