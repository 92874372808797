import Button from "../components/Button";

const UinDetails = ({ onConfirm, onBack, uinData, responseData }: { onBack: () => void; onConfirm: () => void; uinData: any; responseData: any }) => {
  console.log("uinData", uinData);
  console.log("responseData", responseData);

  return (
    <section className="px-5 py-3">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-[#f8f8f8] text-[#49484C]">
            <th className="p-4" />
            <th className="p-4">As per Medvol</th>
            <th className="p-4">As per Company</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">Name</td>
            <td className="p-4 font-semibold">{uinData?.counter_name}</td>
            <td className="p-4 font-semibold">{responseData?.CompanyUINDetails?.Counter_Name}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">City</td>
            <td className="p-4 font-semibold">{uinData?.city_code?.city}</td>
            <td className="p-4 font-semibold">{responseData?.CompanyUINDetails?.City ?? "-"}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8] ">State</td>
            <td className="p-4 font-semibold">{uinData?.city_code?.district_code?.state_code?.state_name}</td>
            <td className="p-4 font-semibold">{responseData?.CompanyUINDetails?.State ?? "-"}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">UIN Speciality</td>
            <td className="p-4 font-semibold">-</td>
            <td className="p-4 font-semibold">{responseData?.CompanyUINDetails?.UIN_Speciality}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">Division Speciality</td>
            <td className="p-4 font-semibold">-</td>
            <td className="p-4 font-semibold">{responseData?.CompanyUINDetails?.Division_Speciality}</td>
          </tr>
        </tbody>
      </table>
      <footer className="flex items-center justify-end gap-3 ">
        <Button onClick={onBack} className="border">
          Back
        </Button>
        <Button onClick={onConfirm} className="text-white bg-[#586AF5]">
          Confirm
        </Button>
      </footer>
    </section>
  );
};

export default UinDetails;
