import { useEffect, useState } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select.component";

interface ITableActiveInactiveDropdown {
  label?: string;
  placeholder?: string;
  defaultValue?: "active" | "inactive";
  onChanged?: (value: string) => void;
  disabled?: boolean;
}

const TableActiveInactiveDropdown = ({ label, placeholder, defaultValue, onChanged, disabled = false }: ITableActiveInactiveDropdown) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue || "");

  console.log("defaultValue", defaultValue);

  const handleChange = (value: string) => {
    if (onChanged) {
      onChanged(value);
      // setSelectedValue(value);
    }
  };

  useEffect(() => {
    setSelectedValue(defaultValue || "");
  }, [defaultValue]);
  return (
    <>
      <Select value={selectedValue} onValueChange={handleChange} disabled={disabled}>
        <SelectTrigger className="w-[180px] font-semibold">
          {selectedValue?.toString()?.toLocaleLowerCase()?.trim() === "active" ? <GreenBall /> : <RedBall />} Status:
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectGroup>
            {label && <SelectLabel>{label}</SelectLabel>}
            <SelectItem value="active">Active</SelectItem>
            <SelectItem value="inactive">Inactive</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
};

const RedBall = () => <div className="w-3 h-3 bg-red-500 rounded-full" />;
const GreenBall = () => <div className="w-3 h-3 bg-green-500 rounded-full" />;

export default TableActiveInactiveDropdown;
