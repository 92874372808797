import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Badge, Button } from "src/components";

// Create a column helper
const columnHelper = createColumnHelper();

export const getCompanyMasterColumns = (dependencies: string[], onEdit: any) => {
  return useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: () => "Name",
        enableSorting: true,
        size: 101,
      }),
      columnHelper.accessor("shortname", {
        cell: (info) => info.getValue(),
        header: () => "Short Name",
        enableSorting: true,
        size: 101,
      }),
      columnHelper.accessor("code", {
        cell: (info) => info.getValue(),
        header: () => "Company Code",
        enableSorting: true,
        size: 101,
      }),
      columnHelper.accessor("status", {
        cell: (info) => (info.getValue() ? <Badge variant="success">True</Badge> : <Badge>False</Badge>),
        header: () => "Status",
        enableSorting: true,
        size: 101,
      }),
      columnHelper.accessor("edit", {
        cell: (info) => (
          <Button className="border" onClick={() => onEdit(info.row.original)}>
            Edit
          </Button>
        ),
        header: () => "Status",
        enableSorting: true,
        size: 101,
      }),
    ],
    [...dependencies]
  );
};
