/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components";
import SmartForm from "src/container/smart-form.component";
import { counterActions } from "src/context/counter-slice";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { getClusterFromCompany } from "src/services/company-api";
import { getPaymentTerms } from "src/services/stockist-api";

interface IStockistCluster {
  companyData: any;
}

// Define the StockistCluster component
const StockistCluster = ({ companyData }: IStockistCluster) => {
  // Get the addEditFormData from the redux store
  const { toast } = useToast();
  const dispatch = useDispatch();

  const { sections } = useSelector((state: RootState) => state.employee);
  const { addEditFormData, mainFormEditMode } = useSelector((state: RootState) => state.stockist);
  const { clusterList } = useSelector((state: RootState) => state.counter);
  const [originalClusterList, setOriginalClusterList] = useState([]);
  const [companyToCompare, setCompanyToCompare] = useState("");
  // Define the state for the divisionList

  const resetClusterFields = () => {
    if (companyToCompare === addEditFormData?.company?.name) return;
    const updatedFormData = { ...addEditFormData };

    FORM_FIELDS.forEach((section: any[]) => {
      section.forEach((field: any) => {
        const fieldKey = field?.keyofForm;
        updatedFormData[fieldKey] = null; // Reset the field to null
      });
    });

    dispatch(stockistActions.setStockistLinkFormFullObject(updatedFormData));
  };

  const handleSelectCompany = async (data: any) => {
    if (!data?.code) return;
    getClusterFromCompany(data?.code)
      .then((res: any) => {
        if (res?.status === 200) {
          setOriginalClusterList(res.data);
          dispatch(counterActions.setClusterList(res.data));
          updateFormFields(res.data, "clusterCode");
        }
      })
      .catch((err: any) => {
        toast({ description: JSON.stringify(err.message) });
      });
    // setstockistSearch((prevState) => ({ ...prevState, company: data?.counter_cmpny_location_id || [] }));
  };

  const appendClusterSections = () => {
    if (!mainFormEditMode && !addEditFormData?.stockistClusterLnk?.length) return;

    const updatedFormFields = addEditFormData.stockistClusterLnk.map((_: any, index: number) => {
      return initialformFields.map((item) => {
        const currentItem = { ...item };
        const currentFormKey = currentItem.keyofForm;
        const slicedFormKey = currentFormKey?.split("__")[0];
        const newFormKey = `${slicedFormKey}__${index}`;
        return { ...currentItem, keyofForm: newFormKey };
      });
    });

    setFORM_FIELDS(updatedFormFields);
  };

  const getInitialApiResponses = async (stockistClusterLink: any[]) => {
    if (!mainFormEditMode && !stockistClusterLink?.length) return;

    for (const [index, obj] of stockistClusterLink.entries()) {
      if (obj?.Cluster_Code?.cluster_code) {
        try {
          const marginApiRes = await getPaymentTerms(`?cluster_code=${obj.Cluster_Code.cluster_code}&margin=&getCluster=true`);
          updateFormFields(marginApiRes?.data?.data || [], "margin", index);
        } catch (error) {
          console.error(`Error fetching margin API response for index ${index}:`, error);
        }
      }

      if (obj?.Cluster_Code?.cluster_code && obj?.paymentTermHdr?.margin) {
        try {
          const paymentTermApiRes = await getPaymentTerms(`?cluster_code=${obj.Cluster_Code.cluster_code}&margin=${obj.paymentTermHdr.margin}`);
          updateFormFields(paymentTermApiRes?.data?.data || [], "paymentTermHdr", index);
        } catch (error) {
          console.error(`Error fetching payment term API response for index ${index}:`, error);
        }
      }
    }
  };

  const getpayTerms = async (clusterCode: number, margin?: number | null) => {
    try {
      const res = await getPaymentTerms(`?cluster_code=${clusterCode}&margin=${margin || ""}${!margin ? "&getCluster=true" : ""}`);
      if (res?.status === 200) {
        const paymentTermsRes = res?.data?.data || [];
        return paymentTermsRes;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      toast({
        description: "Error fetching payment terms",
        variant: "destructive",
      });
      return [];
    }
  };

  useEffect(() => {
    setCompanyToCompare(companyData?.name);
    if (!companyData || (companyData && Object.keys(companyData)?.length === 0)) {
      handleSelectCompany(addEditFormData?.company);
      appendClusterSections();
    } else {
      handleSelectCompany(companyData);
      resetClusterFields();
    }

    if (addEditFormData?.stockistClusterLnk?.length > 0) {
      getInitialApiResponses(addEditFormData?.stockistClusterLnk || []);
    }
  }, [companyData?.code, addEditFormData?.company]);

  const initialformFields = [
    {
      label: "Cluster",
      type: "autocomplete",
      id: "1",
      suggestionList: clusterList,
      uniqueKey: "cluster_code",
      keyofForm: "clusterCode",
      displayKey: "cluster_name",
    },
    {
      id: "2",
      label: "Margin",
      type: "autocomplete",
      keyofForm: "margin",
      suggestionList: [],
      uniqueKey: "margin",
      displayKey: "margin",
      disabled: false,
    },
    {
      id: "3",
      label: "Payment Term Code",
      type: "autocomplete",
      keyofForm: "paymentTermHdr",
      suggestionList: [],
      uniqueKey: "id",
      displayKey: "paymentTermCode",
      disabled: false,
    },
    {
      id: "4",
      label: "Distribution channel",
      type: "input",
      keyofForm: "distributionChannel",
      disabled: false,
    },
  ];

  // Define the state for the form fields
  const [FORM_FIELDS, setFORM_FIELDS] = useState(
    Array.from({ length: sections === 0 ? 1 : sections }, (_, index) => [
      ...initialformFields.map((item) => {
        const currentItem = item;
        const currentFormKey = currentItem.keyofForm;
        const slicedFormKey = currentFormKey?.split("__")[0];
        const newFormKey = slicedFormKey + "__" + index;
        return { ...item, keyofForm: newFormKey };
      }),
    ])
  );

  // Define the function to handle adding a new division
  const handleAddDivision = async () => {
    const newInitialFormFields = initialformFields.map((item) => {
      const currentItem = item;
      const currentFormKey = currentItem.keyofForm;
      const slicedFormKey = currentFormKey?.split("__")[0];
      const newFormKey = slicedFormKey + "__" + FORM_FIELDS.length;
      return { ...item, keyofForm: newFormKey };
    });

    newInitialFormFields[0].suggestionList = [...clusterList];

    dispatch(stockistActions.setClusterFormSectionCounter(FORM_FIELDS.length + 1));
    // Update the form fields state with the new form fields
    setFORM_FIELDS((prevFORM_FIELDS) => [...prevFORM_FIELDS, newInitialFormFields]);
  };

  // Define the function to update the form fields
  const updateFormFields = useCallback((data: any[], keyOfForm: string, index?: number | undefined) => {
    setFORM_FIELDS((prevFormFields: any) => {
      return prevFormFields.map((formFieldArray: any, arrayIndex: number) => {
        if (index === undefined || arrayIndex === index) {
          return formFieldArray.map((formField: any) => {
            if (formField.keyofForm.split("_")[0] === keyOfForm) {
              return {
                ...formField,
                suggestionList: [...data],
              };
            }
            return formField;
          });
        }
        return formFieldArray;
      });
    });
  }, []);

  const handleRemoveDivision = (indexToRemove: number) => {
    // Get the current form data from the state
    const currentFormData: any = structuredClone(addEditFormData);

    const uniqueSectionsSet = new Set();

    const sortedKeys = Object.keys(currentFormData).sort((a, b) => {
      const indexA = parseInt(a.split("__")[1]);
      const indexB = parseInt(b.split("__")[1]);
      return indexA - indexB;
    });

    // Iterate over the keys in the form data
    sortedKeys.forEach((key) => {
      // Check if the key includes "__"
      if (key.includes("__")) {
        // Split the key into the field name and index
        const [fieldName, index] = key.split("__");

        // Parse the index into a number
        const parsedIndex = parseInt(index);

        // Check if the index is greater than the index to remove
        if (parsedIndex > indexToRemove) {
          // Decrease the index by 1
          const newIndex = parsedIndex - 1;
          // Create the new key
          const newKey = `${fieldName}__${newIndex}`;

          // Update the key in the form data
          currentFormData[newKey] = currentFormData[key];
          delete currentFormData[key];
        }
        if (parsedIndex === indexToRemove) {
          delete currentFormData[key];
        }

        if (parsedIndex !== indexToRemove) uniqueSectionsSet.add(parsedIndex);
      }
    });

    // Create a copy of the form fields and remove the specified index
    let updatedFormFields = [...FORM_FIELDS];
    updatedFormFields.splice(indexToRemove, 1);

    // Adjust the keys in the form fields
    updatedFormFields = updatedFormFields.map((formField, i) => {
      return formField.map((field) => {
        const slicedFormKey = field.keyofForm?.split("__")[0];
        const newFormKey = slicedFormKey + "__" + i;
        const updatedField = { ...field, keyofForm: newFormKey };
        return updatedField;
      });
    });

    setFORM_FIELDS(updatedFormFields);

    dispatch(stockistActions.setClusterFormSectionCounter(uniqueSectionsSet.size || 1));

    // Update the form data in the redux store
    dispatch(stockistActions.setStockistLinkFormFullObject(currentFormData));
  };

  // remove duplicate cluster from suggestion list

  useEffect(() => {
    let updatedClusters: any = structuredClone(originalClusterList);

    Object.keys(addEditFormData)?.forEach((key: any) => {
      // If the key starts with "divisionAutoComplete", filter the divisionList
      if (key.toString().startsWith("clusterCode")) {
        updatedClusters = updatedClusters.filter((cluster: any) => cluster.cluster_code !== addEditFormData[key]?.cluster_code);
        updateFormFields(updatedClusters, "clusterCode");
      }
    });

    dispatch(counterActions.setClusterList(updatedClusters));
  }, [addEditFormData]);

  const autocompleteHandler = async (data: any, keyofForm: string, index: number) => {
    switch (keyofForm.split("_")[0]) {
      case "clusterCode":
        dispatch(
          stockistActions.setFormData({
            keyofForm: `margin__${index}`,
            value: null,
          })
        );
        dispatch(
          stockistActions.setFormData({
            keyofForm: `paymentTermHdr__${index}`,
            value: null,
          })
        );
        const marginList = await getpayTerms(data?.cluster_code, null);
        updateFormFields(marginList, "margin", index);
        break;

      case "margin":
        dispatch(
          stockistActions.setFormData({
            keyofForm: `paymentTermHdr__${index}`,
            value: null,
          })
        );
        const selectedCluster = addEditFormData[`clusterCode__${index}`];
        const paymentTermCodeList = await getpayTerms(selectedCluster?.cluster_code, data?.margin);
        updateFormFields(paymentTermCodeList, "paymentTermHdr", index);
        break;
    }
  };

  // const checkIfHeadquarterExists = async (data: any, isEdit: boolean) => {
  //   // try {
  //   //   setHeaderQuarterError("");
  //   //   if (!isEdit && data) {
  //   //     const res = await checkHeadquarterStatus(data, addEditFormData?.name?.code);
  //   //     if (res?.status !== 200) throw new Error();
  //   //     if (res?.data?.status === true) {
  //   //       // headquarter already exists
  //   //       setHeaderQuarterError("Headquarter already exists");
  //   //     }
  //   //   }
  //   // } catch (error) {
  //   //   setHeaderQuarterError("");
  //   // }
  // };

  // Render the SmartForm component
  return (
    <aside className="relative grid grid-cols-1 py-6 md:grid-cols-2 gap-y-4 gap-x-10">
      <div
        className={`justify-between flex-row-reverse md:flex-row md:absolute flex flex-wrap items-center top-4 ${
          FORM_FIELDS?.length > 1 ? "left-36" : "left-64"
        }`}
      >
        {FORM_FIELDS?.length > 1 && (
          <Button onClick={() => handleRemoveDivision(0)} className="text-xs font-medium text-red-500 ">
            - Remove Cluster
          </Button>
        )}
        <Button className=" text-[#5E3CEC] focus:ring-0  left-64 top-4 text-xs font-medium " onClick={handleAddDivision}>
          + Add Cluster
        </Button>
      </div>

      {FORM_FIELDS?.map((formfield: any[], index: number) => {
        return (
          <>
            {index > 0 && (
              <div className="relative w-full md:col-span-2">
                <Divider />
                <Button
                  onClick={() => handleRemoveDivision(index)}
                  className="absolute right-0 md:right-[52%] text-xs font-medium text-red-500 top-2"
                >
                  - Remove Cluster
                </Button>
              </div>
            )}
            {formfield?.map(({ label, type, id, keyofForm, suggestionList, displayKey, uniqueKey, disabled, additionalfield }: any) => {
              switch (type?.toString().toLocaleLowerCase().trim()) {
                case "autocomplete":
                  return (
                    <Fragment key={id}>
                      <div className="w-[363px]">
                        <SmartForm.Autocomplete
                          suggestionList={suggestionList || []}
                          displayKey={displayKey || ""}
                          fieldName={keyofForm}
                          placeholder={""}
                          label={label}
                          className=""
                          disabled={disabled ? true : false}
                          uniqueKey={uniqueKey || ""}
                          isControlled={true}
                          value={
                            addEditFormData?.[keyofForm]
                              ? typeof addEditFormData[keyofForm] === "object"
                                ? addEditFormData?.[keyofForm]?.[displayKey]
                                : addEditFormData?.[keyofForm]
                              : ""
                          }
                          onChange={(data) => {
                            dispatch(stockistActions.setFormData({ keyofForm, value: data }));
                            autocompleteHandler(data, keyofForm, index);
                          }}
                          additionalfield={additionalfield}
                        />
                      </div>
                    </Fragment>
                  );

                default:
                  return (
                    <SmartForm.Input
                      type={type}
                      fieldName={keyofForm}
                      placeholder=""
                      label={label}
                      disabled={disabled ? true : false}
                      key={id}
                      blurHandler={(data, keyofForm) => dispatch(stockistActions.setFormData({ keyofForm, value: data }))}
                      className="w-[363px] mr-auto"
                    />
                  );
              }
            })}
          </>
        );
      })}
    </aside>
  );
};

// Define the Divider component
const Divider = () => <div className="h-[0.0625rem] w-[95%] bg-[#00000033] mx-auto" />;

// Export the StockistCluster component
export default StockistCluster;
