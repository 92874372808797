/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Company {
  code: number;
  name: string;
  shortcode: string;
  shortname: string;
  status: boolean;
  uid: string;
  created_by: string;
  created_date: string;
  modified_by: string;
  allow_placeOrder: number;
  modified_date: string;
}

export interface BulkUploadState {
  companyList: any[];
  selectedCompany: Company;
  selectedFunctionality: any | null | undefined;
  uploadedFile: FileList | null;
  url: string;
  isDataValid: boolean;
  divisionList: Array<number>;
}

const initialState: BulkUploadState = {
  companyList: [],
  selectedCompany: {
    code: 0,
    name: "",
    shortcode: "",
    shortname: "",
    status: false,
    uid: "",
    created_by: "",
    created_date: "",
    modified_by: "",
    allow_placeOrder: 0,
    modified_date: "",
  },
  divisionList: new Array<number>(),
  selectedFunctionality: null,
  uploadedFile: null,
  url: "",
  isDataValid: false,
};

const bulkUploadSlice = createSlice({
  name: "bulkUpload",
  initialState,
  reducers: {
    setSelectedCompany(state, action: PayloadAction<Company>) {
      state.selectedCompany = action.payload;
    },
    setSelectedFunctionality(state, action: PayloadAction<any | null | undefined>) {
      state.selectedFunctionality = action.payload;
    },
    setUploadedFile(state, action: PayloadAction<any>) {
      state.uploadedFile = action.payload;
    },
    setCompanyList(state, action: PayloadAction<any[]>) {
      state.companyList = action.payload;
    },
    setUrl(state, action: PayloadAction<string>) {
      state.url = action.payload;
    },
    setDivisionList(state, action: PayloadAction<Array<number>>) {
      state.divisionList = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    updateDataValidity(state) {
      state.isDataValid = !!state.selectedCompany && !!state.selectedFunctionality;
    },
    // Add additional actions for other properties if needed
  },
});

export const bulkUploadActions = bulkUploadSlice.actions;
export { bulkUploadSlice };
