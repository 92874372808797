import { Transition } from "@headlessui/react";

interface FormErrorProps {
  message: any;
  show: boolean;
  type: string;
  label: string;
}

const ErrorMessage = ({ message, show, type, label }: FormErrorProps) => {
  // console.log("first", message, show, type, label);
  return (
    <Transition
      show={show}
      enter="transition duration-300 ease-out duration-500"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out duration-500"
      leaveFrom="transform scale-300 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      {(() => {
        switch (type) {
          case "integer":
            return <p className="px-2 py-1 text-sm text-red-600">{label ? `${label} must be a number` : "This field must be a number"}</p>;
          case "email":
            return <p className="px-2 py-1 text-sm text-red-600">{label ? `${label} must be an email` : "This field must be an email"}</p>;

          case "optionality":
            return <p className="px-2 py-1 text-sm text-red-600">{label ? `${label} is required` : "This field is required"}.</p>;

          case "typeError":
            return (
              <p className="px-2 py-1 text-sm text-red-600">
                {label ? label : "This field"} must be {message?.split(" ")[4]?.toString()}
              </p>
            );
          case "required":
            return <p className="px-2 py-1 text-sm text-red-600">{label ? label : "This field"} is required.</p>;
          case "max":
            return (
              <p className="px-2 py-1 text-sm text-red-600">
                {label ? label : "This field"} must not exceed {message && message?.match(/\d+/) ? message?.match(/\d+/)[0] : ""} characters.
              </p>
            );
          // case "min":
          //   return (
          //     <p className="px-2 py-1 text-sm text-red-600">
          //       {message?.includes("positive number") && `${label || "This field"} must be a positive number`}
          //       {!message?.includes("positive number") &&
          //         `${label || "This field"} must be at least ${message && message?.match(/\d+/)?.[0]} characters.`}
          //     </p>
          //   );
          default:
            return <p className="px-2 py-1 text-sm text-red-600">{message}</p>;
        }
      })()}
    </Transition>
  );
};

export default ErrorMessage;
