import CheckCirlceIcon from "../assets/check-circle.svg";
import Button from "../components/Button";
import Modal from "../components/Modal";
import AlertIcon from "../assets/alert-circle.svg";

interface SuccessModalProps {
  popupMessage: any;
  showForm: boolean;
  onContinue: () => void;
  variant?: string;
}

const FormSuccess: React.FC<SuccessModalProps> = ({ popupMessage, showForm, onContinue, variant = "default" }) => (
  <Modal isOpen={showForm} onCloseModal={onContinue} isHeader={false} title="Create a counter">
    <div className="p-6 text-center w-[25rem] shadow-md">
      {variant === "default" && (
        <div className="inline-flex items-center justify-center rounded-full p-2 mb-4 bg-[#ECFDF3]">
          <div className="p-2 bg-[#D1FADF] flex items-center justify-center rounded-full">
            <img src={CheckCirlceIcon} alt="check-circle" width={24} height={24} />
          </div>
        </div>
      )}
      {variant === "error" && (
        <div className="bg-[#fef3f2] mx-auto h-12 w-12 flex items-center rounded-full p-2">
          <div className="p-2 rounded-full flex items-center bg-[#fee4e2]">
            <img src={AlertIcon} alt="alert" width={24} height={24} />
          </div>
        </div>
      )}
      <h2 className="text-[#101828] font-semibold text-lg mb-2 text-center">{popupMessage?.title || "DRNT created successfully!"}</h2>
      <p className="text-[#667085] font-normal text-sm text-center">{popupMessage?.description}</p>
      <Button onClick={onContinue} className="bg-[#586AF5] mt-8 text-white rounded-lg w-full">
        Continue
      </Button>
    </div>
  </Modal>
);

export default FormSuccess;
