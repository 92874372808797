import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Modal from "../components/Modal";
import SmartForm from "../components/SmartForm";
import Spinner from "../components/Spinner";
import { getCookieValue } from "../constants/global-const";
import { craActions } from "../context/cra-slice";
import { RootState } from "../context/store";
import { useToast } from "../hooks/useToast";
import {
  getFormFields,
  postCreateCounterRequest,
  postUploadToS3,
  putCreateCounterRequest,
  validateUin
} from "../services/cda-api";
import { getCounterSpeciality } from "../services/counter-api";
import { getAllCityDetails } from "../services/product-api";
import { getDoctorFields } from "./DoctorFieldCounterForm";
import FormSuccess from "./FormSuccess";
import UinDetails from "./uin-details-form.component";

const autocompleteMap: any = {
  city: {
    fn: getAllCityDetails,
    uniqueKey: "city_code",
    displayKey: "city_name",
    bindingKey: "city_code",
  },
  speciality: {
    fn: getCounterSpeciality,
    displayKey: "speciality_desc",
    uniqueKey: "speciality_id",
    bindingKey: "speciality_id",
  },
};

const CounterCreateForm = ({
  type,
  selectedDivision,
  onCancel,
  onFormSubmit,
  isAdoptForm,
  lowestEmployee,
  isImportForm = false,
  postPerson,
  importCreqCode,
}: {
  type: any;
  selectedDivision: any;
  onCancel?: any;
  onFormSubmit?: any;
  isAdoptForm: Boolean;
  lowestEmployee?: any;
  isImportForm?: boolean;
  postPerson?: any;
  importCreqCode?: string;
}) => {
  // const [formFields, setFormFields] = useState<any>([]);
  const { toast } = useToast();
  const { formDefaultValues = {}, editMode, counter_company_location_id, adoptCounterCode } = useSelector((state: RootState) => state.cra);
  const [uin, setUin] = useState("");
  const [altUin, setAltUin] = useState("");
  const { currentPsr, isAdmin } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [validationResponse, setValidationResponse] = useState<any>(null);
  const [uinDraftData, setUinDraftData] = useState<any>(null);
  // const [userUcode, setUserUcode] = useState<string>("");
  const [uinVerified, setUinVerified] = useState<boolean>(false);
  const [isUinDisabled, setIsUinDisabled] = useState<boolean>(false);
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const [creqId, setCreqId] = useState("");
  const [normalFields, setNormalFields] = useState<any>([]);
  const [pharmacyFields, setPharmacyFields] = useState<any>([]);

  const smartFormInstance = useForm({
    resolver: undefined,
    mode: "onBlur",
    defaultValues: structuredClone(formDefaultValues),
  });

  function validateForm(): boolean {
    const values = smartFormInstance.getValues();

    const fieldNames: Record<string, string> = {
      gst_number: "GST Number",
      drug_license_number: "Drug License Number",
      mci_register_number: "MCI Register Number",
      uin_code: "UIN Code",
      mobile_no: "Mobile number",
      associated_dr_mobile_number: "Associated doctor mobile number",
      pharmacy_mobile_no: "Pharmacy mobile number",
    };

    const equalPairs: string[] = [];

    const checkEquality = (field1: string, field2: string): void => {
      if (values[field1] && values[field2] && values[field1] === values[field2]) {
        equalPairs.push(`${fieldNames[field1]} and ${fieldNames[field2]}`);
      }
    };

    checkEquality("gst_number", "drug_license_number");
    checkEquality("gst_number", "mci_register_number");
    checkEquality("gst_number", "uin_code");
    checkEquality("drug_license_number", "mci_register_number");
    checkEquality("drug_license_number", "uin_code");
    checkEquality("mci_register_number", "uin_code");
    checkEquality("mobile_no", "associated_dr_mobile_number");
    checkEquality("mobile_no", "pharmacy_mobile_no");
    checkEquality("associated_dr_mobile_number", "pharmacy_mobile_no");

    if (equalPairs.length > 0) {
      const description =
        equalPairs.length === 1
          ? `${equalPairs[0]} should not be the same.`
          : `The following values should not be the same: ${equalPairs.join(", ")}.`;

      toast({
        description: description,
        variant: "destructive",
      });
      return false;
    }

    // If all validations pass
    return true;
  }

  function getDiffFields(defaultValues: any, formData: any, formFields: any): string {
    // debugger;
    let diffFields: string = "Changes Detected: \n";

    for (const formKey in formData) {
      const formField = formFields.find((field: any) => field?.bindingKey == formKey);
      const label = formField?.field_name;

      if (formData[formKey] !== defaultValues[formKey] && label) {
        if (formKey === 'remarks') continue;

        if (
          formKey.toLowerCase().includes("speciality") ||
          (formKey.toLowerCase().includes("city") && formField?.inputType?.toString()?.toLocaleLowerCase()?.trim() === "dropdown")
        ) {
          const displayKey = autoCompleteTypeHandler(label)?.displayKey;
          const defaultVal = defaultValues[formKey]?.[displayKey];
          const currentVal = formData[formKey]?.[displayKey];
          if (defaultVal === currentVal) continue;
          diffFields += `${label} is changed from ${defaultVal} to ${currentVal} \n`;
        } else {
          diffFields += `${label} is changed from ${defaultValues[formKey]} to ${formData[formKey]} \n`;
        }
      }
    }
    return diffFields;
  }

  const showToastIfMandatoryFieldsAreNull = (formData: any): string => {
    if(!isAdoptForm) return "";
    const mandatoryFields = [...normalFields, ...pharmacyFields]?.filter((field: any) => field?.is_mandatory && !formData[field?.bindingKey]);
    if (mandatoryFields?.length > 0) {
      return "This counter cannot be adopted. Please contact MedVol Support.";
    }
    return "";
  };

  async function handleFormSubmission(formData: any) {
    setIsLoading(true);
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    try {
      let formObj = formData?.data || formData;
      const toastMessage = showToastIfMandatoryFieldsAreNull(formObj);
      if (toastMessage) {
        toast({
          description: toastMessage,
          variant: "destructive",
        });
        setIsLoading(false);
        return;
      }
      
      formObj.remarks = isImportForm ? `User Message: ${formObj.remarks} \n This Counter is re-drafted from ${importCreqCode} \n ${getDiffFields(formDefaultValues, formObj, [...normalFields, ...pharmacyFields])}` : formObj.remarks;

      Object.keys(formObj).forEach((key) => {
        if (key?.toString()?.toLowerCase()?.includes("city")) {
          formObj[key] = Number(formObj[key]?.city_code) ?? null;
        }
        if (key?.toString()?.toLowerCase()?.includes("speciality")) {
          formObj[key] = Number(formObj[key]?.speciality_id) ?? null;
        }
        // Handle specific keys for mobile numbers
        const mobileKeys = ["associated_dr_mobile_number", "mobile_no", "pharmacy_mobile_no"];
        if (mobileKeys.includes(key)) {
          formObj[key] = formObj[key] !== "" ? formObj[key] : null;
        }
      });

      formObj.drph_lnk_type = type?.drph_lnk_type_id;
      formObj.company_division_code = typeof selectedDivision === "object" ? selectedDivision?.company_divisioncode?.toString() : selectedDivision;
      formObj.request_type = isImportForm ? "IMPORTED" : isAdoptForm ? "ADOPTED" : "NEW";

      const resObj: any = {
        counterRequestInfo: lowestEmployee ? { ...formObj, On_Behalf_PositionCode: lowestEmployee?.emp_position_hdr?.position_code } : formObj,
        attachment_detail: [],
        company_code: isAdmin ? postPerson?.company_code?.toString() + "" : currentPsr?.company_code?.toString() + "",
      };

      // Check if formData contains fileData
      if (formData?.fileData) {
        // Get keys from formData.fileData
        const keys = Object.keys(formData?.fileData);
        // Iterate over keys
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          // Get files for each key
          const files = formData?.fileData[key];

          // Iterate over files
          for (let i = 0; i < files.length; i++) {
            if ("upload_path" in files[i]) {
              resObj.attachment_detail.push(files[i]);
              continue;
            }
            // Create new FormData instance and append file
            const uploadFiledata = new FormData();
            uploadFiledata.append("file", files[i]);
            // Post file to S3
            const res = await postUploadToS3(uploadFiledata);
            // If response status is not 200, throw error
            if (res?.status !== 200) throw new Error();

            // Initialize attachment_detail array if it doesn't exist
            resObj.attachment_detail = resObj.attachment_detail || [];
            // Push new attachment detail object to array
            resObj.attachment_detail.push({
              counterDraftCompanyConfig: key,
              serial_no: index + 1,
              upload_path: res?.data?.message[0],
              uploaded_by: 123,
            });
          }
        }
      }

      if (!editMode) {
        resObj.position_code = isAdmin ? postPerson?.emp_position_hdr?.position_code : currentPsr?.emp_position_hdr?.position_code;
        resObj.division_code = selectedDivision?.company_divisioncode?.toString();
        resObj.drph_lnk_type_name = type?.dr_ph_lnk_code;
        resObj.counterRequestInfo.request_creator_position_code = isAdmin
          ? postPerson?.emp_position_hdr?.position_code
          : currentPsr?.emp_position_hdr?.position_code;
        resObj.counterRequestInfo.request_creator_division_code = selectedDivision?.company_divisioncode?.toString();
      }
      resObj.counterRequestInfo.uin_code = validationResponse?.CompanyUINDetails?.Counter_UIN || null;
      resObj.counterRequestInfo.company_alternate_uin = validationResponse?.CompanyUINDetails?.Counter_AlternateUIN || null;
      resObj.counterRequestInfo.uin_state = validationResponse?.CompanyUINDetails?.State || null;
      resObj.counterRequestInfo.uin_city = validationResponse?.CompanyUINDetails?.City || null;
      resObj.counterRequestInfo.uin_division = validationResponse?.CompanyUINDetails?.Division_Speciality || null;
      resObj.counterRequestInfo.uin_speciality = validationResponse?.CompanyUINDetails?.UIN_Speciality || null;
      // resObj.counterRequestInfo.uin_counter_name = validationResponse?.CompanyUINDetails?.Counter_Name || null;
      resObj.counterRequestInfo.uin_doctor_name = validationResponse?.CompanyUINDetails?.Counter_Name || null;

      if (isAdoptForm) {
        resObj.counter_company_location_id = counter_company_location_id;
        resObj.counterRequestInfo.LnkCounterCode = adoptCounterCode;
      }

      delete resObj?.counterRequestInfo?.counterRequestAttachmentDtl;

      const res = editMode
        ? await putCreateCounterRequest(resObj, resObj?.counterRequestInfo?.counterRequest_dtl_id)
        : await postCreateCounterRequest(resObj);

      if (res?.status !== 200) throw new Error();
      setIsLoading(false);
      window.sessionStorage.setItem("requestId", res?.data?.eventCreated?.data?.counterRequestInfo?.counterRequest_dtl_id);
      if (!editMode) {
        setCreqId(`CREQ${new Date().getFullYear()}${res?.data?.eventCreated?.data?.counterRequestInfo?.counterRequest_dtl_id}`);
        setSuccessForm(true);
      } else {
        dispatch(craActions.setFormDefaultValues({}));
        dispatch(craActions.setSelectedData({}));
        navigate("/request-approval/cra/details");
        if (onFormSubmit) onFormSubmit();
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: "Error updating counter",
        variant: "destructive",
      });
      console.log("Error creating submission");
      onCancel();
    }
  }

  useEffect(() => {
    if ([...normalFields, ...pharmacyFields]?.find((field: any) => field?.bindingKey === "company_alternate_uin")) {
      setIsUinDisabled(true);
      smartFormInstance.reset({ ...smartFormInstance.getValues() });
    }
  }, [normalFields, pharmacyFields]);

  useEffect(() => {
    async function fetchFormFields() {
      try {
        setIsLoading(true);
        const currenDivision = typeof selectedDivision === "object" ? selectedDivision?.company_divisioncode : selectedDivision;
        const emp_uid = lowestEmployee?.uid;

        const res = await getFormFields(type?.drph_lnk_type_id, currenDivision, emp_uid, isAdoptForm ? "ADOPTED" : "");
        if (res?.status !== 200) throw new Error();
        const pharmacyFields: any[] = [];
        const normalFields: any[] = [];
        res?.data?.forEach((field: any) => {
          if (field?.ispharmacyfield) {
            pharmacyFields.push(field);
          } else {
            normalFields.push(field);
          }
        });

        setNormalFields(normalFields);
        setPharmacyFields(pharmacyFields);

        if (isImportForm) {
          // from formdata only keep the fields which are present in the formfields
          const formData = structuredClone(formDefaultValues);
          const formDataKeys = Object.keys(formData);
          const formFieldsKeys = res?.data?.map((field: any) => field?.bindingKey);
          const filteredFormData = formDataKeys.reduce((acc: any, key: string) => {
            if (formFieldsKeys.includes(key)) {
              acc[key] = formData[key];
            }
            return acc;
          }, {});
          filteredFormData.counterRequestAttachmentDtl = formDefaultValues?.counterRequestAttachmentDtl;
          delete filteredFormData?.counterRequest_dtl_id;
          delete filteredFormData?.counterRequest_status;
          dispatch(craActions.setFormDefaultValues(filteredFormData));
          smartFormInstance.reset(filteredFormData);
        }
      } catch (error) {
        toast({
          variant: "destructive",
          description: "Unable to fetch form fields",
        });
        onCancel();
      } finally {
        setIsLoading(false);
      }
    }

    fetchFormFields();
  }, [isImportForm]);

  // in case of edit add uin and alt uin to the form
  useEffect(() => {
    if (editMode) {
      setUin(formDefaultValues?.uin_code);
      setAltUin(formDefaultValues?.company_alternate_uin);
    }
  }, [editMode]);

  function autoCompleteTypeHandler(label: string): any {
    const labelArray = label?.toString()?.toLowerCase().split("_");

    const itemFound: any = Object.keys(autocompleteMap).find((item) => labelArray.includes(item));

    return autocompleteMap?.[itemFound];
  }

  // const checkUINValidation = async () => {
  //   const token: any = await getCookieValue("idToken");
  //   let data = JSON.stringify({
  //     user_ucode: token?.["custom:ucode"],
  //     Webusertokenpassword: "B6uYT/9GssMbPd59c3sndRW1VPZyShW6BxGu2sBYy9Y=",
  //   });

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://testappsapigw.medvol.in/api/WebUserTokenGenerate",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   const credentialsRes = await axios.request(config);
  //   if (credentialsRes?.status !== 200) {
  //     toast({
  //       description: "Invalid credentials",
  //       variant: "destructive",
  //     });
  //   }

  //   if (credentialsRes?.status === 200) {
  //     setUserUcode(credentialsRes?.data?.User_Ucode);
  //   }
  // };

  const checkUIN = async (data: any) => {
    // console.log("lowestEmployee", lowestEmployee);
    // console.log("uin", uin);
    // debugger;
    const token: any = await getCookieValue("idToken");
    const decodedIdtoken: any = await jwtDecode(token?.toString() as string);
    // console.log("decodedIdtoken", decodedIdtoken);
    const body = {
      UserUCode: decodedIdtoken?.["custom:ucode"],
      Company_UIN: altUin || uin,
      Company_DivisionCode: String(selectedDivision?.company_divisioncode),
      PSR_UCode: lowestEmployee?.uid,
    };
    // console.log("body", body);
    // console.log("token", token);
    setIsLoading(true);
    validateUin(body, token)
      .then((res: any) => {
        setValidationResponse(res?.data);
        if (res?.data?.status === true) {
          setIsDetailsOpen(true);
          setUinDraftData(data);
          setIsLoading(false);
          if (altUin && res?.data?.CompanyUINDetails?.Counter_UIN) {
            // console.log("res?.data?.CompanyUINDetails?.Counter_UIN", res?.data?.CompanyUINDetails?.Counter_UIN);
            smartFormInstance.setValue("uin_code", res?.data?.CompanyUINDetails?.Counter_UIN);
          }
        } else {
          toast({
            description: res?.data?.message,
            variant: "destructive",
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const attachmentRequired = [...normalFields, ...pharmacyFields]?.filter((field: any) => {
    if (field.is_attachment) {
      return {
        field: field?.bindingKey,
        counterDraft_companyConfig_id: field?.counterDraft_companyConfig_id,
      };
    }
    return false;
  });

  return (
    <>
      <div className="w-[50vw]">
        {isLoading && (
          <div className="flex items-center justify-center my-3">
            <Spinner />
          </div>
        )}
        <SmartForm
          isControlled={false}
          filesDefaultValues={formDefaultValues?.counterRequestAttachmentDtl?.length > 0 ? [...formDefaultValues?.counterRequestAttachmentDtl] : []}
          defaultValues={structuredClone(formDefaultValues)}
          onFormSubmit={(formData: any) => {
            if ((uin || altUin) && !uinVerified) {
              checkUIN(formData);
            } else {
              handleFormSubmission(formData);
            }
          }}
          formInstance={smartFormInstance}
          attachmentRequired={attachmentRequired}
        >
          <h1 className="text-[#BBBBBC] font-semibold text-xs leading-3 uppercase tracking-[2px] mt-5  px-7">Doctor Details</h1>
          <section className="grid grid-cols-1 px-5 py-3 md:grid-cols-2 gap-x-5 gap-y-2">
            {getDoctorFields({
              formFields: normalFields,
              isAdoptForm,
              autoCompleteTypeHandler,
              setUinVerified,
              setUin,
              setAltUin,
              uinVerified,
              isUinDisabled,
              isImportForm,
            })}
          </section>

          {pharmacyFields?.length > 0 && (
            <h1 className="text-[#BBBBBC] font-semibold text-xs leading-3 uppercase tracking-[2px] mt-5  px-7">Pharmacy details</h1>
          )}

          <section className="grid grid-cols-1 px-5 py-3 md:grid-cols-2 gap-x-5 gap-y-3">
            {getDoctorFields({
              formFields: pharmacyFields,
              isAdoptForm,
              autoCompleteTypeHandler,
              setUinVerified,
              setUin,
              setAltUin,
              uinVerified,
              isUinDisabled,
              isImportForm,
            })}
          </section>

          <footer className="border border-t-[1px] mt-auto flex justify-end px-5 py-3 gap-3 rounded-bl-2xl rounded-br-2xl">
            <Button onClick={onCancel} className="border">
              Cancel
            </Button>
            <Button isLoading={isLoading} loaderSize="small" type="submit" className="text-white bg-[#586AF5]">
              {(uin || altUin) && !uinVerified ? "Verify UIN" : editMode ? "Update" : isAdoptForm ? "Adopt" : "Create"}
            </Button>
          </footer>
        </SmartForm>
        {validationResponse && (
          <Modal
            isOpen={isDetailsOpen}
            isHeader={true}
            title="Confirm UIN details"
            onCloseModal={() => setIsDetailsOpen(false)}
            onBack={() => {
              setIsDetailsOpen(false);
            }}
          >
            {validationResponse ? (
              <UinDetails
                uinData={uinDraftData}
                responseData={validationResponse}
                onBack={() => {
                  // dispatch(
                  //   counterActions.setAddDivisionModalState({
                  //     type: true,
                  //     data: addDivisionModalState?.data || [],
                  //   })
                  // );
                  setIsDetailsOpen(false);
                }}
                onConfirm={() => {
                  setIsDetailsOpen(false);
                  setUinVerified(true);
                }}
              />
            ) : (
              <>{alert("No response of UIN validation found")}</>
            )}
          </Modal>
        )}
      </div>
      <FormSuccess
        showForm={successForm}
        onContinue={() => {
          dispatch(craActions.setFormDefaultValues({}));
          dispatch(craActions.setSelectedData({}));
          navigate("/request-approval/cra/details");
          if (onFormSubmit) onFormSubmit();
        }}
        popupMessage={{
          description: `A new ${type?.dr_ph_lnk_code} counter request has been created for ${creqId}`,
          title: `${type?.dr_ph_lnk_code} created successfully!`,
        }}
      />
    </>
  );
};

export default CounterCreateForm;
