import { Fragment } from "react";
import Button from "./button.component";
import arrowDownIcon from "../../assets/arrow-down.svg";

export function ClientPagination({ table }: { table: any }) {
  const currentPage = table.getState().pagination.pageIndex;
  const totalPageCount = table.getPageCount();

  const generatePageNumbers = (): (number | string)[] => {
    const maxPagesToShow = 5;
    let pages: (number | string)[] = [];

    if (totalPageCount <= maxPagesToShow) {
      pages = Array.from({ length: totalPageCount }, (_, i) => i);
    } else {
      const startPage = Math.max(0, currentPage - 1);
      const endPage = Math.min(totalPageCount - 1, currentPage + 3);

      if (startPage > 0) {
        pages.push(0);
        if (startPage > 1) pages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPageCount - 1) {
        if (endPage < totalPageCount - 2) pages.push("...");
        pages.push(totalPageCount - 1);
      }
    }

    return pages;
  };

  return (
    <div className="flex items-center justify-center mb-1 mt-7">
      <Button
        className="gap-2 text-sm font-medium bg-white border rounded-r-none"
        disabled={currentPage === 0}
        onClick={() => table.previousPage()}
      >
        <img src={arrowDownIcon} className="rotate-90" /> Previous
      </Button>
      {generatePageNumbers().map((pageNumber, index) => (
        <Fragment key={index}>
          {pageNumber === "..." ? (
            <span className="mx-2">...</span>
          ) : (
            <Button
              className={`border rounded-none bg-white gap-2 ${
                currentPage === pageNumber
                  ? "bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700"
                  : "hover:bg-gray-100 hover:text-gray-700"
              }`}
              onClick={() => table.setPageIndex(Number(pageNumber))}
            >
              {Number(pageNumber) + 1}
            </Button>
          )}
        </Fragment>
      ))}
      <Button
        className="gap-2 text-sm font-medium bg-white border rounded-l-none"
        disabled={!table.getCanNextPage()}
        onClick={() => table.nextPage()}
      >
        Next
        <img src={arrowDownIcon} className="-rotate-90 " />
      </Button>
    </div>
  );
}
