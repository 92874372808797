import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { AnyObject } from "yup";
// @ts-ignore
import { parse } from 'url';

export const switchString = (input: string) => {
  switch (input.toLowerCase()) {
    case "company_productcode":
      return "Co. Product Code";
    case "counter_code":
      return "Counter Code";
    case "mci_register_number":
      return "MCI Register No.";
    case "registration_date":
      return "Registeration Code";
    case "clinic_name":
      return "Clinic Name";
    case "counter_name":
      return "Doctor Name";
    case "item_cmpcode":
      return "Co. Item Code";
    case "description":
      return "Description";
    case "name":
      return "Name";
    case "item_factor":
      return "Item Factor";
    case "integral_quantity":
      return "Integral Qty.";
    case "ptr":
      return "PTR";
    case "min_qty":
      return "Min. Qty.";
    case "discountOnPTR_cp":
      return "Co. Discount PTR";
    case "created_by":
      return "Created By";
    case "modified_by":
      return "Modified By";
    case "company_divisions":
      return "Co. Division";
    case "remarks":
      return "Remarks";
    case "company_code":
      return "Co. Code";
    case "modified_date":
      return "Modified Date";
    case "created_date":
      return "Created Date";
    case "gst_pc":
      return "GST ";
    case "price_identifier_cp":
      return "Co. Price Identifier";
    case "mrp":
      return "MRP";
    case "pts":
      return "PTS";
    case "item_type":
      return "Item Type";
    case "item_uom":
      return "Item UOM";
    case "item_molecule":
      return "Item Molecule";
    case "brand":
      return "Brand";
    case "po_itemcode":
      return "Product Item Code";
    case "item_code":
      return "Item Code";
    case "active_status":
      return "Active Status";
    default:
      return input;
  }
};

export const queryParamsGenerator = (searchParams: URLSearchParams) => {
  // this function is used to generate query params for backend
  const queryParams = new URLSearchParams();
  const queryObj: { [key: string]: string } = Object.fromEntries(searchParams);

  const conversionObj: { [key: string]: string } = {
    c: "company_id",
    p: "company_productcode",
    d: "company_divisioncode",
  };

  Object.keys(queryObj).forEach((key) => {
    const code = queryObj[key].split("__")[1];
    queryParams.append(conversionObj[key], code);
  });

  return "?" + queryParams.toString();
};

export const queryProductParamsGenerator = (searchParams: URLSearchParams) => {
  // this function is used to generate query params for backend
  const queryParams = new URLSearchParams();
  const queryObj: { [key: string]: string } = Object.fromEntries(searchParams);

  const conversionObj: { [key: string]: string } = {
    c: "company_id",
    p: "company_productcode",
    // d: "company_divisioncode",
  };

  Object.keys(queryObj).forEach((key) => {
    const code = queryObj[key].split("__")[1];
    queryParams.append(conversionObj[key], code);
  });

  return "?" + queryParams.toString();
};

export const employeeQueryParamsGenerator = (searchParams: any) => {
  // this function is used to generate query params for backend
  const queryParams = new URLSearchParams();
  const queryObj: { [key: string]: string } = Object.fromEntries(searchParams);

  const conversionObj: { [key: string]: string } = {
    c: "company_id",
    psr: "psrcodenumeric_id",
    d: "company_divisioncode",
  };

  Object.keys(queryObj).forEach((key) => {
    const code = queryObj[key].split("__")[1];
    queryParams.append(conversionObj[key], code);
  });

  return "?" + queryParams.toString();
};

// use for combining tailwind classes
export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

export const findQueryNameCode = (arr: string[], target: string, Id: string): string | number => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].includes(target)) {
      const val = arr[i];
      if (Id === "code") {
        return val.split("=")[1];
      } else if (Id === "name") {
        return val.split("=")[0];
      }
      return i; // Return the index if the target string is found
    }
  }
  return -1; // Return -1 if the target string is not found in the array
};

export const constructUrlQueryWithRowData = (rowData: any) => {
  const companyData = rowData.company_code;
  const productData = rowData;
  // const divisionData =
  //   (rowData.company_divisions && rowData.company_divisions[0]) || {};

  const urlParams = [];

  if (companyData && companyData.name && companyData.code) {
    urlParams.push(`c=${companyData.name}__${companyData.code}`);
  }

  if (productData && productData.name && productData.company_productcode) {
    urlParams.push(`p=${productData.name}__${productData.company_productcode}`);
  }

  // if (divisionData.division_name && divisionData.company_divisioncode) {
  //   urlParams.push(
  //     `d=${divisionData.division_name}__${divisionData.company_divisioncode}`,
  //   );
  // }

  if (urlParams.length === 0) {
    return ""; // Return an empty string if there are no valid parameters
  }

  // Construct the URL query string by joining the valid parameters with '&'
  const urlQueryParams = `?${urlParams.join("&")}`;

  return urlQueryParams.replace(/ /g, "");
};

export const parseQueryString = (queryString: string): Record<string, string> => {
  const params: Record<string, string> = {};
  const pairs: string[] = queryString.split("&");

  for (const pair of pairs) {
    const [key, value] = pair.split("=");
    if (key && value) {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  }

  return params;
};

export const createParamObject = (paramString: string | null) => {
  if (!paramString) return {};
  const params = paramString.split("&");

  let paramObject: { [key: string]: string } = {};
  params.forEach((param) => {
    const [key, value] = param.split("=");
    paramObject[key] = value;
  });
  return paramObject;
};

export const flattenObject = (obj: any) => {
  let result: { [key: string]: any } = {};
  for (let key in obj) {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      result = { ...result, ...flattenObject(obj[key]) };
    } else {
      result[key] = obj[key];
    }
  }
  return result;
};

type KeyMap<T> = { [key: string]: keyof T };

export function changeKeys<T>(obj: T, keyMap: KeyMap<T>): T {
  // Ensure obj is an object
  if (typeof obj !== "object" || obj === null) {
    throw new Error("Invalid input. Please provide a valid object.");
  }

  // Ensure keyMap is an object
  if (typeof keyMap !== "object" || keyMap === null) {
    throw new Error("Invalid keyMap. Please provide a valid object.");
  }

  // Create a new object with updated keys
  const updatedObj: Partial<T> = {};
  for (const oldKey in obj) {
    if (obj.hasOwnProperty(oldKey)) {
      const newKey = keyMap[oldKey] || oldKey; // Use the new key if specified, otherwise keep the old key
      updatedObj[newKey] = obj[oldKey];
    }
  }

  return updatedObj as T;
}

export const productModalDataTransform = (data: any): any => {
  const keyMap = {
    Priceldentifier_Id: "priceldentifier",
    DiscountOnPtr: "discountonptr_cp",
    FixedPrice: "fixedprice_cp",
    SchemeQty: "schemeqty_cp",
    FreeQty: "freeqty_cp",
    Min_Order_Qty: "min_qty",
    Max_Order_Qty: "max_qty",
    Period: "periodtype",
    PeriodLimit: "quarterly_qty",
    companyProduct: "companyProducts",
    company_divisioncode: "company_divisioncode",
    integral_quantity: "int_qty",
  };
  const updatedObj = changeKeys(structuredClone(data), keyMap);

  updatedObj.division_details = data?.counterProductRelDraft?.company_division_code;

  return updatedObj;
};

export const isFieldRequired = (fieldName: string, schema: any): boolean => {
  if (!schema) return false;

  try {
    schema.validateSyncAt(fieldName, {});
    return false;
  } catch (error: any) {
    return error.errors?.[0]?.includes("required");
  }
};

/**
 * Removes empty values from an object recursively.
 * @param obj - The object to remove empty values from.
 * @returns The object with empty values removed.
 */
export const removeEmptyValues = (obj: AnyObject): AnyObject => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively call the function for nested objects
      obj[key] = removeEmptyValues(obj[key]);

      // Remove the key if all nested keys contain empty values
      if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
        delete obj[key];
      }
    } else {
      // Remove the key if the value is an empty string, null, undefined, or an empty object
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined || (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0)) {
        delete obj[key];
      }
    }
  }

  return obj;
};

/**
 * Appends the specified codeName values from the items array to a query parameter string.
 *
 * @param items - An array of objects containing key-value pairs.
 * @param paramName - The name of the query parameter.
 * @param codeName - The name of the property in each object that contains the code value.
 * @returns The query parameter string with the appended codeName values, or an empty string if no values are found.
 */
export const appendParams = (items: { [key: string]: string }[], paramName: string, codeName: string) => {
  let params = "";
  items?.forEach((item, index) => {
    params += item[codeName];
    const comma = index === items.length - 1 ? "" : ",";
    params += comma;
  });
  return params ? `${paramName}=${params}` : "";
};

export const getValueFromNestedObject = (obj: any, keyPath: string): any => {
  // Split the keyPath by dots to get individual keys
  const keys: string[] = keyPath.split(".");

  // Use reduce to traverse through each key in the keyPath
  return keys.reduce((value: any, key: string) => {
    // Check if the current value is an object and contains the current key
    return value && typeof value === "object" ? value[key] : undefined;
  }, obj);
};

export const insertElementAtSpecificIndex = (arr: any[], element: any, index: number) => {
  let result = [];

  for (let i = 0; i < arr.length; i++) {
    if (i === index) result.push(element);
    result.push(arr[i]);
  }

  return result;
};

export const gcd: any = (a: any, b: any) => {
  while (b !== 0) {
    let temp = b;
    b = a % b;
    a = temp;
  }
  return a;
};

export function extractFileNameAndExtension(s3Link: string): [string, string] {
  const parsedUrl = parse(s3Link, true);
  const path = parsedUrl.pathname?.replace(/^\//, ''); // Remove leading slash

  const [fileName, extension] = path?.split('.') || [];
  if (extension === path) { // Handle files without extension
    return [path, ''];
  }

  return [fileName, extension];
}