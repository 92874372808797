import { FC, ReactNode, Ref, forwardRef } from "react";
import { cn } from "src/utils/utils";

type Variant = "default" | "error" | "success" | "warning" | "info";

interface BadgeProps {
  className?: string;
  children?: ReactNode;
  variant?: Variant | string | undefined;
}

const Badge: FC<BadgeProps> = forwardRef(({ className, children, variant = "default", ...rest }, ref: Ref<HTMLDivElement>) => {
  const variantStyles = {
    default: "bg-[#E9E9E9] text-gray-700",
    error: "bg-red-100 text-red-700",
    success: "bg-green-100 text-green-700",
    warning: "bg-yellow-100 text-yellow-700",
    info: "bg-purple-100 text-[#4B0082]",
  };

  return (
    <div
      ref={ref}
      {...rest}
      className={cn(`px-2 py-1 m-0 not-italic text-sm line-height-normal font-semibold rounded-[0.625rem] inline-flex justify-center items-center ${className} ${variantStyles[variant as Variant]}`)}
    >
      {children}
    </div>
  );
});

export default Badge;
