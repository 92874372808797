import { createSlice } from "@reduxjs/toolkit";

// Currently under development and not reachable anywhere from the UI.

const initialState = {
  menuList: [
    {
      name: "Dr. Reddy’s",
      percentage: 100,
    },
    {
      name: "Dr. Reddy’s",
      percentage: 100,
    },
    {
      name: "Dr. Reddy’s",
      percentage: 100,
    },
    {
      name: "Dr. Reddy’s",
      percentage: 100,
    },
  ],
  menuVisible: false,
};
export interface ProgressMenuState {
  menuList: [];
  menuVisible: boolean;
}

const floatingMenuSlice = createSlice({
  name: "progress-menu",
  initialState: initialState as ProgressMenuState,
  reducers: {
    setMenuList: (state, action) => {
      state.menuList = action.payload;
    },
    setMenuVisibility: (state, action) => {
      state.menuVisible = action.payload;
    },
  },
});

export const floatingMenuActions = floatingMenuSlice.actions;
export default floatingMenuSlice;
