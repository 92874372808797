/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CounterBulkUploadState {
  companyList: any[];
  selectedData: {
    code: any;
    company_divisioncode: any;
    select_type: any | null | undefined;
    select_add_type: any | null | undefined;
  };
  file: FileList | null;
  url: string;
  isDataValid: boolean;
  divisionList: any[];
}

const initialState: CounterBulkUploadState = {
  companyList: [],
  divisionList: [],
  selectedData: {
    code: null,
    company_divisioncode: null,
    select_type: null,
    select_add_type: null,
  },
  file: null,
  url: "",
  isDataValid: false,
};

const counterBulkUploadSlice = createSlice({
  name: "counterBulkUpload",
  initialState,
  reducers: {
    setSelectedData(state, action: PayloadAction<any>) {
      const { key, value } = action.payload;
      state.selectedData = { ...state.selectedData, [key]: value };
    },
    setUploadedFile(state, action: PayloadAction<FileList | null>) {
      state.file = action.payload;
    },
    setCompanyList(state, action: PayloadAction<any[]>) {
      state.companyList = action.payload;
    },
    setUrl(state, action: PayloadAction<string>) {
      state.url = action.payload;
    },
    setDivisionList(state, action: PayloadAction<[]>) {
      state.divisionList = [{ division_name: "Action doesn't require division", company_divisioncode: -1 }, ...action.payload];
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const counterBulkUploadActions = counterBulkUploadSlice.actions;
export { counterBulkUploadSlice };
