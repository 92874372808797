import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { addAuthHeader } from "src/utils/auth";
import { api } from "../constants/global-const";

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/employee",
});

const eventInstance: AxiosInstance = axios.create({
  baseURL: api + "/event/employee",
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status?: number;
  // Add other properties as needed
}

interface LowerHierarchyProps {
  type: string;
  relation: string;
  user: string;
}
interface UpperHierarchyProps {
  relation: string;
  object: string;
}

export const queryEmployeeDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};

export const addEmployee = async (data: any) => {
  return eventInstance.post("/add", {
    data: { ...data, active_status: "Y" },
    event_type: "EMPLOYEE_ADD",
  });
};

export const editEmployee = async (data: any, primary_key: string | number | null | undefined) => {
  if (!primary_key) throw new Error();
  return eventInstance.put("/edit", {
    data: { ...data, psrcodenumeric_id: primary_key },
    event_type: "EDIT_EMPLOYEE",
    primary_key: primary_key,
  });
};

export const queryUserType = async (companyCode: number | string) => {
  return instance.get("/user-type/details?company_code=" + companyCode);
};

export const getLowerHierarchy = async (record: LowerHierarchyProps): Promise<ApiResponse> => {
  return instance.post(`/hierarchy/lower`, record);
};

export const getUpperHierarchy = async (record: UpperHierarchyProps): Promise<ApiResponse> => {
  return instance.post(`/hierarchy/upper`, record);
};

export const queryEmployeeManager = async (user_type: string | number, company_id: number | string) => {
  return instance.get(`/managers?user_type=${user_type}&company_id=${company_id}`);
};

export const getPositionCodeStatus = (positionCode: string | number) => {
  return instance.get(`/position-code/status?position_code=${positionCode}`);
};

export const deactivateEmployee = async (data: any): Promise<ApiResponse> => {
  return instance.put("/edit", data);
};

export const getLowestPSRDetails = async (position_code: string, division_code: string | number, usertype: string) => {
  return instance.get(`get-lowest-psr-details?position_code=${position_code}&division_code=${division_code}&userType=${usertype}`);
};
