export const getConvertedCraFormField = (formdata: any = {}, type: string, companyCode: string | number) => {
  const foundLocation = formdata?.counter_location?.find((item: any) => item?.drph_lnk_type?.dr_ph_lnk_code === type);
  const foundCompany = foundLocation?.counter_cmpny_location_id?.find((company: any) => company?.company_code == companyCode);

  if (type === "INDPH-PH") {
    return {
      pharmacy_name: formdata?.counter_name,
      pharmacy_mobile_no: foundCompany?.mobile || foundCompany?.mobile_no,
      pharmacy_manager_name: formdata?.manager_name,
      pharmacy_address: foundLocation?.address,
      pharmacy_city_code: foundLocation?.city_code,
      drug_license_number: formdata?.drug_license_number,
      associated_dr_name: foundCompany?.counter_field_force_id?.[0]?.assc_counterhdr_data?.counter_name,
      associated_dr_mobile_number:
        foundCompany?.counter_field_force_id?.[0]?.assc_counterhdr_data?.mobile ||
        foundCompany?.counter_field_force_id?.[0]?.assc_counterhdr_data?.mobile_no,
      gst_number: formdata?.gst_number,
      uin_code: formdata?.uin_code,
      remarks: formdata?.remarks,
    };
  }

  if (type === "INDPH-HS") {
    return {
      pharmacy_name: formdata?.counter_name ?? "",
      counter_name: formdata?.hospital_id?.Hospital_Name ?? "",
      city_code: formdata?.hospital_id?.city_code ?? "",
      company_division_code: { company_code: formdata?.companyName ?? "" },
      pharmacy_city_code: foundLocation?.city_code ?? "",
      drug_license_number: formdata?.drug_license_number ?? "",
      email: formdata?.email ?? null,
      pharmacy_manager_name: formdata?.manager_name ?? "",
      gst_number: formdata?.gst_number ?? "",
      reference_number: formdata?.reference_number ?? "",
      chain_pharmacy: formdata?.chain_pharmacy ?? "",
      address: formdata?.hospital_id?.address ?? "",
      pharmacy_address: foundLocation?.address || "",
      pharmacy_mobile_no: foundCompany?.mobile || foundCompany?.mobile_no || null,
      associated_dr_name: foundCompany?.counter_field_force_id?.[0]?.assc_counterhdr_data?.counter_name,
      associated_dr_mobile_no:
        foundCompany?.counter_field_force_id?.[0]?.assc_counterhdr_data?.mobile ||
        foundCompany?.counter_field_force_id?.[0]?.assc_counterhdr_data?.mobile_no,
    };
  }

  return {
    counter_name: formdata?.counter_name,
    mobile_no: foundCompany?.mobile || foundCompany?.mobile_no,
    mci_register_number: formdata?.mci_register_number,
    clinic_name: foundLocation?.clinic_name,
    address: foundLocation?.address,
    speciality_id: formdata?.speciality_id,
    city_code: foundLocation?.city_code,
    pharmacy_name: foundLocation?.pharmacistData?.counter_name,
    pharmacy_mobile_no:
      foundLocation?.pharmacistData?.counter_location?.[0]?.counter_cmpny_location_id?.[0]?.mobile ||
      foundLocation?.pharmacistData?.counter_location?.[0]?.counter_cmpny_location_id?.[0]?.mobile_no,
    pharmacy_manager_name: foundLocation?.pharmacistData?.manager_name,
    pharmacy_address: foundLocation?.pharmacistData?.counter_location?.[0]?.address,
    pharmacy_city_code: foundLocation?.pharmacistData?.counter_location?.[0]?.city_code,
    drug_license_number: foundLocation?.pharmacistData?.drug_license_number,
    gst_number: foundLocation?.pharmacistData?.gst_number,
    remarks: foundLocation?.pharmacistData?.remarks,
    associated_dr_name: foundLocation?.counter_cmpny_location_id?.[0]?.counter_field_force_id?.[0]?.assc_counterhdr_data?.counter_name,
    associated_dr_mobile_no:
      foundLocation?.counter_cmpny_location_id?.[0]?.counter_field_force_id?.[0]?.assc_counterhdr_data?.mobile ||
      foundLocation?.counter_cmpny_location_id?.[0]?.counter_field_force_id?.[0]?.assc_counterhdr_data?.mobile_no,
  };
};
