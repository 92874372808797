/* eslint-disable @typescript-eslint/no-explicit-any */
export const IdentifyPage = (window: any) => {
  console.log("location", window.location.pathname?.split("/")[2]);
  return window.location.pathname?.split("/")[2];
};

export const flattenObject = (obj: any) => {
  let result: { [key: string]: any } = {};
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
      result = { ...result, ...flattenObject(obj[key]) };
    } else {
      result[key] = obj[key];
    }
  }
  return result;
};

export const getCookieValue = (cookieName: string): string | null => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
};

export const getSessionData = (key: string | any): string | null => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const generateUniqueID = (): string => {
  // Get the current timestamp in milliseconds
  let timestamp = Date.now();

  // Generate a unique identifier
  let uniqueIdentifier = Math.random().toString(36).substr(2);

  // Combine the timestamp and unique identifier to create a unique ID
  let uniqueID = timestamp.toString() + uniqueIdentifier;

  // Return the unique ID
  return uniqueID;
};

/**
 * This function extracts unique values from an array of objects based on a specified identifier and extraction key.
 * It first maps through the array to create a new array of key-value pairs, where the key is derived from the object's
 * `company_data` property using the `identifier`, and the value is obtained using the `extractionKey`.
 * Then, it creates a Map from these key-value pairs to ensure uniqueness based on the key (identifier).
 * Finally, it converts the Map's values back into an array and returns it. This results in an array of unique values
 * based on the specified `extractionKey`.
 *
 * @param {Array} array - The array of objects to extract values from.
 * @param {String} identifier - The property within `company_data` to use as a key for uniqueness.
 * @param {String} extractionKey - The property from which to extract the value.
 * @returns {Array} An array of unique values extracted based on the `extractionKey`.
 */
// export const extractArrayValue = (array: any[], extractionKey: string, additionalKeys?: string[], identifier?: string) => {
//   return Array.from(
//     new Map(
//       array.map((item: any) => {
//         const key = identifier ? item?.[extractionKey]?.[identifier] : undefined;
//         const value = additionalKeys
//           ? { ...item?.[extractionKey], ...additionalKeys?.reduce((acc, key) => ({ ...acc, [key]: item?.[key] }), {}) }
//           : item?.[extractionKey];
//         return [key, value];
//       })
//     ).values()
//   );
// };
export const extractArrayValue = (array: any[], identifier: string | null, extractionKey: string, additionalKeys: string[]) => {
  if (identifier) {
    return Array.from(
      new Map(
        array.map((item: any) => {
          const key = item?.[extractionKey]?.[identifier];
          let value = item?.[extractionKey];
          additionalKeys.forEach((additionalKey) => {
            if (item.hasOwnProperty(additionalKey)) {
              if (Array.isArray(additionalKey)) {
                additionalKey.forEach((key) => {
                  for (const innerKey in item[key]) {
                    value = { ...value, [innerKey]: item[key][innerKey] };
                  }
                });
              } else {
                value = { ...value, [additionalKey]: item[additionalKey] };
              }
            }
          });
          return [key, value];
        })
      ).values()
    );
  } else {
    return array.map((item: any) => {
      let value = item?.[extractionKey];
      additionalKeys.forEach((additionalKey) => {
        if (item.hasOwnProperty(additionalKey)) {
          value = { ...value, [additionalKey]: item[additionalKey] };
        }
      });
      return value;
    });
  }
};
