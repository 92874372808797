import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Badge, Table } from "src/components";
import Loader from "src/components/ui/loader.component";
import { useToast } from "src/hooks/useToast";
import { getRevokeCounterList } from "src/services/counter-api";

const columnHelper = createColumnHelper();

const RevokedCounter = ({ selectedCompany }: { selectedCompany: any }) => {
  const [revokeList, setRevokeList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function fetchRevokeCounter() {
      setIsLoading(true);
      try {
        const res = await getRevokeCounterList(
          searchParams.get("id") ?? "",
          selectedCompany?.counter_company_location_id,
          selectedCompany?.counter_location?.counter_location_id
        );

        if (res?.status !== 200) throw new Error();

        const transformedData: any[] = [];
        for (let i = 0; i < res?.data?.length; i++) {
          const currentObj = res?.data[i];
          for (let j = 0; j < currentObj?.counter_order_block.length; j++) {
            transformedData.push({
              ...currentObj,
              counter_order_block: currentObj?.counter_order_block[j],
              // divisionData: currentObj?.division_data,
              // company_data: currentObj?.company_data,
              // counter_location: currentObj?.counter_location,
            });
          }
        }
        setRevokeList(transformedData || []);
      } catch (error) {
        toast({
          description: "Not able to fetch revoke counter",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (selectedCompany && Object.keys(selectedCompany)?.length > 0) fetchRevokeCounter();
  }, [selectedCompany]);

  // Metadata for the Blocked/Revoked table
  const blockedRevokedTableMD = [
    // Column for Block dtl id
    columnHelper.accessor("counter_order_block.order_block_dtl_id", {
      cell: (info) => info.getValue(),
      header: () => "Block Detail id",
    }),

    // Column for Block Type
    columnHelper.accessor("counter_order_block.block_type", {
      cell: (info) => info.getValue(),
      header: () => "Block Type",
    }),
    // Column for Block Category
    columnHelper.accessor("counter_order_block.block_remark_id.blocked_remarks_desc", {
      cell: (info) => info.getValue(),
      header: () => "Block Category",
    }),

    // Column for Division name
    columnHelper.accessor("divisionData.division_name", {
      cell: (info) => info.getValue(),
      header: () => "Division Name",
    }),

    // Column for Date & Time
    columnHelper.accessor("counter_order_block.created_date", {
      cell: (info) => (info.getValue() ? <p>{moment(info.getValue()).format("DD/MM/YYYY | h:mm a")}</p> : "-"),

      header: () => "Date & Time",
    }),

    // Column for Date & Time
    columnHelper.accessor("counter_order_block.modified_date", {
      cell: (info) => (info.getValue() ? <p>{moment(info.getValue()).format("DD/MM/YYYY | h:mm a")}</p> : "-"),
      header: () => "Revoked Date & Time",
    }),

    // Column for Revoked Block with a button
    columnHelper.accessor("counter_order_block.revoked_block", {
      cell: () => <Badge variant="success">Revoked</Badge>,
      header: () => "Revoked Block",
    }),
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <Table data={revokeList} columns={blockedRevokedTableMD} isPaginating={false} headClass="bg-[#F8F8F8]" />
    </>
  );
};

export default RevokedCounter;
