import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, ReactNode } from "react";
import ChevronLeft from "src/assets/chevron-left.svg";
import CrossIcon from "src/assets/cross.svg";

interface ModalProps {
  placeholderButton?: string;
  isHeader: boolean;
  isOpen: boolean;
  onCloseModal: (isOpen: boolean) => void;
  onOpenModal?: (isOpen: boolean) => void;
  title?: string;
  children?: ReactNode;
  onBack?: (event: React.MouseEvent) => void;
  zIndex?: number;
  backdropClose?: boolean;
}

const Modal: FC<ModalProps> = ({
  placeholderButton,
  isHeader,
  isOpen,
  children,
  onCloseModal,
  onOpenModal,
  onBack,
  title,
  zIndex = 10,
  backdropClose = false,
}) => {
  const openModalHandler = () => {
    onOpenModal && onOpenModal(true);
  };

  const closeModalHandler = () => {
    onCloseModal(false);
  };

  return (
    <aside>
      <div className={isOpen ? "fixed inset-0 flex items-center justify-center" : ""}>
        <button onClick={openModalHandler} type="button">
          {placeholderButton}
        </button>
      </div>

      {isOpen && (
        <>
          <Transition appear show={isOpen ? true : false} as={Fragment}>
            <Dialog
              as="div"
              style={{
                zIndex: zIndex,
              }}
              className={`relative`}
              onClose={() => {
                if (backdropClose) {
                  closeModalHandler();
                }
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      {isHeader && (
                        <header className="flex rounded-tl-2xl rounded-tr-2xl items-center justify-between px-[1.19rem] py-5 bg-[#7450FA]">
                          <div className="flex items-center gap-[1.19rem]">
                            <div className="p-[0.19rem] cursor-pointer rounded-3xl bg-[#f4f7fa29]">
                              <img onClick={onBack} src={ChevronLeft} alt="Chevron Left" />
                            </div>
                            <h3 className="text-white">{title ? title : "Heading"}</h3>
                          </div>
                          <button onClick={closeModalHandler} className="p-[0.19rem] cursor-pointer bg-[#f4f7fa29] rounded-3xl">
                            <img src={CrossIcon} alt="Cross" />
                          </button>
                        </header>
                      )}
                      {children}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </aside>
  );
};

export default Modal;
