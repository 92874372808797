import Button from "../ui/button.component";
import openFullscreen from "src/assets/open_in_full.svg";
import description from "src/assets/description.svg";


// Currently under development and not reachable anywhere from the UI.

const Divider = () => <div className="h-[0.0625rem] w-full bg-[#00000010] mx-auto" />;

export const FloatingProgressMenu = ({list : menuList = []}): JSX.Element => {
  return (
    <>
      <div className="bg-white shadow-xl rounded-xl flex flex-row items-center justify-center fixed bottom-5 right-5 z-10 max-w-xl">
        <div>
          <header className="flex justify-between px-5 py-3 font-bold text-md rounded-3xl">
            <h2>Uploading File...</h2>
            <Button className="p-0 m-0" onClick={() => {}}>
              <img className="" src={openFullscreen} alt="cross" width={16} height={16} />
            </Button>
          </header>
          <Divider />
          <main className="my-1 gap-4 px-5 pt-2 pb-4">
            {menuList.map((item: { name: string; percentage: number }) => (
<div className="flex h-[33.63px] items-center gap-[12px] relative">
                <img className="relative w-[24px] h-[24px]" alt="Description" src={description} />
                <div className="flex-col items-start justify-center gap-[8px] flex-1 grow flex relative">
                  <div className="items-center justify-between self-stretch w-full flex-[0_0_auto] flex relative">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter-SemiBold',Helvetica] font-semibold text-grey-900 text-[14px] tracking-[-0.14px] leading-[20px] whitespace-nowrap">
                      {item.name}
                    </div>
                    <div className="relative w-fit [font-family:'Inter-SemiBold',Helvetica] font-semibold text-grey-900 text-[13px] tracking-[-0.13px] leading-[16px] whitespace-nowrap">
                      {item.percentage}%
                    </div>
                  </div>
                  <div className="relative self-stretch w-full h-[5.63px]">
                    <div className="relative w-[277px] h-[6px]">
                      <div className="w-[264px] top-px bg-grey-100 absolute h-[5px] left-0 rounded-[5px]" />
                      <div className="w-[277px] top-0 bg-green-500 absolute h-[5px] left-0 rounded-[5px]" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </main>
        </div>
      </div>
    </>
  );
};
