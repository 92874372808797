import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "src/components/ui/toast.component";
import { useToast } from "src/hooks/useToast";
import { createPortal } from "react-dom";

export function Toaster() {
  const { toasts } = useToast();

  return createPortal(
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, duration, ...props }) {
        if (!description?.toString()?.trim()?.length || description === '""' || description === "''") return <></>;
        return (
          <Toast key={id} duration={duration} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription className="select-text">{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>,
    document.body
  );
}