import { Disclosure, Transition } from "@headlessui/react";
import { useState, useEffect } from "react";
import ArrowIcon from "src/assets/chevron-right.svg";
import { Badge, Checkbox, Input } from "src/components";
import { cn } from "src/utils/utils";

interface FilterItem {
  id?: string;
  name?: string;
  code?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add an index signature
}

interface FilterAccordion {
  title: string;
  arrayToFilter: FilterItem[];
  onSelectingFilter?: (value: FilterItem[]) => void;
  displayKey: string;
  uniqueKey: string;
  defaultValue?: FilterItem[];
}

const FilterAccordion = ({
  title,
  arrayToFilter,
  onSelectingFilter,
  displayKey,
  uniqueKey,
  defaultValue,
}: FilterAccordion) => {
  const [query, setQuery] = useState("");
  const [filterLimit, setFilterLimit] = useState(3);
  const [selectedItems, setSelectedItems] = useState<FilterItem[]>(
    defaultValue || [],
  );

  const queryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (!value) setQuery("");
    else setQuery(value);
  };

  const showMoreHandler = () => {
    setFilterLimit(filterLimit + 3);
  };

  const filteredArray = arrayToFilter?.filter((item: FilterItem) =>
    item[displayKey]
      ?.toString()
      .trim()
      .toLowerCase()
      .includes(query.toLowerCase().trim()),
  );

  const filterHandler = (value: FilterItem) => {
    // check if it already exists in state or not

    const updatedSelectedItems = [...selectedItems];

    // if it already exists remove it from state
    const index = updatedSelectedItems.findIndex(
      (item) => item[uniqueKey] === value[uniqueKey],
    );
    if (index !== -1) {
      updatedSelectedItems.splice(index, 1);
    } else {
      // otherwise add it to state
      updatedSelectedItems.push(value);
    }

    setSelectedItems(updatedSelectedItems);
    onSelectingFilter && onSelectingFilter(updatedSelectedItems);
  };

  const removeFilterHandler = (e: any, value: any) => {
    e.stopPropagation();

    const updatedSelectedItems = [
      ...selectedItems.filter((item) => item[uniqueKey] !== value[uniqueKey]),
    ];

    setSelectedItems(updatedSelectedItems);
    onSelectingFilter && onSelectingFilter(updatedSelectedItems);
  };

  useEffect(() => {
    setSelectedItems(defaultValue || []);
  }, [defaultValue]);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button>
            <div className="text-[#49484C] flex justify-between items-center gap-2 font-semibold text-sm">
              <h4 className="flex items-center gap-2">
                {title}
                <Badge className="bg-[#d9cdf4] p-[0.3125rem] w-auto h-auto flex items-center justify-center rounded-full text-[0.625rem] font-semibold text-[#42267f]">
                  {selectedItems?.length || 0}
                </Badge>
              </h4>
              <div className="hover:bg-[#d9cdf4] rounded-full p-2">
                <img
                  src={ArrowIcon}
                  className={cn(
                    `transform ${
                      open ? "-rotate-90" : "rotate-90"
                    } transition duration-300 ease-in-out`,
                  )}
                  alt="arrow down"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-3 mb-2 break-words">
              {selectedItems?.length > 0 &&
                selectedItems.map((item) => (
                  <Badge
                    className="bg-[#D9CDF4] text-[#42267F] font-semibold text-sm py-[0.38rem] py-[0.62rem]"
                    key={item[uniqueKey]}
                  >
                    {item[displayKey]}{" "}
                    <span
                      onClick={(e) => removeFilterHandler(e, item)}
                      className="ml-3"
                    >
                      {" "}
                      X{" "}
                    </span>
                  </Badge>
                ))}
            </div>
          </Disclosure.Button>
          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-300 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel>
              <Input
                showIcon={true}
                placeholder={`Search ${title}`}
                className="w-11/12"
                onChange={queryHandler}
                value={query}
              />
              <h3 className="text-[#BBBBBC] text-[0.625rem] not-italic pt-3 leading-5 uppercase tracking-[0.0875rem]">
                Search results
              </h3>
              {filteredArray?.length === 0 && (
                <p className="text-sm font-normal text-[#767679] text-center">
                  No filters!
                </p>
              )}
              {filteredArray?.slice(0, filterLimit).map((item: FilterItem) => (
                <div
                  key={item[uniqueKey]}
                  className="grid grid-cols-7  mt-3 flex-wrap items-center gap-3"
                >
                  <Checkbox
                    onChange={() => filterHandler(item)}
                    checked={selectedItems?.some(
                      (selectedItem) =>
                        selectedItem[uniqueKey] === item[uniqueKey],
                    )}
                  />
                  <p className="text-[#767679] col-span-6 overflow-hidden text-ellipsis text-sm font-normal ">
                    {item[displayKey]}
                  </p>
                </div>
              ))}
              {filteredArray?.length > 0 &&
                filterLimit <= filteredArray.length && (
                  <button
                    onClick={showMoreHandler}
                    className="inline-block cursor-pointer pt-3 pb-4 text-[#7F56D9] underline font-normal text-xs"
                  >
                    Show more
                  </button>
                )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default FilterAccordion;
