import { Tab } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PurpleLeftArrowIcon from "src/assets/purple-left-arrow.svg";
import { SubHeader } from "src/components";
import { extractArrayValue } from "src/constants/functions";
import { Combobox } from "src/container/components/combobox.";
import { counterActions } from "src/context/counter-slice";
import { RootState } from "src/context/store";
import { getCounterCompanyLinkData, queryCounterDetailsById } from "src/services/counter-api";
import BlockedCounter from "./blocked.component";
import RevokedCounter from "./revoked.component";
import VersioningCrate from "src/components/Versioning/versioningModal.component";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import Book from "../../assets/book.png";
import { versioningActions } from "src/context/versioning-slice";

const tabs: string[] = ["Blocked", "Revoked"];

const BlockedRevoked = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [VersioningModal, setVersioningModal] = useState(false);
  const navigate = useNavigate();

  const { selectedCounterData, counterCompanyList } = useSelector((state: RootState) => state.counter);
  const dispatch = useDispatch();

  const ref = useOutsideClick(() => {
    setVersioningModal((prev) => !prev);
    dispatch(versioningActions.setProductVersioning([]));
  });

  const fetchData = async (query?: string) => {
    try {
      const queryObj: { [key: string]: string } = Object.fromEntries(new URLSearchParams(window.location.search));
      const q = query ? "&" + query : "";
      const response = await queryCounterDetailsById(`?id=${queryObj.id}${q}`);
      if (response.status === 200) {
        const transformedData = structuredClone(response?.data);
        transformedData.mobile = response?.data?.counter_location?.[0]?.counter_cmpny_location_id?.[0]?.mobile || "";
        setSelectedCompany(extractArrayValue(counterCompanyList || [], null, "company_data", ["counter_location", "counter_company_location_id"])[0]);
        dispatch(counterActions.setSelectedCounterData(transformedData));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!counterCompanyList || counterCompanyList?.length === 0 || Object.keys(counterCompanyList)?.length === 0) {
      const fetchCompanyLink = () => {
        const queryObj: { [key: string]: string } = Object.fromEntries(new URLSearchParams(window.location.search));

        getCounterCompanyLinkData(queryObj?.id || selectedCounterData?.counter_id)
          .then((res: any) => {
            dispatch(counterActions.setCounterCompanyList(res.data || []));
            setSelectedCompany(extractArrayValue(res?.data || [], null, "company_data", ["counter_location", "counter_company_location_id"])[0]);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      fetchCompanyLink();
    }

    if (!selectedCounterData?.id) {
      fetchData();
    }
  }, [dispatch]);

  return (
    <>
      <section className="w-full">
        <SubHeader />
        <div
          onClick={() => {
            setVersioningModal((prev) => !prev);
          }}
          className="p-1 py-3 w-[5%] h-auto border-l-2 border-b-2 absolute right-0 "
        >
          <img onClick={() => {}} src={Book} alt="React Logo" className="h-10 w-14" />
        </div>
        {VersioningModal && (
          <div ref={ref} className="px-3 ">
            <VersioningCrate type="Counter" />
          </div>
        )}
        <header className="px-[3.75rem]">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex mt-[1.16rem] gap-3 items-center text-[#7450FA] cursor-pointer text-base font-semibold"
          >
            <img src={PurpleLeftArrowIcon} />
            Back to Doctors
          </button>
          <div className="flex flex-wrap items-center justify-between">
            <p className="text-[2.5rem] line-height-normal font-semibold not-italic">{selectedCounterData?.counter_name}</p>
          </div>

          <div className="flex flex-wrap items-center gap-2">
            <Combobox
              options={extractArrayValue(counterCompanyList, null, "company_data", ["counter_location", "counter_company_location_id"])}
              displayKey="shortname"
              uniqueKey={"code"}
              placeholder={"Select Company"}
              additionalfield="counter_location.location_name"
              defaultValue={
                extractArrayValue(counterCompanyList, null, "company_data", ["counter_location", "counter_company_location_id"])?.[0] ?? {}
              }
              currentVal={(data) => setSelectedCompany(data)}
            />
          </div>
        </header>

        <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
          <Tab.List className="border-b-[1px] border-b-[rgba(0, 0, 0, 0.10)] mb-[1.16rem] px-[3.75rem] mt-6">
            {tabs.map((item: string) => (
              <Tab key={item} as={Fragment}>
                {({ selected }: { selected: boolean }) => (
                  <div
                    className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                      selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                    }`}
                  >
                    {item}
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel className="px-[3.75rem]">
              <BlockedCounter selectedCompany={selectedCompany} onChangeTab={setTabIndex} />
            </Tab.Panel>

            <Tab.Panel className="px-[3.75rem]">
              <RevokedCounter selectedCompany={selectedCompany} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </section>
      {/* <AddToRevokedListModal /> */}
    </>
  );
};

export default BlockedRevoked;
