import axios from "axios";
import { api, getCookieValue } from "../constants/global-const";

class EventEmitter {
  private listeners: { [event: string]: Function[] } = {};

  public on(event: string, listener: Function): void {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(listener);
  }

  public emit(event: string, data?: any): void {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data));
    }
  }

  public off(event: string, listener: Function): void {
    if (this.listeners[event]) {
      const index = this.listeners[event].indexOf(listener);
      if (index > -1) {
        this.listeners[event].splice(index, 1);
      }
    }
  }
}

class ApiBlocker {
  static isEventBlocked: boolean = false;
  static isEventPending: boolean = false;

  static eventChecked = async (streamId?: string) => {
    try {
      if (!streamId) return;

      const res = await axios.get(`${api}/api/v1/common/check-event-status?streamId=${streamId}&consumerName=V2`, {
        headers: {
          Authorization: JSON.stringify({
            authorization: getCookieValue("idToken"),
            refreshToken: getCookieValue("refreshToken"),
          }),
        },
      });
      const response = res?.data;
      ApiBlocker.isEventBlocked = response.isEventBlocked;
      ApiBlocker.isEventPending = response.isEventPending;
      eventEmitterObject.emit("eventBlocked", response.isEventBlocked);
      eventEmitterObject.emit("eventPending", response.isEventPending);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const apiBlockerObject = new ApiBlocker();
const eventEmitterObject = new EventEmitter();
export { EventEmitter, eventEmitterObject, ApiBlocker, apiBlockerObject };
