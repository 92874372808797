import React from "react";

const RestrictedPage: React.FC = () => {

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="mb-4 text-4xl font-bold text-red-600">Access Restricted</h1>
        <p className="mb-8 text-xl text-gray-700">Sorry, you don't have permission to access this page.</p>
      </div>
    </div>
  );
};

export default RestrictedPage;
