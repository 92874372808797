import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export function usePagination() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageSize = searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize") as string, 10) : 10;
  const initialPageIndex = searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex") as string, 10) - 1 : 0; // Adjusting pageIndex to 0-based internally

  const [pagination, setPagination] = useState({
    pageSize: initialPageSize,
    pageIndex: initialPageIndex, // Adjusted to start pageIndex from 0 internally
  });
  const { pageSize, pageIndex } = pagination;

  // Adjust pageIndex to start from 1 for external usage
  const adjustedPageIndex = pageIndex + 1;

  const resetPagination = () => {
    setSearchParams({
      pageSize: pageSize.toString(),
      pageIndex: "1",
    });
    // Adjusted to start pageIndex from 1 for external usage
    setPagination({
      pageSize: initialPageSize,
      pageIndex: 0,
    });
  };

  return {
    pageSize,
    pageIndex: adjustedPageIndex,
    onPaginationChange: setPagination,
    pagination: { pageSize: pageSize, pageIndex: adjustedPageIndex },
    resetPagination,
  };
}
