import { Button, Modal } from "src/components";
import SmartForm from "../smart-form.component";
import { deleteSchema } from "src/constants/counter-master";

interface DeleteModalProps {
  deleteText?: string;
  value: boolean;
  setValue: (value?: any) => void;
  onFormSubmit: (value?: any) => void;
  schema?: any;
  title?: string;
  label?: string;
  fieldName?: string;
  isReferenceNumber?: boolean;
  defaultValues?: any;
  subtitle?: string;
  onBack?: any;
  loading?: boolean;
  positionCodeError?: string;
  positionCodeHandler?: (value: any) => void;
}

function DeleteModal({
  defaultValues = {},
  title,
  deleteText,
  value,
  setValue,
  onFormSubmit,
  schema,
  label,
  fieldName,
  isReferenceNumber = false,
  subtitle,
  onBack,
  loading,
  positionCodeError,
  positionCodeHandler,
}: DeleteModalProps) {
  return (
    <div className="w-11/12 sm:w-10/12 md:w-9/12">
      <Modal
        title={title ?? "Reference Number"}
        isHeader={true}
        isOpen={value}
        onCloseModal={() => {
          setValue(false);
        }}
        onBack={() => {
          if (onBack) onBack();
          else setValue();
        }}
      >
        <div className="p-5">
          <SmartForm defaultValues={structuredClone(defaultValues)} onFormSubmit={onFormSubmit} schema={schema ? schema : deleteSchema}>
            {subtitle && <p className="mb-5 text-sm text-center"> {subtitle}</p>}
            <SmartForm.Input
              placeholder=""
              type="text"
              onKeyUp={
                fieldName === "position_code_name" && positionCodeHandler
                  ? (e: React.KeyboardEvent) => positionCodeHandler((e.target as HTMLInputElement).value)
                  : undefined
              }
              fieldName={fieldName ?? "reference_number"}
              className="w-full"
              label={label ?? "Reference Number"}
            />
            {positionCodeError && <p className="px-2 py-1 text-sm text-red-600">{positionCodeError}</p>}

            {isReferenceNumber && (
              <SmartForm.Input placeholder="" type="text" fieldName={"reference_number"} className="w-full mt-3" label={"Reference Number"} />
            )}
            <footer className="flex items-center justify-between gap-3 mt-8">
              <Button
                onClick={() => {
                  setValue(false);
                }}
                className="border bg-primary text-white flex-1 py-[0.62rem]"
              >
                Cancel
              </Button>
              <Button isLoading={loading} loaderSize="small" type="submit" className="border flex-1  py-[0.62rem]">
                {deleteText ? deleteText : "Update"}
              </Button>
            </footer>
          </SmartForm>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteModal;
