// this component is for admin only

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import { ScrollArea } from "../components/ui/ScrollArea";
import { queryCompanyDetails } from "../services/company-api";
import { cn } from "../utils/utils";

interface CounterCompanySelectProps {
  onSelect: (company: any) => void;
  onCancel: () => void;
}

const CounterCompanySelect = ({ onSelect, onCancel }: CounterCompanySelectProps) => {
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);
  const [companies, setCompanies] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedCompany, setSelectedCompany] = useState<any>({});

  const { toast } = useToast();

  const selectHandler = (value: any) => {
    setSelectedCompany(value);
  };

  const deselectHandler = () => {
    setSelectedCompany({});
  };

  useEffect(() => {
    async function fetchCompanies() {
      try {
        setIsLoading(true);
        const response = await queryCompanyDetails(`details?company_code=${idTokenDetails?.["custom:company"]}`);
        if (response.status !== 200) {
          throw new Error("Failed to fetch companies");
        }
        setCompanies(response.data);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch companies",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (idTokenDetails) {
      fetchCompanies();
    }
  }, [idTokenDetails]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  const filteredCompanies = !searchQuery
    ? structuredClone(companies)
    : companies.filter((company: any) => company?.name?.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Modal isHeader={true} isOpen={true} onCloseModal={onCancel} title="Select Counter Company">
      <div className="p-6 w-[26rem]">
        <header className="flex flex-col mb-8">
          <h1 className="text-[1.525rem] not-italic font-semibold leading-7 ">Select Counter Company</h1>
        </header>

        <div className="mb-3">
          <Input
            onChange={(e: any) => setSearchQuery(e.target.value)}
            placeholder="Search"
            type="search"
            label="Search Company"
            className="w-full py-2"
          />
        </div>
        <div className="flex flex-col gap-3 mb-6">
          <ScrollArea className="overflow-scroll max-h-96 ">
            <div className="flex flex-col max-h-full gap-3 rounded-md">
              {filteredCompanies?.map((company: any) => {
                return (
                  <div
                    key={company.code}
                    className={cn(
                      `${
                        selectedCompany && selectedCompany.code && selectedCompany.code?.toString().includes(company?.code)
                          ? "bg-blue-50 text-blue-700"
                          : ""
                      } px-2 py-3 flex justify-between items-center gap-3 font-semibold border rounded-md`
                    )}
                  >
                    {company.name}
                    <Checkbox
                      checked={selectedCompany?.code?.toString()?.includes(company?.code) ? true : false}
                      onChange={() => {
                        if (Object.keys(selectedCompany).length) {
                          selectedCompany?.code?.toString()?.includes(company?.code) ? deselectHandler() : selectHandler(company);
                        } else selectHandler(company);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollArea>
        </div>

        <footer className="flex items-center justify-between gap-3">
          <Button onClick={onCancel} className="flex-1 border">
            Cancel
          </Button>
          <Button
            disabled={Object.keys(selectedCompany)?.length === 0}
            onClick={() => onSelect(selectedCompany)}
            className="border flex-1 bg-[#586AF5] text-white py-[0.62rem] disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            Confirm
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

export default CounterCompanySelect;
