/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnFiltersState,
  ExpandedState,
  RowSelectionState,
  TableOptions,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Input, Spinner } from "src/components";
import { cn } from "src/utils/utils";
import arrowDownIcon from "../../assets/arrow-down.svg";
import { ClientPagination } from "./client-pagination.component";
import PaginationComponent from "./pagination.component";

interface TableProps {
  columns: any[];
  data: any[];
  onRowClick?: (row: any) => void;
  title?: string;
  subtitle?: string;
  isPaginating?: boolean;
  isGlobalSearch?: boolean;
  searchPlaceholder?: string;
  showheader?: boolean;
  renderSubComponent?: (props: any) => React.ReactElement;
  headClass?: string;
  getRowCanExpand?: (row: any) => boolean;
  loading?: boolean;
  onPaginationChange?: any;
  pagination?: any;
  manualPagination?: boolean;
  pageCount?: number;
  onGlobalSearch?: (value: string) => void;
  getSelectedRows?: any;
  isLoading?: boolean;
  showSearch?: boolean;
  manualSearch?: string;
}

const Table: FC<TableProps> = ({
  columns,
  data,
  onRowClick,
  title,
  subtitle,
  isPaginating = true,
  isGlobalSearch = true,
  searchPlaceholder,
  showheader = true,
  renderSubComponent,
  headClass,
  getRowCanExpand = () => false,
  isLoading = false,
  onPaginationChange = () => {},
  pageCount,
  pagination,
  manualPagination = false,
  onGlobalSearch,
  getSelectedRows,
  showSearch = true,
  manualSearch,
}) => {
  const [columnFilters, setColumnsFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const reactTableProps: TableOptions<any> = {
    data,
    columns,
    enableRowSelection: true,
    manualPagination: manualPagination,
    state: manualPagination
      ? {
          pagination,
          columnFilters,
          rowSelection,
          expanded,
          globalFilter,
        }
      : {
          columnFilters,
          rowSelection,
          expanded,
          globalFilter: manualSearch || globalFilter,
        },
    getRowCanExpand,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: isPaginating ? getPaginationRowModel() : undefined,
    onColumnFiltersChange: setColumnsFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
  };

  if (manualPagination) {
    (reactTableProps["onPaginationChange"] = onPaginationChange), (reactTableProps["pageCount"] = pageCount);
  }

  const table = useReactTable(reactTableProps);

  const filterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.getColumn("item_code")?.setFilterValue(e.target.value);
  };

  const globalFilterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onGlobalSearch) setGlobalFilter(String(e.target.value));
    else onGlobalSearch(String(e.target.value));
  };

  useEffect(() => {
    if (getSelectedRows) getSelectedRows(table.getSelectedRowModel().rows);
  }, [table.getSelectedRowModel()]);

  return (
    <>
      {title && (
        <section className="flex items-center justify-between mt-5 mb-6">
          <div>
            <h4 className="text-2xl font-semibold text-[#49484C]">{title}</h4>
            <p className="text-base font-medium text-[#49484C] opacity-60">{subtitle}</p>
          </div>
          {showSearch && (
            <div className="inline-flex items-center gap-2">
              <Input
                type="input"
                value={globalFilter}
                placeholder={searchPlaceholder || "Search..."}
                onChange={isGlobalSearch ? globalFilterHandler : filterHandler}
                className="px-3  rounded-lg text-base text-[#1B1A1F]"
                showIcon={true}
              />
            </div>
          )}
        </section>
      )}

      {isLoading && (
        <div className="flex justify-center w-full py-5">
          <Spinner />
        </div>
      )}

      {!isLoading && (
        <>
          {" "}
          <div className="relative z-0 bg-white  border rounded-[0.625rem] min-w-[600px] overflow-auto">
            <table className="w-full text-sm text-left text-gray-500 table-auto rounded-[0.625rem]">
              {showheader && (
                <thead className={cn(`pt-4 w-full ${headClass ? headClass : ""}`)}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr className="border-b divide-gray-200" key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          style={{ width: header.column.getSize() || "auto" }}
                          className={`py-3 px-4 text-left text-sm font-normal overflow-hidden text-[0,0,0,0.4] `}
                          key={header.id}
                        >
                          <div className="flex items-center max-w-full gap-2 overflow-hidden">
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                            {header.column.getCanSort() && (
                              <button onClick={header.column.getToggleSortingHandler()}>
                                <img src={arrowDownIcon} alt="sorting" />
                              </button>
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}

              {data?.length === 0 && (
                <td colSpan={table.getAllColumns().length}>
                  <p className="mt-10 mb-10 text-3xl text-center">No data...</p>
                </td>
              )}

              {data && data.length > 0 && (
                <tbody className="bg-white divide-y divide-gray-200 text-[#49484C]">
                  {table.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                      <tr
                        className={`${onRowClick ? "hover:bg-gray-200 cursor-pointer" : ""}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onRowClick) onRowClick(row);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="px-4 py-3 overflow-hidden text-sm not-italic font-semibold text-ellipsis line-height-normal whitespace-nowrap"
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() && renderSubComponent && (
                        <tr>
                          {/* 2nd row is a custom 1 cell row */}
                          <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              )}
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          </div>
          {isPaginating && !manualPagination && <ClientPagination table={table} />}
          {isPaginating && manualPagination ? <PaginationComponent table={table} /> : null}
        </>
      )}
    </>
  );
};

export default Table;
