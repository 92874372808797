import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { addAuthHeader } from "src/utils/auth";
import { api, getCookieValue, logout } from "../constants/global-const";
import { ApiBlocker } from "../utils/eventEmitter";

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/cda",
});

const instance2: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/common",
});

const eventInstance: AxiosInstance = axios.create({
  baseURL: api + "/event/cda",
});

const eventInstance2: AxiosInstance = axios.create({
  baseURL: api + "/event/productRequest",
});

// Add the interceptor to the instance
eventInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403) {
    // logout
    logout();
  }
});

let pendingTimer: any;
const handlePendingState = (config: any, resolve: any, reject: any, attempt = 0) => {
  if (ApiBlocker.isEventPending) {
    if (attempt >= 10) {
      reject(new Error("Maximum retry attempts reached."));
    }
    clearTimeout(pendingTimer); // Clear the previous timer
    pendingTimer = setTimeout(() => {
      const cdaId = window.sessionStorage.getItem("requestId");
      if (cdaId) ApiBlocker.eventChecked(`cda_${cdaId}`);
      handlePendingState(config, resolve, reject, attempt + 1); // Recursively call the function until the event is not pending
    }, 1000); // Wait for 1 second before checking again
  } else {
    resolve(addAuthHeader(config)); // Proceed with the API call if not pending
  }
};

eventInstance.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject): any => {
      if (ApiBlocker.isEventBlocked && window.location.pathname.toString().toLocaleLowerCase().includes("/details")) {
        const errorMessage = "Request cancelled due to event blocked status";
        reject(new Error(errorMessage));
      } else {
        handlePendingState(config, resolve, reject, 1);
      }
    });
  },
  (error) => {
    if (error?.response?.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

// Add the interceptor to the instance
instance.interceptors.response.use(
  (success: any) => {
    const cdaId = window.sessionStorage.getItem("requestId");
    if (window.location.pathname.toString().toLocaleLowerCase().includes("/details")) {
      if (cdaId) ApiBlocker.eventChecked(`cda_${cdaId}`);
    }
    return success;
  },
  (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  }
);

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
instance2.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
eventInstance2.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const getCounterRequestBasedOnPosition = (position_code: string, company_code: string | number) =>
  instance.get(`/counter/request?position_code=${position_code}&company_code=${company_code}`);

export const queryCounterRequestBasedOnPosition = (
  position_code: string,
  company_code: string | number,
  query: string,
  pageIndex?: number,
  orderBy?: any,
  order?: any,
  filtersQuery?: string,
  globalSearch?: string
) => {
  if (pageIndex) {
    if (orderBy && order) {
      return instance.get(
        `/counter/request?position_code=${position_code}&company_code=${company_code}&${query}&page=${pageIndex}&limit=10&orderBy=${orderBy}&order=${order}&${filtersQuery}&search=${globalSearch}`
      );
    } else {
      return instance.get(
        `/counter/request?position_code=${position_code}&company_code=${company_code}&${query}&page=${pageIndex}&limit=10&${filtersQuery}&search=${globalSearch}`
      );
    }
  } else {
    if (orderBy && order) {
      return instance.get(
        `/counter/request?position_code=${position_code}&company_code=${company_code}&${query}&orderBy=${orderBy}&order=${order}&${filtersQuery}&search=${globalSearch}`
      );
    } else {
      return instance.get(
        `/counter/request?position_code=${position_code}&company_code=${company_code}&${query}&${filtersQuery}&search=${globalSearch}`
      );
    }
  }
};

export const getFormFields = (counter_linktypeID: number | string, company_code: string | number, emp_uid: string, creqType: string) =>
  instance.get(
    `/field-to-show?counter_linktype=${counter_linktypeID}&company_divisionCode=${company_code}&emp_uid=${emp_uid}&request_type=${creqType}`
  );

export const getIsActionAllowed = (query: any) => instance.get("is-action-allowed" + query);

export const getPostAllowedLinkTypes = (query: any) => {
  //  axios.get("http://localhost:3001/api/v1/cda/post-access-allowed-link-types" + query);
  return instance.get("post-access-allowed-link-types" + query);
};

export const postCreateCounterRequest = (uploadData: any) =>
  eventInstance.post(
    "/add",
    {
      data: uploadData,
      event_type: "COUNTER_REQUEST_ADD",
    },
    {
      params: {
        version: "v2",
      },
    }
  );

export const putCreateCounterRequest = (data: any, primary_key: string | any) =>
  eventInstance.put(
    "/counter/request/edit",
    {
      data: data,
      event_type: "COUNTER_REQUEST_EDIT",
      primary_key: primary_key,
    },
    {
      params: {
        version: "v2",
      },
    }
  );

export const postUploadToS3 = (data: any) => instance.post("upload-file", data);

export const getCOunterRequestDetailsById = (data: any, isCounter = true) => {
  if (isCounter) {
    return instance.get(`/counter/request/details?counterRequest_dtl_id=${data}&counter=true&employee=true`);
  } else {
    return instance.get(`/counter/request/details?counterRequest_dtl_id=${data}`);
  }
};

export const getApprovalHierarchy = (counterRequest_dtl_id: number) => {
  return instance.get(`/crah?counterRequest_dtl_id=${counterRequest_dtl_id}`);
};

export const updateCounterRequest = (record: any, primary_key: string | number) => {
  return eventInstance.put(
    `/counter/request/status/update`,
    {
      data: record,
      primary_key: primary_key,
      event_type: "COUNTER_REQUEST_STATUS_UPDATE",
    },
    {
      params: {
        version: "v2",
      },
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );
};

export const getCounterEditRequest = (counterRequest_dtl_id: number, position_code: string | number) =>
  instance.get(`is_editing-allowed?counterRequest_dtl_id=${counterRequest_dtl_id}&position_code=${position_code}`);

export const getActionList = (counterRequest_dtl_id: number, position_code: string | number) =>
  instance.get(`/get-action-list?counterRequest_dtl_id=${counterRequest_dtl_id}&position_code=${position_code}`);

export const addTags = (data: any) => {
  return eventInstance.post("/tags/add", {
    data,
    primary_key: data.counterRequest_dtl_id,
    event_type: "COUNTER_TAGS_ADD",
  });
};

export const getCounterRequestBasedOnCreq = (creqNumber: string) => {
  return instance.get(`/counter/request/details?counter_request_code=${creqNumber}`);
};

export const getHierarchyEmployees = (ff_level_current: string, ff_level: string, position_code: string, company_divisioncode: string) => {
  return instance.get(
    `getFFlevelEmployees?ff_level_current=${ff_level_current}&ff_level=${ff_level}&position_code=${position_code}&company_divisioncode=${company_divisioncode}`
  );
};

export const addHierarchy = (data: any) => {
  return eventInstance.post(`addHierarchy`, {
    data: data,
    event_type: "CRA_HIERARCHY_ADD",
    primary_key: data?.counterRequest_dtl_id,
  });
};

export const validateUin = async (data: any, token: any) => {
  if (!token) throw new Error();
  return axios.post(`${import.meta.env.VITE_MEDVOL_BASE_URL}/CompanyUINValidation`, data, {
    headers: {
      Authorization: token,
    },
  });
};
export const getRolesApprovalLayer = () => {
  return instance.get("/approval-layer/actions");
};

export const getMetricData = (body: any) => {
  return axios.post(`${import.meta.env.VITE_MEDVOL_BASE_URL}/FieldForceCounterDraft_RequestMetricDetails_V1`, body, {
    headers: {
      Authorization: getCookieValue("idToken"),
    },
  });
};

export const postCounterRquestMatching = (body: any, token: any) => {
  return axios.post(`${import.meta.env.VITE_MEDVOL_BASE_URL}/CounterMatchDetails_For_V2`, body, {
    headers: {
      Authorization: token,
    },
  });
};

export const getPostPersons = (query: any) => {
  return instance.get(`/get-post-persons?${query}`);
};

export const postEditComment = (body: any) => {
  return instance.put("/remarks", body);
};

export const postNewComment = async (domain: number, domain_id: number, message: string, is_private: boolean) => {
  const body = {
    data: { domain, domain_id, message, is_private },
    event_type: "EMPLOYEE_EDIT",
    primary_key: domain_id,
  };
  if (domain === 1) {
    return eventInstance2.post("/request-comment", body);
  } else if (domain === 2) {
    return eventInstance.post(`/request-comment`, body);
  }
};

export const getComments = (perPage: number, page: number, domain: string, domain_id: number) => {
  return instance2.get(`/request-comment`, {
    params: { perPage, page, domain, domain_id },
  });
};
