import { useSelector } from "react-redux";
import { Badge } from "src/components";
import DetailsGrid from "src/container/details-grid/details-grid.component";
import { RootState } from "src/context/store";

const CompanySection = () => {
  const { selectedStockistData } = useSelector((state: RootState) => state.stockist);

  console.log("selectedStockistData?.stockistClusterLnk", selectedStockistData?.stockist_company_lnk);

  function switchString(input: string) {
    switch (input.toLowerCase()) {
      case "id":
        return undefined;
      case "operator_name":
        return "Operator Name";
      case "operator_mobile":
        return "Operator Mobile";
      case "operator_email":
        return "Operator Email";
      case "plantcode":
        return "Plant Code";
      case "remarks":
        return "Remarks";
      case "created_by":
        return undefined;
      case "created_date":
        return undefined;
      case "modified_by":
        return undefined;
      case "modified_date":
        return undefined;
      case "active_status":
        return "Active Status";
      case "compstockistcode":
        return "Stockist Code";
      case "compstockistaliascode":
        return "Stockist Alias Code";
      case "stockist_type":
        return "Stockist Type";
      case "elnv_exempt":
        return "ELNV Exempt";
      case "code":
        return undefined;
      case "name":
        return "Company Name";
      case "shortcode":
        return "Company Shortcode";
      case "shortname":
        return "Company Shortname";
      case "status":
        return undefined;
      case "uid":
        return "Company UID";
      case "allow_placeorder":
        return "Allow Place Order";
      case "distributionchannel":
        return "Distribution Channel";
      case "cluster_code":
        return undefined;
      case "cluster_name":
        return "Cluster Name";
      case "cluster_uniqueid":
        return undefined;
      case "stockistClusterLnk":
        return undefined;
      default:
        return input;
    }
  }

  function clusterSwitchString(input: string) {
    switch (input.toLowerCase()) {
      case "cluster_name":
        return "Cluster Name";
      case "paymenttermdesc":
        return "Payment Terms";
      case "distributionchannel":
        return "Distribution Channel"
      default:
        return undefined;
    }
  }

  return (
    <section>
      {selectedStockistData?.stockist_company_lnk && selectedStockistData.stockist_company_lnk.length > 0 ? (
        selectedStockistData.stockist_company_lnk.map((item: any, index: number) => (
          <div key={index} className="w-full px-3 py-4 my-10 border rounded-lg">
            <h3 className="text-base font-semibold">{item?.company?.name}</h3>
            <Divider />

            <DetailsGrid
              removeNestedData={true}
              keyString={switchString}
              dataObj={item}
              className="grid grid-cols-4 mt-5 w-12/12 xl:grid-cols-3 gap-y-5"
            />

            <Divider />
            <h3 className="text-base font-semibold">
              Clusters
              <span className="ml-2">
                <Badge className="!text-xs uppercase tracking-wider rounded-md">{`${
                  (item && item?.stockistClusterLnk?.length > 0 && item?.stockistClusterLnk?.length) || 0
                } Cluster${item && item?.stockistClusterLnk?.length > 0 && item?.stockistClusterLnk?.length > 1 ? "s" : ""}`}</Badge>
              </span>
            </h3>
            <Divider />

            {item &&
              item?.stockistClusterLnk?.length > 0 &&
              item?.stockistClusterLnk?.map((Clusteritem: any) => (
                <>
                  <DetailsGrid
                    keyString={clusterSwitchString}
                    removeNestedData={false}
                    dataObj={Clusteritem}
                    className="grid grid-cols-4 mt-5 w-12/12 xl:grid-cols-3 gap-y-5"
                  />
                  <Divider />
                </>
              ))}
          </div>
        ))
      ) : (
        <p>No cluster link data available.</p>
      )}
    </section>
  );
};

const Divider = () => <div className="h-[0.0625rem] w-full bg-[#00000010] mx-auto my-3" />;

export default CompanySection;
