import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, ReactNode } from "react";
import { X } from "lucide-react";

interface ModalProps {
  placeholderButton?: string;
  isHeader: boolean;
  isOpen: boolean;
  onCloseModal: (isOpen: boolean) => void;
  onOpenModal?: (isOpen: boolean) => void;
  title?: string;
  children?: ReactNode;
  onBack?: (event: React.MouseEvent) => void;
  zIndex?: number;
  headerClass?: string;
  closeIconColor?: "white" | "black";
}

const Modal: FC<ModalProps> = ({
  closeIconColor,
  headerClass,
  placeholderButton,
  isHeader,
  isOpen,
  children,
  onCloseModal,
  onOpenModal,
  title,
  zIndex = 10,
}) => {
  const openModalHandler = () => {
    onOpenModal && onOpenModal(true);
  };

  const closeModalHandler = () => {
    onCloseModal(false);
  };

  return (
    <aside>
      {placeholderButton && <div className={isOpen ? "fixed inset-0 flex items-center justify-center" : ""}>
        <button onClick={openModalHandler} type="button">
          {placeholderButton}
        </button>
      </div>}

      {isOpen && (
        <>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              style={{
                zIndex: zIndex,
              }}
              className={`relative`}
              onClose={() => { }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      {isHeader && (
                        <header
                          className={`${headerClass || ""
                            } flex rounded-tl-2xl rounded-tr-2xl px-5 items-center justify-between border-b-[1px] bg-gray-100  py-3`}
                        >
                          <div className="flex font-semibold items-center gap-[1.19rem]">
                            <h3>{title ? title : "Heading"}</h3>
                          </div>
                          <button
                            onClick={closeModalHandler}
                            className="p-[0.19rem] hover:bg-purple-800 group duration-300 transition-all ease-linear cursor-pointer bg-[#f4f7fa29] rounded-3xl"
                          >
                            <X
                              size={20}
                              className={`${closeIconColor === "black" ? "text-black" : closeIconColor === "white" ? "text-white" : "text-black"
                                } group-hover:text-white`}
                            />
                          </button>
                        </header>
                      )}
                      {children}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </aside>
  );
};

export default Modal;
