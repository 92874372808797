import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VersioningState {
  token: string | null | undefined;
  productVersioning: any;
}

const versioningSlice = createSlice({
  name: "auth",
  initialState: {
    productVersioning: [],
    token: null,
  } as VersioningState,
  reducers: {
    setProductVersioning(state, action: PayloadAction<any>) {
      state.productVersioning = action.payload || {};
    },
  },
});

export const versioningActions = versioningSlice.actions;
export { versioningSlice };
