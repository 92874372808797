/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { addAuthHeader } from "src/utils/auth";
import { api, logout } from "../constants/global-const";

// interface for product
interface Product {
  created_by?: number;
  modified_by?: number;
  item_code?: string | number;
  batch_code?: string | number;
  pts?: number;
  ptr?: number;
  mrp?: number;
  remarks?: string | null;
  company_productprice_id?: number;
  active_status?: "Y" | "N";
  isDefault?: boolean;
  created_date?: string;
  expiry_date?: string;
  modified_date?: string;
  company_divisioncode?: string | number;
  companyDivisionCode?: string | number;
}

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status?: number;
  // Add other properties as needed
}

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/product",
});

const uploadInstance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1",
});

//product add edit
const eventInstance: AxiosInstance = axios.create({
  baseURL: api + "/event/product",
});

// Add the interceptor to the instance
eventInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403) {
    // logout
    logout();
  }
});

// Add the interceptor to the instance
instance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403) {
    // logout
    logout();
  }
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
uploadInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));


export const getAllProductDetails = async (): Promise<ApiResponse> => {
  return instance.get("/details");
};

export const getSearchRegionDetails = async (query: string): Promise<ApiResponse> => {
  return instance.get(query);
};

export const patchRegionVisibility = async (query: string, data: object[] | object): Promise<ApiResponse> => {
  return instance.patch(query, data);
};

export const queryProductDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};

export const getAllCityDetails = async (query?: string): Promise<AxiosResponse> => {
  if (query) return instance.get("/city?search=" + query);
  return instance.get("/city?search=");
};

export const StateDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};
export const CityDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};
export const DistrictDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};
export const PsrDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};

export const updateStateDetails = async (query: string, data: object[] | object): Promise<AxiosResponse> => {
  return instance.patch(query, data);
};
export const updatePsrDetails = async (query: string, data: object[] | object): Promise<AxiosResponse> => {
  return instance.patch(query, data);
};

// export const addEditProduct = async (data: Product): Promise<ApiResponse> => {
//   data.created_by = 0;
//   data.modified_by = 0;
//   console.log("data while calling", data);
//   return instance.post("/add-edit-product", data);
// };

export const addProduct = async (data: any): Promise<ApiResponse> => {
  data.created_by = 0;
  data.modified_by = 0;
  return eventInstance.post("/add", {
    data: data,
    event_type: "PRODUCT_ADD",
  });
};

export const editProduct = async (data: any, primary_key: number | string | null | undefined): Promise<ApiResponse> => {
  data.created_by = 0;
  data.modified_by = 0;
  if (!primary_key) throw new Error();
  return eventInstance.put("/edit", {
    data: { ...data, company_productcode: primary_key },
    event_type: "PRODUCT_EDIT",
    primary_key: primary_key,
  });
};

export const getProductPrice = async (item_code: string): Promise<ApiResponse> => {
  return instance.get(`/get-product-price?company_productcode=${item_code}`);
};

export const getVisibleInvisibleData = async (item_code: string, company_divisioncode: string): Promise<ApiResponse> => {
  console.log("item_code", item_code);
  return instance.get(`/region/visiblity?item_code=${item_code}&company_divisioncode=${company_divisioncode}`);
};
export const getPSRVisibleInvisibleData = async (item_code: string, company_divisioncode: string): Promise<ApiResponse> => {
  console.log("item_code", item_code);
  return instance.get(`/psr?item_code=${item_code}&company_divisioncode=${company_divisioncode}`);
};

export const EditProductPrice = async (data: Product, primary_key: number): Promise<ApiResponse> => {
  // return instance.post(`price/edit`, data);

  return eventInstance.post("price/edit", {
    data: { ...data, company_productcode: primary_key },
    event_type: "PRODUCT_PRICE_EDIT",
    primary_key: primary_key,
  });
};

export const addProductPrice = async (data: Product, primary_key: number): Promise<ApiResponse> => {
  // return instance.post(`price/add`, data);

  return eventInstance.post("price/add", {
    data: { ...data, company_productcode: primary_key },
    event_type: "PRODUCT_PRICE_ADD",
    primary_key: primary_key,
  });
};

export const DeleteRegion = async (query: string, data: Product): Promise<ApiResponse> => {
  return instance.put(query, data);
};

export const poGeneration = async (data: any): Promise<ApiResponse> => {
  return instance.post("po-generation", data);
};

export const deactivateProduct = async (data: any): Promise<ApiResponse> => {
  return instance.put("/edit", data);
};

export const replaceProductApi = async (data: any): Promise<ApiResponse> => {
  return instance.post("replace", data);
};

export const getSimilarProductWithDivisionCode = async (data: number[]): Promise<ApiResponse> => {
  return instance.get(`details/division?companydivision_code=${data}`);
};

export const getAllCityDetailsWithCityOnly = async (query?: string): Promise<AxiosResponse> => {
  if (query) return instance.get("/city?cityOnly=true&search=" + query);
  return instance.get("/city?cityOnly=true&search=");
};

export const postProductCsvDownload = async (data: any): Promise<ApiResponse> => {
  return uploadInstance.post("upload/csv", data);
};