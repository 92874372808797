import jwtDecode from "jwt-decode";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../components";
import { authActions } from "../../context/auth-slice";
import { adminList, rolesToRestrict } from "../../constants/roles";

const Login = (): JSX.Element => {
  const dispatch = useDispatch();

  const getCookieValue = (cookieName: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split("=");
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null;
  };

  const validateLogin = useCallback(async (): Promise<void> => {
    // const getCookie = await getCookieValue("token");
    const getIdToken = await getCookieValue("idToken");

    if (getIdToken) {
      const decodedToken: any = await jwtDecode(getIdToken as string);
      let decodedName: any = null;
      if (getIdToken) {
        decodedName = await jwtDecode(getIdToken as string);
      }
      
   
      // Check if the user's role is in the restricted list and update the state
      dispatch(authActions.setRestricted(rolesToRestrict.includes(decodedToken?.["custom:mv_role"])));

      if (decodedToken?.["custom:mv_role"].includes("L")) {
        dispatch(authActions.setRestricted(true));
      }

      dispatch(authActions.login(decodedToken));
      dispatch(authActions.setIdTokenDetails(decodedToken));
      dispatch(authActions.setLoggedInUser(decodedName?.name || ""));
      dispatch(authActions.setIsAdmin(adminList.includes(decodedToken?.["custom:mv_role"])));

      console.log("it is logged in");
    } else {
      window.location.href = `${import.meta.env.VITE_LOGIN_BASE_URL}/?response_type=token&client_id=${import.meta.env.VITE_CLIENT_ID}&redirect_uri=${
        window.location.href
      }`;
    }
  }, [dispatch]);

  useEffect(() => {
    validateLogin();
  }, [validateLogin]);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div>
        <h1 className="text-4xl font-bold text-center">You are not Logged In!</h1>
        <h2 className="text-2xl text-center mt-1 mb-3 text-[rgba(0,0,0,0.4)] font-semibold">Please click on the below button to login</h2>
      </div>
      <Button className="text-white bg-blue-600" onClick={validateLogin}>
        Click here to login
      </Button>
    </div>
  );
};

export default Login;
