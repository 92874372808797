import { useState, useEffect } from "react";
import { getAllCompanyDetails } from "../services/company-api";

const useGetCompanyList = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDetails = () => {
    setLoading(true);
    getAllCompanyDetails()
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return { data, error, loading, refetch: fetchDetails };
};

export default useGetCompanyList;
