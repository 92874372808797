import axios from "axios";
import { accessControlApi } from "src/constants/global-const";

// let instance: AxiosInstance;

// const getCookieValue = (cookieName: string): string | null => {
//   // debugger;
//   const cookies = document.cookie.split(";");
//   for (const cookie of cookies) {
//     const [name, value] = cookie.trim().split("=");
//     if (name === cookieName) {
//       return decodeURIComponent(value);
//     }
//   }
//   return null;
// };

// const axiosInstance = async () => {
//   instance = axios.create({
//     baseURL: accessControlApi,
//     //   headers: {
//     //     Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
//     //   },
//   });
//   // Add the interceptor to the instance
//   instance.interceptors.response.use(undefined, (error) => {
//     if (error?.response?.status === 403) {
//       // logout
//       logout();
//     }
//   });
// };

// axiosInstance();

export const fetchMenuAccessRoles = async () => {
  // return instance.get(`/check-mobile?mobile=${mobileNo}`);
  return axios.get(`${accessControlApi}?application=ops`);
};
