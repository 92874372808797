import { useDispatch, useSelector } from "react-redux";
import { Button, SubHeader } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import { companyActions } from "src/context/company-slice";
import { RootState } from "src/context/store";
import AddEditCompany from "./add-edit-company-form.component";
import useGetCompanyList from "src/hooks/useGetCompanyList";
import Loader from "src/components/ui/loader.component";
import { useEffect } from "react";

const CompanyLayoutComponent = ({ children }: { children: React.ReactNode }) => {
  const { showAddEditForm } = useSelector((state: RootState) => state.company);
  const { data, loading, refetch, error } = useGetCompanyList();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!error) {
      dispatch(companyActions.setCompanyMasterTable(data));
    }
  }, [data]);


  if (loading) <Loader />;

  return (
    <div className="w-full">
      <SubHeader />
      <section className="w-9/12 md:8/12">
        {showAddEditForm && <AddEditCompany refetch={refetch} setShowAddForm={(value: boolean) => dispatch(companyActions.setShowAddEditForm(value))} />}
      </section>
      <header className="flex px-[3.75rem]  items-center justify-between py-5 border-b-[1px] border-b-[#E9E9E9]">
        <div>
          <Breadcrumb
            currentActive="Company"
            dataOrigin={[
              { name: "Masters", path: "masters" },
              { name: "Company", path: "company" },
            ]}
          />
          <h1 className="text-[#49484C] font-bold text-[2rem]">Company Name</h1>
          <p className="text-[#767679] text-base">View and manage all your company configurations</p>
        </div>

        <div className="flex items-center gap-2 ">
          <Button
            onClick={() => {
              dispatch(companyActions.setShowAddEditForm(true));
              dispatch(companyActions.setIsEdit(false));
            }}
            className="bg-[#7F56D9] text-white h-11 w-[15rem]"
          >
            Add new
          </Button>
        </div>
      </header>
      {children}
    </div>
  );
};

export default CompanyLayoutComponent;
