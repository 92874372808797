import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { addAuthHeader } from "src/utils/auth";
import { api } from "../constants/global-const";

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/company",
});

const eventInstance = axios.create({
  baseURL: api + "/api/v1/company",
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const getAllCompanyDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const queryCompanyDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};

export const queryPriceIdentifier = async (query: string) => {
  return eventInstance.get(`/price-identifier${query}`);
};

export const queryCounterPriceIdentifier = async (query: string) => {
  return instance.get(`/priceIdentifier-list${query}`);
};
