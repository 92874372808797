import { Tab } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllCompanyDetails } from "src/services/company-api";
import { getAllLinkTypesDetails } from "src/services/counter-api";
import { getStateDetails } from "src/services/region-api";
import Button from "../../components/Button";
import CounterForm from "../../container/CounterForm";
import { craActions } from "../../context/cra-slice";
import HomeApprovedTab from "../homeApprovedTab";
import HomePendingTab from "../homePendingTab/HomePendingTab";

const tabs = ["Pending", "Approved by me"];

const Homescreen = () => {
  const [showAddEditForm, setShowAddEditForm] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => dispatch(craActions.setCraFiltersData({ key: "company", value: res.data })))
      .catch((err) => console.log(err));

    getAllLinkTypesDetails()
      .then((res) => {
        dispatch(craActions.setCraFiltersData({ key: "link", value: res.data }));
      })
      .catch((err) => console.log(err));

    getStateDetails("")
      .then((res) => {
        dispatch(craActions.setCraFiltersData({ key: "state", value: res.data.data }));
      })
      .catch((err) => console.log(err));

    const ActionType = [
      { label: "Waiting for Approval", value: "waiting" },
      { label: "Reject", value: "rejected" },
      { label: "Complete", value: "complete" },
    ];
    const ActionTypePending = [
      { label: "Waiting for Approval", value: "waiting" },
      { label: "Reject", value: "rejected" },
      { label: "Pending", value: "pending" },
    ];

    dispatch(craActions.setCraFiltersData({ key: "approvedByMeStatus", value: ActionType }));
    dispatch(craActions.setCraFiltersData({ key: "pendingStatus", value: ActionTypePending }));
  }, [dispatch]);

  return (
    <>
      {/* <SubHeader /> */}
      <section>
        <header className="flex px-[3.75rem] items-center justify-between py-5">
          <div>
            <h1 className="text-[#49484C] font-bold text-[2rem]">Counter Requests</h1>
            <p className="font-medium text-base text-[#929194]">View and manage all your counter requests.</p>
          </div>
          <Button
            onClick={() => {
              dispatch(craActions.setEditMode(false));
              dispatch(craActions.setFormDefaultValues({}));
              setShowAddEditForm(true);
            }}
            className="text-white bg-[#586AF5]"
          >
            Add New
          </Button>
        </header>

        {/* table  */}
        <main>
          <Tab.Group>
            <Tab.List className="border-b-[1px] border-b-[rgba(0, 0, 0, 0.10)] mb-[1.16rem] px-[3.75rem] mt-6">
              {tabs.map((item: string) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }: { selected: boolean }) => (
                    <div
                      className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                        selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                      }`}
                    >
                      {item}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel className="px-[3.75rem]">
                <HomePendingTab />
              </Tab.Panel>
              <Tab.Panel className="px-[3.75rem]">
                <HomeApprovedTab />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
      </section>

      {showAddEditForm && <CounterForm showAddEditForm={showAddEditForm} setShowAddEditForm={setShowAddEditForm} />}
    </>
  );
};

export default Homescreen;
