import { cn } from "src/utils/utils";
import { forwardRef } from "react";

interface TextAreaProps {
  label?: string;
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { label, className, value, onChange, placeholder, disabled, onBlur },
    ref
  ) => {
    return (
      <div className="w-full">
        {label && (
          <p className="text-[#737274] font-medium text-xs ml-3 mb-1">
            {label ? label : "label"}
          </p>
        )}
        <textarea
          ref={ref}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          className={cn(
            ` outline-none text-sm px-3 py-1 ${className} border border-[#e0e0e0] rounded-lg w-full`
          )}
        />
      </div>
    );
  }
);

export default TextArea;
