import { useDispatch, useSelector } from "react-redux";
import { Table } from "src/components";
import { companyActions } from "src/context/company-slice";
import { RootState } from "src/context/store";
import CompanyLayoutComponent from "./company-layout.component";
import { getCompanyMasterColumns } from "./company-master-table.columns";

const CompanyMasterComponent = () => {
  const { companyMasterTable } = useSelector((state: RootState) => state.company);

  const dispatch = useDispatch();

  const editHandler = (rowData: any) => {
    let formData = structuredClone(rowData);
    formData.status = rowData.status === true ? { label: "Active", value: true } : { label: "Inactive", value: false };
    formData.allow_placeOrder = rowData.allow_placeOrder === 1 ? { label: "Yes", value: 1 } : { label: "No", value: 0 };
    dispatch(companyActions.setAddEditFormData(formData));
    dispatch(companyActions.setShowAddEditForm(true));
    dispatch(companyActions.setIsEdit(true));
  };


  return (
    <CompanyLayoutComponent>
      <div className="px-10">
        <Table title="Company details" showSearch={true} data={companyMasterTable || []} columns={getCompanyMasterColumns([], editHandler)} />
      </div>
    </CompanyLayoutComponent>
  );
};

export default CompanyMasterComponent;
