import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UploadIcon from "src/assets/upload.svg";
import { Button } from "src/components";
import { getSubHeaderLinks, getSubHeaderLinks2 } from "src/constants/header";
import { masterActions } from "src/context/master-slice";
import { productActions } from "src/context/product-slice";
import { RootState } from "src/context/store";
import { queryProductDetails } from "src/services/product-api";
import { parseQueryString } from "src/utils/utils";

interface SubHeaderProps {
  onAddNew?: () => void;
  onBulkUpload?: () => void;
}

const SubHeader: FC<SubHeaderProps> = ({ onAddNew, onBulkUpload }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const userRole: any = useSelector((state: RootState) => state.auth);
  const accessControl = useSelector((state: RootState) => state.accessControl);
  // const currentLocationArr: string[] = location.pathname.split("/");
  // let currentLocation: string = "";
  // const linksArray = location.pathname.toLowerCase().includes("masters") ? subHeaderLinks : subHeaderLinks2;
  // if (currentLocationArr.length > 2) {
  //   currentLocation = currentLocationArr[2];
  // }

  useEffect(() => {
    if (location.pathname.includes("/Masters/Products")) {
      const destructuredQuery = parseQueryString(window.location.search);

      queryProductDetails(`/details?c=` + destructuredQuery["?c"]).then((productDetails) => {
        dispatch(productActions.setProduct(productDetails?.data || []));
      });
    }
  }, [dispatch, location.pathname]);

  const subHeaderLinks = getSubHeaderLinks(accessControl);
  const subHeaderLinks2 = getSubHeaderLinks2(accessControl);

  const linksArray = location.pathname.toLowerCase().includes("masters") ? subHeaderLinks : subHeaderLinks2;

  return (
    <section className="flex items-center w-full justify-between px-[3.75rem] h-16 border-b-[rgba(0, 0, 0, 0.10)] border border-t-0">
      <nav>
        <ul className="flex gap-10 text-[#A4A3A5] text-base font-medium not-italic capitalize ">
          {linksArray.map(
            (link: any) =>
              (link.roles.includes(userRole.token["custom:mv_role"]) || link.roles.includes("Any")) && (
                <button
                  onClick={() => {
                    dispatch(masterActions.setTableData(undefined));
                    dispatch(masterActions.removeAllAutocompleteFilters());
                    dispatch(masterActions.removeAllAutocompleteSuggestionList());
                    navigate(`/${link.value}`);
                  }}
                  className={`${
                    location.pathname?.toLowerCase()?.includes(link?.value.toLowerCase()) ? "text-[#7F56D9]" : ""
                  } hover:text-purple-400 cursor-pointer`}
                  key={link.label}
                >
                  {link.label}
                </button>
              )
          )}
        </ul>
      </nav>
      {/* action buttons  */}
      <div className="flex gap-2">
        {
        (onBulkUpload && location.pathname.toString().toLowerCase().includes("/employees")) ||
        (location.pathname.toString().toLowerCase().includes("/counters") &&
          !location.pathname.toString().toLowerCase().includes("/value-cap") &&
          !location.pathname.toString().toLowerCase().includes("/block-revoke")) ? (
          <Button onClick={onBulkUpload} className="gap-2 transition-all duration-300 ease-in-out border hover:bg-gray-100">
            <img src={UploadIcon} alt="Upload" width={12} height={12} /> Bulk Upload
          </Button>
        ) : (
          ""
        )}
        {onAddNew ? (
          <Button onClick={onAddNew} className="bg-[#7F56D9] text-white">
            Add new
          </Button>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default SubHeader;
