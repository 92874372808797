import { createSlice } from "@reduxjs/toolkit";

export interface GeographyState {
  GeographyStateData: any[] | null | undefined;
  GeographyDistrictAndCityData: any[] | null | undefined;
  showAddForm: boolean | null | undefined;
  showEditForm: boolean | null | undefined;
  isEdit: boolean | null | undefined;
  addEditFormData: any;
  primary_key: string | null | undefined;
  selectedState: any;
  event: {
    isBlocked: boolean;
    isPending: boolean;
  };
}
export enum geographyType {
  state = "state",
  district = "district",
  city = "city",
}

const initialState: GeographyState = {
  GeographyStateData: [],
  GeographyDistrictAndCityData: [],
  showEditForm: false,
  showAddForm: false,
  isEdit: false,
  selectedState: {},
  event: {
    isBlocked: false,
    isPending: false,
  },

  addEditFormData: {
    state_code: null,
    district_code: null,
    city: null,
    old_city_name: null,
    new_city_name: null,
    active_status: null,
    type: geographyType.city,
  },
  primary_key: "",
};

const geographySlice = createSlice({
  name: "geography",
  initialState,
  reducers: {
    setGeographyStateData(state, action) {
      state.GeographyStateData = action.payload;
    },
    setGeographyDistrictAndCityData(state, action) {
      state.GeographyDistrictAndCityData = action.payload;
    },
    setAddFormData(state, action) {
      const { key, value } = action.payload;
      state.addEditFormData = { ...state.addEditFormData, [key]: value };
    },

    setEditFormData(state, action) {
      const payloadKeys = Object.keys(action.payload);
      payloadKeys.forEach((key) => {
        state.addEditFormData = { ...state.addEditFormData, [key]: action.payload[key] };
      });
    },
    setPrimaryKey(state, action) {
      state.primary_key = action.payload;
    },
    setShowAddForm(state, action) {
      state.showAddForm = action.payload;
    },
    setShowEditForm(state, action) {
      state.showEditForm = action.payload;
    },

    setResetFormData(state) {
      state.addEditFormData = {};
    },
    setSelectedState(state, action) {
      state.selectedState = action.payload;
    },
    setEvent(state, action) {
      state.event = {
        isBlocked: action.payload.isBlocked,
        isPending: action.payload.isPending,
      };
    },
  },
});

export const geographyActions = geographySlice.actions;
export { geographySlice };
