import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button, Modal } from "src/components";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { addCompany, editCompany } from "src/services/company-api";
import * as yup from "yup";
import GenericForm from "../counter-master/form.component";

interface IAddEditCompany {
  setShowAddForm: any;
  refetch: any;
}

const formFields: any[] = [
  {
    id: "name",
    type: "text",
    label: "Company Name",
    placeholder: "Enter Company Name",
    keyofForm: "name",
  },
  {
    id: "shortcode",
    type: "text",
    label: "Short Code",
    placeholder: "Enter Short Code",
    keyofForm: "shortcode",
  },
  {
    id: "shortname",
    type: "text",
    label: "Short Name",
    placeholder: "Enter Short Name",
    keyofForm: "shortname",
  },


  {
    id: "status",
    type: "autocomplete",
    label: "Status",
    placeholder: "Enter Status",
    keyofForm: "status",
    suggestionList: [
      { value: true, label: "Active" },
      { value: false, label: "Inactive" },
    ],
    displayKey: "label",
  },
  {
    id: "allow_placeOrder",
    type: "autocomplete",
    label: "Allow Place Order",
    placeholder: "Enter Allow Place Order",
    keyofForm: "allow_placeOrder",
    suggestionList: [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ],
    displayKey: "label",
  }
];

const schema = yup.object().shape({
  name: yup.string().required("Company Name is required"),
  shortcode: yup.string().required("Short Code is required"),
  shortname: yup.string().required("Short Name is required"),
  allow_placeOrder: yup.object().required("Allow Place Order is required"),
  status: yup.object().required("Status is required"),
});

const AddEditCompany = ({ setShowAddForm, refetch }: IAddEditCompany) => {
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();

  const { addEditFormData, isEdit } = useSelector((state: RootState) => state.company);





  const smartFormInstance = useForm({
    mode: "onBlur",
    defaultValues: structuredClone(
      isEdit
        ? structuredClone(addEditFormData)
        : {
          name: "",
          shortcode: "",
          shortname: "",
          allow_placeOrder: "",
          status: null,
          highest_position: null,
          lowest_position: null,
        }
    ),
    resolver: yupResolver(schema) as any,
  });

  const autocompleteHandler = (_: any, key?: string) => {
    // highest position should always be greater then lowest position
    if (key === "highest_position") {
      smartFormInstance.setValue("lowest_position", null);
    }
  };

  const onSubmit = async (data: any) => {
    if (loading) return;
    try {
      const payload = {
        ...data,
        status: data.status.value,
        allow_placeOrder: data.allow_placeOrder.value,
      };
      if (isEdit) {
        payload.uid = addEditFormData.uid;
      }
      const res = isEdit ? await editCompany(payload, addEditFormData.code) : await addCompany(payload);
      if (res.status === 200) {
        toast({
          title: "Success",
          description: "Company added successfully",
          variant: "default",
        });
        setTimeout(() => {
          refetch();
          setShowAddForm(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isHeader={true}
      onBack={() => {
        setShowAddForm(false);
      }}
      title={isEdit ? "Edit Company" : "Select Company"}
      isOpen={true}
      onCloseModal={() => {
        setShowAddForm(false);
      }}
    >
      <GenericForm
        onAutocompleteChange={autocompleteHandler}
        formFields={formFields}
        onFormSubmit={() => { }}
        defaultValues={{}}
        formInstance={smartFormInstance}
      >
        <footer className="flex justify-end gap-3 px-5 pb-5">
          <Button onClick={() => setShowAddForm(false)} className="border">
            Cancel
          </Button>
          <Button
            isLoading={loading}
            loaderSize="small"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              smartFormInstance.handleSubmit(onSubmit, () => {
                setLoading(false);
              })();
            }}
            className="text-white bg-primary"
          >
            {isEdit ? "Edit" : "Add"}
            Company
          </Button>
        </footer>
      </GenericForm>
    </Modal>
  );
};

export default AddEditCompany;
