import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { ApiBlocker, api, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
let eventInstance: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/stockist",
  });
  instance.interceptors.request.use(
    (success: InternalAxiosRequestConfig) => {
      ApiBlocker.eventChecked();
      return addAuthHeader(success);
    },
    (error) => {
      // alert("asdfasdf");
      if (error?.response?.status === 403) {
        // logout
        // logout();
      }
    }
  );
  instance.interceptors.response.use(
    (success: any) => {
      let counterString = "";
      console.log('success.config.baseURL.includes("stockist")', success.config.baseURL.includes("stockist") && success.data);
      if (success.config.baseURL.includes("stockist") && success.data && window.sessionStorage.getItem("stockistMasterId")) {
        counterString = `stockist_${window.sessionStorage.getItem("stockistMasterId")}`;

        counterString && ApiBlocker.eventChecked(counterString);
      }
      return success;
    },
    (error) => {
      if (error?.response?.status === 403) {
        // logout
        logout();
      }
    }
  );
};
const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/stockist",
  });

  // Add the interceptor to the instance
  eventInstance.interceptors.response.use(
    (success: any) => {
      ApiBlocker.eventChecked(success?.data?.eventStreamCreated?.streamId);
      return success;
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
    }
  );

  eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};

axiosEventInstance();

axiosInstance();

export const getPaymentTerms = async (query: string): Promise<AxiosResponse> => {
  return instance.get(`/payment${query}`);
};

export const addStockist = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.post("/add", { data, event_type: "STOCKIST_ADD" });
};
export const editStockist = async (data: any, primaryKey: string | number): Promise<AxiosResponse> => {
  return eventInstance.put("/edit", { data, event_type: "STOCKIST_EDIT", primary_key: primaryKey });
};

export const getStockist = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const getStockistByCluster = async (cluster_code: number | string, company_code: string) => {
  return instance.get(`/details?company=true&cluster=true&active_status=Y&cluster_code=${cluster_code}&company_code=${company_code}`);
};

export const queryStockist = async (query: string): Promise<AxiosResponse> => {
  return instance.get(`/details${query}`);
};

export const updateStockist = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.post("/update", { data, event_type: "STOCKIST_UPDATE" });
};

export const addStockistCompany = async (data: any, PrimaryKey: string | number): Promise<AxiosResponse> => {
  return eventInstance.post("/company/add", { data, event_type: "STOCKIST_COMPANY_ADD", primary_key: PrimaryKey });
};
export const editStockistCompany = async (data: any, PrimaryKey: string | number): Promise<AxiosResponse> => {
  return eventInstance.put("/company/edit", { data, event_type: "STOCKIST_COMPANY_EDIT", primary_key: PrimaryKey });
};
export const queryStockistDetails = async (data: any): Promise<AxiosResponse> => {
  return instance.get(`/details${data}`);
};

export const activeInactiveStockist = async (data: any, primary_key: string | number) => {
  return eventInstance.put("/edit", {
    data: data,
    event_type: "STOCKIST_EDIT",
    primary_key: primary_key,
  });
};

export const uniqueMedvolCode = async (data: string) => {
  return instance.get(`/medvolcode/unique?medvol_code=${data}`);
};

export const uniqueStockistCode = async (data: string) => {
  return instance.get(`/stockistcode/unique?compStockistCode=${data}`);
};
export const StockistCode = async (data: string, query: string) => {
  return instance.get(`/company/unique?compStockistCode=${data}${query ? `&${query}` : ""}`);
};
export const StockistAliasCode = async (data: string, query: string) => {
  return instance.get(`/company/unique?compStockistAliasCode=${data}${query ? `&${query}` : ""}`);
};

export const uniqueGstNumber = async (data: string, query?: string | null) => {
  return instance.get(`/gstExistValidation?gstNumber=${data}${query ? `&${query}` : ""}`);
};

export const getEInvAckType = async () => {
  return instance.get(`/getEInvAckdetails`);
};
