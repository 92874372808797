import { InternalAxiosRequestConfig } from "axios";
import { getCookieValue } from "src/constants/functions";
import { logout } from "src/constants/global-const";

export const addAuthHeader = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const idToken = getCookieValue("idToken");
  const refreshToken = getCookieValue("refreshToken");

  if (!idToken && !refreshToken) {
    logout();
  }



  if (idToken && refreshToken) {
    config.headers["Authorization"] = JSON.stringify({
      authorization: idToken,
      refreshToken: refreshToken,
    });
  }


  config.baseURL = config.baseURL?.toString()?.toLocaleLowerCase();
  const [firstPart, ...rest] = config.url?.split("?") || [];
  config.url = firstPart?.toString()?.toLocaleLowerCase() + (rest.length ? '?' + rest.join('?') : ''); 


  return config;
};
