import React from "react";
import { useSearchParams } from "react-router-dom";
import arrowDownIcon from "../../assets/arrow-down.svg";
import Button from "./button.component";

const PaginationComponent = ({ table }: { table: any }) => {
  const { getCanPreviousPage, previousPage, nextPage, getState, setPageIndex, getPageCount } = table;

  const [searchParams] = useSearchParams();
  // Adjusted to start pageIndex from 1 for external usage
  const { pageIndex } = getState().pagination;
  const pageCount = getPageCount();

  const generatePageNumbers = (): (number | string)[] => {
    const maxPagesToShow = 3;
    let pages: (number | string)[] = [];

    if (pageCount <= maxPagesToShow) {
      pages = Array.from({ length: pageCount }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(1, pageIndex - 1);
      const endPage = Math.min(pageCount, pageIndex + 2); // Adjusted to align with the 1-based indexing

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < pageCount) {
        if (endPage < pageCount - 1) pages.push("...");
        pages.push(pageCount);
      }
    }

    return pages;
  };

  const isNextPage = parseInt(pageCount) > parseInt(searchParams.get("pageIndex") as string);

  return (
    <div className="flex items-center justify-center mb-1 mt-7">
      <Button
        className="gap-2 text-sm font-medium bg-white border rounded-r-none"
        disabled={!getCanPreviousPage() || pageIndex === 1}
        onClick={() => previousPage()}
      >
        <img src={arrowDownIcon} className="rotate-90" /> Previous
      </Button>
      {generatePageNumbers().map((pageNumber, index) => (
        <React.Fragment key={index}>
          {pageNumber === "..." ? (
            <span className="mx-2">...</span>
          ) : (
            <Button
              className={`border rounded-none  bg-white gap-2 ${
                pageIndex === pageNumber ? "bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700" : "hover:bg-gray-100 hover:text-gray-700"
              }`}
              onClick={() => setPageIndex(Number(pageNumber) - 1)} // No adjustment needed here since setPageIndex internally expects 0-based index
            >
              {pageNumber}
            </Button>
          )}
        </React.Fragment>
      ))}
      <Button className="gap-2 text-sm font-medium bg-white border rounded-l-none" disabled={!isNextPage} onClick={() => nextPage()}>
        Next
        <img src={arrowDownIcon} className="-rotate-90 " />
      </Button>
    </div>
  );
};

export default PaginationComponent;
